import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import apiService from '../../../utlis/apiService';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const BatchLog = ({ id }) => {
  const [activityLogs, setActivityLogs] = useState([]);

  const fetchActivityLogs = async () => {
    try {
      const response = await apiService.get(`/draft-batch/getActivityLogsByBatchId?batchId=${id}`);
      
      setActivityLogs(response?.data?.activityLogs);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchActivityLogs();
  }, [id]);

  const formatDate = (myDate) => {
    const date = new Date(myDate);

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };

    const formattedDate = date.toLocaleString('en-US', options);

    return formattedDate;
  };
  
  return (
    <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
      <Typography variant="h6" gutterBottom>
        Activity Logs
      </Typography>
      <List>
        {activityLogs.map((item, index) => (
          <React.Fragment key={item._id}>
            <div style={{ marginTop: '10px' }}>
              <div style={{ fontSize: '14px', fontWeight: 600 }}>{formatDate(item.timestamp)}</div>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>User</TableCell>
                      <TableCell>Action</TableCell>
                      <TableCell>Academic Year</TableCell>
                      <TableCell>Sessoin</TableCell>
                      <TableCell>Branch</TableCell>
                      <TableCell>Course</TableCell>
                      <TableCell>Subject</TableCell>
                      <TableCell>Capacity</TableCell>
                      <TableCell>Starting Date</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Mode</TableCell>
                      <TableCell>Faculty</TableCell>
                      <TableCell>Mentor</TableCell>
                      <TableCell>Coordinator</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{item?.userId?.email}</TableCell>
                      <TableCell>{item.details?.action}</TableCell>
                      <TableCell>{item.details?.details?.year}</TableCell>
                      <TableCell>{item.details?.details?.session}</TableCell>
                      <TableCell>{item.details?.details?.branch?.name}</TableCell>
                      <TableCell>{item.details?.details?.course?.name}</TableCell>
                      <TableCell>{item.details?.details?.paper?.name}</TableCell>
                      <TableCell>{item.details?.details?.limit}</TableCell>
                      <TableCell>{item.details?.details?.date}</TableCell>
                      <TableCell>{item.details?.details?.status}</TableCell>
                      <TableCell>{item.details?.details?.mode}</TableCell>
                      <TableCell>{item.details?.details?.faculty?.name}</TableCell>
                      <TableCell>{item.details?.details?.mentor?.name}</TableCell>
                      <TableCell>{item.details?.details?.coordinator?.name}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {index < activityLogs.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </Paper>
  );
};

export default BatchLog;
