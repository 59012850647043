import { useEffect, useMemo, useState } from "react";
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import config from "../config";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Avatar, Box, Button, Checkbox, CircularProgress, createTheme, CssBaseline, FormHelperText, Grid, Paper, TextField, ThemeProvider, Typography } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";

const useQuery = () => {
	const { search } = useLocation();
	return useMemo(() => new URLSearchParams(search), [search]);
}

const defaultTheme = createTheme();

const RecordingsApplicationForm = () => {
    let query = useQuery();
	let id = query.get('id');
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '+91',
        course: {
            name: ''
        },
        statusType: '',
        category: {
            name: ''
        },
        subject: '',
        price: '',
    });
    const [loader, setLoader] = useState(false);
    const [appInstalled, setAppInstalled] = useState(false);
    const [installedError, setInstalledError] = useState('');

    useEffect(() => {
        if(id) {
            fetchStudentData();
        }
    }, [id]);

    const fetchStudentData = async () => {
        let murl = `${config.apiUrl}/student/get/recordings/registration/details`;
        try {
            const response = await axios.get(murl, {
                params: {
                    id: id,
                }
            });

            setFormData(response?.data);
            if(response?.data?.status !== 'Application Pending') {
                navigate('/error-404');
            }
            
        } catch (error) {
            console.error(error);
            if(error?.response?.status === 404) {
                navigate('/error-404');
            }
        }
    }

    const payNow = async () => {
        if(appInstalled) {
            const data = {
                id: id,
                type: 'New Admission',
            }

            try {
                const response = await axios.post(`${config.apiUrl}/user/recordings/payment-initiate`, data, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const paymentLink = response?.data?.payment_links?.web;
                if(paymentLink) {
                    window.open(paymentLink, '_self');
                }
                else {
                    alert('Something went wrong');
                    window.location.reload();
                }

            } catch(err) {
                console.error(err);
                if(err.response?.status === 400) {
                    alert(err.response?.data?.message);
                }
            } finally {
                setLoader(false);
            }
        }
        else {
            setInstalledError('Kindly confirm that you have sign up for the Elance Learing App')
        }
    }

    const confimAppInstall = (e) => {
        setAppInstalled(e.target.checked);
        setInstalledError('');
    }

    if(loader) {
        return <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center', height: '100vh', width: '100%' }}>
            <CircularProgress style={{ margin: 'auto' }} />
        </div>
    }
    else {
        return (
            <ThemeProvider theme={defaultTheme}>
                <Grid container component="main" sx={{ marginTop: '20px', paddingBottom: '20px', height: '100vh', display:'flex', justifyContent:'center', alignItems:'center' }}>
                    <CssBaseline />
                    <Grid item xs={11} sm={6} md={4} component={Paper} elevation={6} square>
                        <Box
                            sx={{
                                my: 8,
                                mx: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                borderRadius:8
                            }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                <AppRegistrationIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5">Recordings Application</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        label="First Name"
                                        name="firstName"
                                        value={formData.firstName}
                                        fullWidth
                                        required
                                        margin="normal"
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Last Name"
                                        name="lastName"
                                        value={formData.lastName}
                                        fullWidth
                                        required
                                        margin="normal"
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Email"
                                        name="email"
                                        value={formData.email}
                                        fullWidth
                                        type="email"
                                        margin="normal"
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <MuiTelInput
                                        label="Phone Number"
                                        name="phoneNumber"
                                        value={formData.phoneNumber}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Course"
                                        name="course"
                                        type="text"
                                        value={formData?.course?.name}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Status Type"
                                        name="statusType"
                                        type="text"
                                        value={formData?.statusType}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Category"
                                        name="category"
                                        type="text"
                                        value={formData?.category?.name}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Subject"
                                        name="subject"
                                        value={formData?.subject}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                        multiline
                                    />
                                </Grid>
                            </Grid>

                            <div style={{ textAlign: 'justify', textJustify: 'inter-word'}}>
                            <strong>Note: </strong> Please ensure that you have installed and sign up the Elance Learing App before proceeding with the application enrollment. <br /><br />
                                <Checkbox onChange={confimAppInstall} checked={appInstalled} /> 
                                I confirm that I have successfully sign up on the Elance Learing App.
                                <FormHelperText error>{installedError}</FormHelperText>
                                <br /><br />
                            </div>
                            <Typography component="h1" variant="h6" sx={{ textAlign: 'left', mt: 2 }}>Amount Payable: {formData.price}</Typography>
                            <Button
                                type="button"
                                variant="contained"
                                sx={{ mt: 2 }}
                                onClick={payNow}
                            >
                                Pay Now
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </ThemeProvider>
        );
    }
}

export default RecordingsApplicationForm;