import * as React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Autocomplete, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import apiService from '../../../utlis/apiService';
import { generateYearRange, getSessions } from '../../../utlis/commonFunction';



export default function ExportDefaultToolbar() {
    const [data,setData] = React.useState([]);
    const [totalResults, setTotalResults] = React.useState(0);
    const [branchs, setBranchs] = React.useState([]);
    const [filterData, setFilterData] = React.useState({
        session: '',
        year: '',
        branch: '',
    });

    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 50,
        page: 0,
    });

    const [loading, setLoading] = React.useState(false);

    const pageOption = [10, 25, 50, 100];

    const years = generateYearRange();
    const session = getSessions();

    React.useEffect(()=>{
        getData()
    }, [filterData, paginationModel]);

    React.useEffect(()=>{
        getAllData()
    }, []);

    const getAllData = async () => {
        try {
            const branchResponse = await apiService.get('/branch/list');

            setBranchs(branchResponse?.data?.branchs);
        } catch (error) {
            console.error('Error during requests:', error);
        }
    };

    const getData = async () => {
        try {
            setLoading(true);
            const response = await apiService.get('/report/batch', {
                params: {
                    ...filterData,
                    page: paginationModel.page,
                    pageSize: paginationModel.pageSize,
                },
            });
            setData(response?.data?.batchs);
            setTotalResults(response?.data?.totalResults);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleChangeSubmit = (e, value, name) => {
        setFilterData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

  return (
    <div style={{ height: '70vh', width: '100%' }}>
        <Typography variant="h4" gutterBottom>
            BATCHS REPORT
        </Typography>
        <Grid container spacing={2} alignItems="center" sx={{ marginBottom: 2}}>
            <Grid item xs={4}>
                <Autocomplete
                    disablePortal
                    id="combo-box-year"
                    name="year"
                    options={years}
                    style={{ marginTop: 15 }}
                    getOptionLabel={(option) => option}
                    value={filterData.year || null}
                    onChange={(e, value) => handleChangeSubmit(e, value, 'year')}
                    renderInput={(params) => <TextField {...params} label="Academic Year" size='small' />}
                />
            </Grid>
            <Grid item xs={4}>
                <Autocomplete
                    disablePortal
                    id="combo-box-course"
                    name="course"
                    options={session}
                    style={{ marginTop: 15 }}
                    getOptionLabel={(option) => option}
                    value={session?.find((option) => option === filterData.session) || null}
                    onChange={(e, value) => handleChangeSubmit(e, value, 'session')}
                    renderInput={(params) => <TextField {...params} label="Session" size='small' />}
                />
            </Grid>
            <Grid item xs={4}>
                <Autocomplete
                    disablePortal
                    id="combo-box-branch"
                    name="branch"
                    options={branchs}
                    style={{marginTop:15}}
                    getOptionLabel={(option) => option.name}
                    value={branchs.find((option) => option._id === filterData.branch) || null}
                    onChange={(e, value) => handleChangeSubmit(e, value, 'branch')} 
                    renderInput={(params) => <TextField {...params} label="Branch" size='small' />}
                />
            </Grid>
        </Grid>
        {loading ? (
            <div style={{ textAlign: 'center' }}>
                <CircularProgress style={{ margin: 'auto' }} />
            </div>
        ) : (
            <DataGridPro
                getRowId={(row) => row._id}
                rows={data}
                disableDensitySelector
                columns={[
                    { field: 'name', headerName: 'Name', flex: 1 },
                    { field: 'year', headerName: 'Academic Year', flex: 1 },
                    { field: 'session', headerName: 'session', flex: 1 },
                    { 
                        field: 'branch', 
                        headerName: 'Branch', 
                        flex: 1, 
                        valueGetter: (params) => params.row?.branch?.name
                    },
                    { 
                        field: 'course', 
                        headerName: 'Course', 
                        flex: 1, 
                        valueGetter: (params) => params.row?.course?.name
                    },
                    { 
                        field: 'paper', 
                        headerName: 'Paper', 
                        flex: 1, 
                        valueGetter: (params) => params?.row?.paper?.name 
                    },
                ]}
                pagination
                paginationModel={paginationModel}
                rowCount={totalResults}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                pageSizeOptions={pageOption}
            />
        )}
    </div>
  );
}
