import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { memo } from "react";

const ScheduleFacultyView = memo(({facultySchedule, calendarData}) => {
    return <div style={{ width: '100%', overflowX: 'auto' }}>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="custom table">
                <TableHead>
                    <TableRow>
                        <TableCell>Faculties</TableCell>
                        <TableCell>Report</TableCell>
                        {calendarData.map((date, index) => (
                            <TableCell align="center" key={index}>{new Date(date).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' })}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {facultySchedule.map((faculty, index) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row">{faculty.faculty}</TableCell>
                            <TableCell component="th" scope="row">
                                Total Hrs: {faculty?.availablility?.totalHours}
                                <br />
                                Remaining Hrs: {faculty?.availablility?.remainingHours < 0 ? faculty?.availablility?.totalHours : faculty?.availablility?.remainingHours}
                                <br />
                                Alloted Hrs: {faculty?.availablility?.allotedHours}
                                <br />
                                Batch Size: {faculty?.availablility?.size}
                                <br />
                                {/* {faculty?.availablility?.reason ? `Reason: ${faculty?.availablility?.reason}` : ''} */}
                            </TableCell>
                            {calendarData.map((date, index1) => {
                                const thisDate = new Date(date).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' });
                                const scheduleItem = faculty.schedule.find(schedule => new Date(schedule.date).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }) === thisDate);

                                let cell = <TableCell>-</TableCell>
                                
                                if(scheduleItem) {
                                    if(['Schedule', 'Weird Schedule'].includes(scheduleItem.type)) {
                                        cell = <TableCell sx={scheduleItem.type === 'Schedule' ? {color: 'green'} : {color: 'red'}} component="th" scope="row" key={index + index1}>{scheduleItem?.batchName ?? ''} {scheduleItem?.classRoomName ?? ''} {(scheduleItem?.start === 0 ? '-' : new Date(scheduleItem?.start).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }))} - {(scheduleItem?.end === 0 ? '-' : new Date(scheduleItem?.end).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }))}</TableCell>
                                    }
                                    else {
                                        cell = <TableCell sx={{color: 'red'}} component="th" scope="row" key={index + index1}>{scheduleItem?.batchName ?? ''}, {scheduleItem?.type ?? ''}</TableCell>
                                    }
                                }
                                
                                return cell;
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </div>
});

export default ScheduleFacultyView;