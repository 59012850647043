import React, { useState,useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate,useLocation } from "react-router-dom";
import apiService from '../../../../utlis/apiService';
import {
    ArrowBackIos as BackButton,
} from '@mui/icons-material';
import { getStatusType } from '../../../../utlis/commonFunction';
import { MuiTelInput } from 'mui-tel-input';
import { FormControl, FormHelperText } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import dayjs from 'dayjs';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CreateApplicationForm = () => {
    let query = useQuery();
    let id = query.get("id")
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '+91',
        course: '',
        statusType: '',
        category: '',
        subject: '',
        price: '',
        expiry: null,
    });

    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        course: '',
        statusType: '',
        category: '',
        subject: '',
        price: '',
        expiry: '',
    });
    const [courses, setCourses] = useState([]);
    const [statusType, setStatusType] = useState([]);
    const [category, setCategory] = useState([]);


    useEffect(() => {
        getData();
        fetchStatusTypeData();
    }, []);

    useEffect(() => {
        if(formData.course && formData.statusType) {
            getCategoryByCourse();
        }
    },[formData.course, formData.statusType]);

    useEffect(() => {
        if(id) {
            fetchRecordingsAdmissionData();
        }
    }, [id]);

    const fetchRecordingsAdmissionData = async () => {
        try {
            if(id) {
                const response = await apiService.get('/recordings-admission/get', {
                    params: {
                        id: id,
                    },
                });
                setFormData(response?.data?.recordings);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getData = async () => {
        try {
            const response = await apiService.get('/course/list', {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
    
            setCourses(response?.data?.course);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchStatusTypeData = async () => {
		const statusTypeData = await getStatusType();
		setStatusType(statusTypeData);
	}

    const getCategoryByCourse = async () => {
        try {
            const response = await apiService.post(`/level/list/categoryByCourse`, { courseId: formData.course, statusType: formData.statusType });
            console.log(response.data);
            setCategory(response?.data?.categories);
        } catch (error) {
            console.error(error);
        }
    }

    const handleChange = (name, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    const validateForm = () => {
        let isValid = true;
        const newErrors = {
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            course: '',
            statusType: '',
            category: '',
            subject: '',
            price: '',
            expiry: '',
        };

        if(!formData.firstName) {
            isValid = false;
            newErrors.firstName = 'Invalid first name';
        }
        if(!formData.lastName) {
            isValid = false;
            newErrors.lastName = 'Invalid last name';
        }
        if(!formData.phoneNumber) {
            isValid = false;
            newErrors.phoneNumber = 'Invalid phone number';
        }
        if(!formData.email) {
            isValid = false;
            newErrors.email = 'Invalid email';
        }
        if(!formData.course) {
            isValid = false;
            newErrors.course = 'Invalid course';
        }
        if(!formData.statusType) {
            isValid = false;
            newErrors.statusType = 'Invalid status type';
        }
        if(!formData.category) {
            isValid = false;
            newErrors.category = 'Invalid category';
        }
        if(!formData.subject) {
            isValid = false;
            newErrors.subject = 'Invalid subject';
        }
        if(!formData.price) {
            isValid = false;
            newErrors.price = 'Invalid price';
        }
        // if(!formData.expiry) {
        //     isValid = false;
        //     newErrors.expiry = 'Invalid expiry';
        // }
        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (validateForm()) {
            const murl = id ? `/recordings-admission/update?id=${id}` : `/recordings-admission/create`;
        
            try {
                const response = await apiService.post(murl, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
        
                navigate(-1);
                console.log(JSON.stringify(response.data));
            } catch (error) {
                alert(error?.response?.data?.error);
                console.error(error);
            }
        }
    };

    const handlePhoneChange = (value) => {
        setFormData((prevData) => ({
            ...prevData,
            phoneNumber: value,
        }));
    }

  const handleInputChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value?._id,
    }));
  };

  return (
    <div>
        <h1 style={{ cursor:'pointer', width: 'fit-content' }} onClick={() => navigate(-1)}>
            <BackButton />
            Recordings Applications
        </h1>
        <form onSubmit={handleSubmit}>
            <Typography variant="h4" gutterBottom>
                {id ? 'Edit' : 'Create'}  Recordings Application
            </Typography>
            <TextField
                label="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={(e) => handleChange('firstName', e.target.value)}
                fullWidth
                required
                margin="normal"
                size='small'
                error={Boolean(errors.firstName)}
                helperText={errors.firstName}
            />
            <TextField
                label="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={(e) => handleChange('lastName', e.target.value)}
                fullWidth
                required
                margin="normal"
                size='small'
                error={Boolean(errors.lastName)}
                helperText={errors.lastName}
            />
            <MuiTelInput
                label="Phone Number"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handlePhoneChange}
                fullWidth
                required
                error={Boolean(errors.phoneNumber)}
                helperText={errors.phoneNumber}
                margin="normal"
                size='small'
            />
            <TextField
                label="Email"
                name="email"
                value={formData.email}
                onChange={(e) => handleChange('email', e.target.value)}
                fullWidth
                required
                type='email'
                error={Boolean(errors.email)}
                helperText={errors.email}
                margin="normal"
                size='small'
            />

            <Autocomplete
                disablePortal
                id="course"
                name="course"
                options={courses}
                style={{marginTop:15}}
                getOptionLabel={(option) => option.name}
                value={courses.find((option) => option._id === formData.course) || null}
                onChange={(e, value) => handleInputChange('course', value)}
                size='small'
                renderInput={(params) => <TextField {...params} label="Course" error={Boolean(errors.course)} helperText={errors.course} size='small' />}
            />

            <FormControl fullWidth style={{ marginTop: '10px'}}>
                <TextField
                    label="Status Type"
                    select
                    value={formData.statusType}
                    onChange={(e) => handleChange('statusType', e.target.value)}
                    fullWidth
                    name="statusType"
                    margin="normal"
                    required
                    size='small'
                    error={Boolean(errors.statusType)}
                    helperText={errors.statusType}
                >
                    {statusType?.map((type, statusTypeIndex) => (
                        <MenuItem key={statusTypeIndex} value={type}>{type}</MenuItem>
                    ))}
                </TextField>
            </FormControl>
            <Autocomplete
                id="Category-autocomplete"
                options={category}
                value={category.find((option) => option._id === formData.category) || null}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => handleInputChange('category', newValue)}
                renderInput={(params) => <TextField {...params} error={Boolean(errors.category)} helperText={errors.category} size='small' label={'Category *'} />}
                sx={{ marginTop: '15px' }}
                size='small'
            />
            <TextField
                label="Subject"
                name="subject"
                minRows={2}
                value={formData.subject}
                onChange={(e) => handleChange('subject', e.target.value)}
                fullWidth
                required
                type='text'
                error={Boolean(errors.subject)}
                helperText={errors.subject}
                margin="normal"
                size='small'
                rows={2}
                multiline
            />
            <TextField
                label="Price"
                name="price"
                value={formData.price}
                onChange={(e) => handleChange('price', e.target.value)}
                fullWidth
                required
                type='text'
                error={Boolean(errors.price)}
                helperText={errors.price}
                margin="normal"
                size='small'
            />
            <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
                <FormControl className='gap-2 mb-8' fullWidth style={{ marginTop: '10px' }} size="small">
                    <MobileDatePicker
                        label="Expiry Date *"
                        value={formData.expiry ? dayjs(formData.expiry) : null}
                        onChange={(date) => handleChange('expiry', date)}
                        sx={{ marginTop: 1 }}
                        format="DD/MM/YYYY"
                        slotProps={{
                            textField: { size: 'small' }
                        }}
                    />
                    <FormHelperText error>{errors.expiry}</FormHelperText>
                </FormControl>
            </LocalizationProvider>

            <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }} size='small'>
                {id ? 'Update' : 'Create' }
            </Button>
    
        </form>
         
    </div>
  );
};

export default CreateApplicationForm;