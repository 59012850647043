import React, { useEffect, useState } from 'react';
import { Typography, Button, TextField, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import apiService from '../../../utlis/apiService';
import { useUser } from '../../../context/userContext';

const MyTable = () => {
    const { permissions } = useUser();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalResults, setTotalResults] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 50,
        page: 0,
    });

    const pageOption = [10, 25, 50, 100];
      

    useEffect(() => {
        getData();
    }, [searchTerm, paginationModel]);

    const handlePageChange = (newPage) => {
        // setPage(newPage);
        setPaginationModel({...paginationModel, page: newPage})
    };

    const getData = async () => {
        setLoading(true);
        try {
            const response = await apiService.get('/paper/list', {
                params: {
                    searchTerm: searchTerm,
                    page: paginationModel.page,
                    pageSize: paginationModel.pageSize,
                },
            });

            setData(response?.data?.papers);
            setTotalResults(response?.data?.totalResults);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const deleteItem = async (id) => {
        const shouldDelete = window.confirm('Are you sure you want to delete this item?');
        if (shouldDelete) {
            try {
                await apiService.post('/paper/delete', null, {
                    params: {
                        id: id,
                    },
                });
                getData();
            } catch (error) {
                console.error(error);
            }
        }
    };

    function clearLocalStorageKeysWithSubstring(substring) {
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key.includes(substring)) {
                localStorage.removeItem(key);
            }
        }
    }

    const createPress = () => {
        clearLocalStorageKeysWithSubstring('FormData');
        navigate('/dashboard/paper/add');
    };

    return (
        <div>
            <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                <Typography variant="h4" gutterBottom>
                    Subjects
                </Typography>
                {permissions.includes('createSubject') && (
                    <Button variant="contained" color="primary" style={{ marginBottom: '16px' }} onClick={createPress} size='small'>
                        + Create
                    </Button>
                )}
            </div>
            <TextField
                label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                fullWidth
                margin="normal"
                style={{ marginBottom: 10 }}
                size='small'
            />
            {loading ? (
                <CircularProgress style={{ margin: '20px auto', display: 'block' }} />
            ) : (
                <div style={{ height: '70vh', width: '100%' }}>
                    <DataGridPro
                        rows={data}
                        getRowId={(row) => row._id}
                        columns={[
                            { field: 'name', headerName: 'Name', width: 200 },
                            { field: 'course', headerName: 'Course', width: 200,valueGetter: (params) => params.row?.course?.name },
                            // { field: 'status', headerName: 'Status', width: 150 },
                            {
                                field: 'actions',
                                headerName: 'Action',
                                width: 150,
                                renderCell: (params) => (
                                    <>
                                        {permissions.includes('updateSubject') && (
                                            <Button variant="outlined" onClick={() => navigate(`/dashboard/paper/add?id=${params.row._id}`)}>Edit</Button>
                                        )}
                                        {permissions.includes('deleteSubject') && (
                                            <Button variant="outlined" color="error" style={{ marginLeft: 5 }} onClick={() => deleteItem(params.row._id)}>Delete</Button>
                                        )}
                                    </>
                                ),
                            },
                        ]}
                        pagination
                        paginationModel={paginationModel}
                        rowCount={totalResults}
                        paginationMode="server"
                        components={{ Toolbar: GridToolbar }}
                        onPaginationModelChange={setPaginationModel}
                        pageSizeOptions={pageOption}
                    />
                </div>
            )}
            {/* <div style={{ textAlign: 'right', marginTop: 20 }}>
                <Button onClick={() => handlePageChange(paginationModel.page - 1)} disabled={paginationModel.page === 1}>
                    Previous
                </Button>
                {paginationModel.page}
                <Button onClick={() => handlePageChange(paginationModel.page + 1)} disabled={paginationModel.page * paginationModel.pageSize >= totalResults}>
                    Next
                </Button>
            </div> */}
        </div>
    );
};

export default MyTable;
