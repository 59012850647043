import {
    ArrowBackIos as BackButton,
} from '@mui/icons-material';
import { Autocomplete, Button, FormControl, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';
import { useEffect, useState } from 'react';
import { generateYearRange, getStatusType } from '../../../utlis/commonFunction';
import apiService from '../../../utlis/apiService';
import { useNavigate } from 'react-router-dom';
import config from '../../../config';

const CreateStudentApplication = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '+91',
        email: '',
        year: '',
        course: null,
        courseId: '',
        statusType: '',
        category: null,
        categoryId: '',
        mode: '',
        discount: '',
        referrerPhoneNumber: '+91',
        referrerName: '',
        referrerEmail: '',
    });
    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        year: '',
        course: '',
        statusType: '',
        category: '',
        mode: '',
        discount: ''
    });
    const [courses, setCourses] = useState([]);
    const [statusType, setStatusType] = useState([]);
    const [category, setCategory] = useState([]);

    const navigate = useNavigate();
    const years = generateYearRange();

    const getCourses = async () => {
		try {
			const response = await apiService.get('/course/list');
			setCourses(response?.data?.course);
		} catch (error) {
			console.error(error);
		}
	}

    const fetchStatusTypeData = async () => {
		const statusTypeData = await getStatusType();
		setStatusType(statusTypeData);
	}

    const validatePhoneNumber = (phoneNumber) => {
		const regex = /^\+\d{1,3}\s\d{5}\s\d{5}$/;
		const regex2 = /^\+\d{1,3}\d{5}\d{5}$/;
		const regex3 = /^\+\d{1,3}\s\d{4}\s\d{4}$/;
		const regex4 = /^\+\d{1,3}\d{4}\d{4}$/;
		return regex.test(phoneNumber) || regex2.test(phoneNumber.replace(/\s/g, '')) || regex3.test(phoneNumber) || regex4.test(phoneNumber);
	}

    const getCategories = async (courseId, statusType, year) => {
		try {
			const response = await apiService.post(`/level/list/categoryByCourse`, { courseId: courseId, statusType: statusType, year: year });
			setCategory(response?.data?.categories);
		} catch (error) {
			console.error(error);
		}
	};

    const validateForm = () => {
		let isValid = true;
		const newErrors = {
			firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            year: '',
            courseId: '',
            statusType: '',
            categoryId: '',
            mode: '',
		};

        if(!formData.firstName) {
            newErrors.firstName = 'Invalid first name';
			isValid = false;
        }
        if(!formData.lastName) {
            newErrors.lastName = 'Invalid last name';
			isValid = false;
        }
        if (!validatePhoneNumber(formData.phoneNumber)) {
			newErrors.phoneNumber = 'Invalid phone number';
			isValid = false;
		}
        if(!formData.email) {
            newErrors.email = 'Invalid last email';
			isValid = false;
        }
        if(!formData.year) {
            newErrors.year = 'Invalid academic year';
			isValid = false;
        }
        if(!formData.courseId) {
            newErrors.courseId = 'Invalid academic year';
			isValid = false;
        }
        if(!formData.statusType) {
            newErrors.statusType = 'Invalid status type';
			isValid = false;
        }
        if(!formData.categoryId) {
            newErrors.categoryId = 'Invalid category';
			isValid = false;
        }
        if(!formData.mode) {
            newErrors.mode = 'Invalid mode';
			isValid = false;
        }

		setErrors(newErrors);
		return isValid;
	};

    useEffect(() => {
		getCourses();
        fetchStatusTypeData();
	}, []);

    useEffect(() => {
        if(formData.year && formData.courseId && formData.statusType) {
		    getCategories(formData.courseId, formData.statusType, formData.year);
        }
	}, [formData.year, formData.courseId, formData.statusType]);

    const handleChange = (name, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }))
    }

    const handleInputChange = (name, idName, value) => {
        setFormData((prevData) => ({
			...prevData,
			[name]: value,
            [idName]: value?._id,
		}));
    }

    const handlePhoneChange = (value) => {
		setFormData((prevData) => ({
			...prevData,
			phoneNumber: value,
		}));
	}

    const handleReferrerPhoneChange = (value) => {
        setFormData((prevData) => ({
			...prevData,
			referrerPhoneNumber: value,
		}));
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            try {
                const newData = {...formData};

                delete newData.category;
				delete newData.course;

				const response = await apiService.post('/student/registration/create', newData, {
					headers: {
						'Content-Type': 'application/json',
					},
				});
				
				alert('Application initiated!');
				navigate(`/dashboard/registrations/list`);
				
			} catch (error) {
				alert(error?.response?.data?.error);
				console.error(error);
			}
        }
    }

    return (
        <div>
            <h1 style={{ cursor: 'pointer', width: 'fit-content' }} onClick={() => navigate(-1)}>
                <BackButton />
                {`Registration`}
            </h1>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="First Name"
                    name="firstName"
                    value={formData.firstName}
                    onChange={(e) => handleChange('firstName', e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                    disabled={false}
                    size='small'
                    error={Boolean(errors.firstName)}
                    helperText={errors.firstName}
                />
                <TextField
                    label="Last Name"
                    name="lastName"
                    value={formData.lastName}
                    onChange={(e) => handleChange('lastName', e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                    size='small'
                    error={Boolean(errors.lastName)}
                    helperText={errors.lastName}
                />
                <MuiTelInput
                    label="Phone Number"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handlePhoneChange}
                    fullWidth
                    required
                    error={Boolean(errors.phoneNumber)}
                    helperText={errors.phoneNumber}
                    margin="normal"
                    size='small'
                />
                <TextField
                    label="Email"
                    name="email"
                    value={formData.email}
                    onChange={(e) => handleChange('email', e.target.value)}
                    fullWidth
                    required
                    type="email"
                    margin="normal"
                    size='small'
                    error={Boolean(errors.email)}
                    helperText={errors.email}
                />
                <Autocomplete
                    disablePortal
                    id="academicYear-box"
                    name="year"
                    size='small'
                    required
                    options={years}
                    style={{ marginTop: 15 }}
                    getOptionLabel={(option) => option}  
                    value={formData.year}
                    onChange={(e, value) => handleChange('year', value)}
                    renderInput={(params) => <TextField {...params} error={Boolean(errors.year)} helperText={errors.year} size='small' label="Academic Year *" />}
                />
                <Autocomplete
                    id="Course-autocomplete"
                    options={courses}
                    getOptionLabel={(option) => option.name}
                    value={formData.course}
                    onChange={(event, newValue) => handleInputChange('course', 'courseId', newValue)}
                    renderInput={(params) => <TextField {...params} error={Boolean(errors.course)} helperText={errors.course} size='small' label={'Course *'} />}
                    sx={{ marginTop: '15px' }}
                    required
                    size='small'
                />
                <FormControl fullWidth style={{ marginTop: '10px'}}>
                    <TextField
                        label="Status Type"
                        select
                        value={formData.statusType}
                        onChange={(e) => handleChange('statusType', e.target.value)}
                        fullWidth
                        name="statusType"
                        margin="normal"
                        required
                        size='small'
                        error={Boolean(errors.statusType)}
                        helperText={errors.statusType}
                    >
                        {statusType?.map((type, statusTypeIndex) => (
                            <MenuItem key={statusTypeIndex} value={type}>{type}</MenuItem>
                        ))}
                    </TextField>
                </FormControl>
                <Autocomplete
                    id="Category-autocomplete"
                    options={category}
                    value={formData.category}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => handleInputChange('category', 'categoryId', newValue)}
                    renderInput={(params) => <TextField {...params} error={Boolean(errors.category)} helperText={errors.category} size='small' label={'Category *'} />}
                    sx={{ marginTop: '15px' }}
                    size='small'
                />
                <FormControl fullWidth sx={{ marginTop: '15px' }}>
                    <TextField
                        label="Mode"
                        select
                        value={formData.mode}
                        onChange={(e) => handleChange('mode', e.target.value)}
                        fullWidth
                        name="mode"
                        margin="normal"
                        required
                        size='small'
                        error={Boolean(errors.mode)}
                        helperText={errors.mode}
                    >
                        <MenuItem value="">Select Mode</MenuItem>
                        <MenuItem value="Offline">Offline</MenuItem>
                        <MenuItem value="Online">Online</MenuItem>
                        <MenuItem value="Hybrid">Hybrid</MenuItem>
                        <MenuItem value="Recordings">Recordings</MenuItem>
                        <MenuItem value="Online Plus Recordings">Online Plus Recordings</MenuItem>
                    </TextField>
                </FormControl>
                <TextField
                    label="Discount"
                    name="discount"
                    value={formData.discount}
                    onChange={(e) => handleChange('discount', e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                    size='small'
                    error={Boolean(errors.discount)}
                    helperText={errors.discount}
                />

                <Typography variant='h6' sx={{ marginTop: '10px' }}>Referrer</Typography>

                <MuiTelInput
                    label="Referrer Phone Number"
                    name="referrerPhoneNumber"
                    value={formData.referrerPhoneNumber}
                    onChange={handleReferrerPhoneChange}
                    fullWidth
                    error={Boolean(errors.referrerPhoneNumber)}
                    helperText={errors.referrerPhoneNumber}
                    margin="normal"
                    size='small'
                />
                <TextField
                    label="Referrer Name"
                    name="referrerName"
                    value={formData.referrerName}
                    onChange={(e) => handleChange('referrerName', e.target.value)}
                    fullWidth
                    margin="normal"
                    size='small'
                    error={Boolean(errors.referrerName)}
                    helperText={errors.referrerName}
                />
                <TextField
                    label="Referrer Email"
                    name="referrerEmail"
                    value={formData.referrerEmail}
                    onChange={(e) => handleChange('referrerEmail', e.target.value)}
                    fullWidth
                    margin="normal"
                    size='small'
                    error={Boolean(errors.referrerEmail)}
                    helperText={errors.referrerEmail}
                />

                <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>Create</Button>
            </form>
        </div>
    );
}

export default CreateStudentApplication;