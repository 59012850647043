import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './loginForm'
import SubmitForm from './submitForm'
import HomePage from './home'
import Attendance from './pages/attendance'
import Materials from './pages/materials'
import Academic from './pages/academics/academic';
import Notification from './pages/notification'
import Payments from './pages/payments'
import Profile from './pages/profile';
import StudentRegistration from './registrationForm';
import RecordingsApplication from './recordingsApplicationForm';

const StudentApp = () => {
  const sToken = localStorage.getItem('stoken');

  return (
    <div>
      <Routes>
        <Route path="/" element={sToken ? (<Navigate replace to={'home'} />) : (<Login />)} />
        <Route path="/submit" element={<SubmitForm />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/attendance" element={<Attendance />} />
        <Route path="/materials" element={<Materials />} />
        <Route path="/academics" element={<Academic />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/payments" element={<Payments />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/register" element={<StudentRegistration />} />
        <Route path="/recordings/register" element={<RecordingsApplication />} />
      </Routes>
    </div>
  );
};

export default StudentApp;