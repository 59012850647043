import { AppBar, Box, Button, Card, CircularProgress, Container, Grid, IconButton, Modal, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import studentApiService from "../../utlis/apiStudent";
import { useEffect, useState } from "react";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Receipt from "../../utlis/receipt";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    width: '60vw',
    maxHeight: '90vh', 
    overflowY: 'scroll',
};

const Payments = () => {
    const navigate = useNavigate();
    const [student, setStudent] = useState([]);
    const [admissionTabValue, setAdmissionTabValue] = useState('admission0');
    const [totalPaidAmount, setTotalPaidAmount] = useState(0);
    const [totalDueAmount, setTotalDueAmount] = useState(0);
    const [totalPendingAmount, setTotalPendingAmount] = useState(0);
    const [receiptModal, setReceiptModal] = useState(false);
    const [receiptData, setReceiptData] = useState({});
    const [loader, setLoader] = useState(false);

    const handleBack = () => {
        navigate(-1);
    };

    const getDetails = async () => {
        try {
            const response = await studentApiService.get('/user/get/me');
            
            if(response?.data?.status === 'success'){
                setStudent(response?.data?.student);
                calculateTotals(response?.data?.student?.admission);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const calculateTotals = (admissions) => {
        let paidAdmissionFee = 0;
        let paidOts = 0;
        let paidEmi = 0;
        let paidDiscount = 0;

        let pendingAdmissionFee = 0;
        let pendingOts = 0;
        let pendingEmi = 0;
        let pendingDiscount = 0;

        let unallocatedFee = 0;

        let otsDue = 0;
        let emiDue = 0;
        let discountDue = 0;

        let refundAmount = 0;

        let totalDiscount = 0; // for temporary use unallocated need to correct in student. remove this after correction

        admissions?.map(admission => {
            admission?.category?.map(category => {
                if(category?.admissionFeeCollected === true) {
                    paidAdmissionFee += category?.admissionFee ? parseFloat(category?.admissionFee) : 0;
                }
                else {
                    pendingAdmissionFee += category?.admissionFee ? parseFloat(category?.admissionFee) : 0;
                }

                if(category?.ots?.amount !== 0 && category?.ots?.received) {
                    paidOts += category?.ots?.amount ? parseFloat(category?.ots?.amount) : 0;
                }
                else {
                    if(category?.ots?.amount !== 0) {
                        pendingOts += category?.ots?.amount ? parseFloat(category?.ots?.amount) : 0;
                        if(new Date(category?.ots?.dueDate) > new Date()) {
                            otsDue += category?.ots?.amount ? parseFloat(category?.ots?.amount) : 0;
                        }
                            
                    }
                }

                paidEmi += category?.feeSplit?.reduce((sum, dis) => sum + parseFloat((dis.value === '' || isNaN(dis.value)) || dis.received === false ? 0 : dis.value), 0);
                paidDiscount += category?.discount?.reduce((sum, dis) => sum + parseFloat((dis.value === '' || isNaN(dis.value)) || dis.received === false || dis.discountType !== 'Instant Discount' ? 0 : dis.value), 0);
                pendingEmi += category?.feeSplit?.reduce((sum, dis) => sum + parseFloat((dis.value === '' || isNaN(dis.value)) || dis.received === true ? 0 : dis.value), 0);
                pendingDiscount += category?.discount?.reduce((sum, dis) => sum + parseFloat((dis.value === '' || isNaN(dis.value)) || (dis.received === true && dis.discountType === 'Instant Discount') ? 0 : (dis.received === false && dis.discountType !== 'Instant Discount') ? 0 : dis.value), 0);
                emiDue += category?.feeSplit?.reduce((sum, dis) => sum + parseFloat((dis.value === '' || isNaN(dis.value)) || (new Date(dis.dueDate) <= new Date() && dis.received === true) ? 0 : dis.value), 0);
                discountDue += category?.discount?.reduce((sum, dis) => sum + parseFloat((dis.value === '' || isNaN(dis.value)) || (new Date(dis.dueDate) <= new Date() && dis.received === true && dis.discountType === 'Instant Discount') ? 0 : (new Date(dis.dueDate) >= new Date() && dis.received === false && dis.discountType === 'Instant Discount') ? dis.value : 0), 0);
                refundAmount += category?.excessOrRefund?.reduce((sum, dis) => sum + parseFloat((dis.amount === '' || isNaN(dis.amount)) || dis.paid === false || dis.type === 'Excess' ? 0 : dis.amount), 0);

                totalDiscount += category?.discount?.reduce((sum, dis) => sum + parseFloat(((dis.value === '') || isNaN(dis.value)) || (dis.discountType === 'Instant Discount') ? 0 : dis.value), 0);

            });

            // for temporary use unallocated need to correct in student. remove this after correction
            unallocatedFee += admission?.category?.reduce((sum, dis) => sum + parseFloat((dis.unallocatedFee === '' || isNaN(dis.unallocatedFee)) ? 0 : dis.unallocatedFee), 0);
        });
        
        setTotalPaidAmount(paidAdmissionFee + paidOts + paidEmi - refundAmount);
        setTotalPendingAmount((pendingAdmissionFee + pendingOts + pendingEmi + unallocatedFee));
        setTotalDueAmount(otsDue + emiDue);
    }

    useEffect(() => {
        getDetails();
    },[]);

    const formatDate = (dateString) => {
        if(dateString) {
            const options = { year: 'numeric', month: 'short', day: 'numeric' };
            const date = new Date(dateString);
            return date.toLocaleDateString('en-US', options);
        }
        else {
            return '-';
        }
    };

    const handleAdmissionTabChange = (e, value) => {
		setAdmissionTabValue(value);
	}

    const printAdmissionFeeReceipt = async(index, itemIndex) => {
        processReceipt('Admission Fee', index, itemIndex);
    }

    const printOtsFeeReceipt = async(index, itemIndex) => {
		processReceipt('Course Fee One Time Settlement', index, itemIndex);
	}

    const printEmiFeeReceipt = async(index, itemIndex, splitIndex, Instant = '') => {
        processReceipt('Course Fee EMI', index, itemIndex, splitIndex, Instant);
	}

    const processReceipt = async (type, index, itemIndex, splitIndex = '', Instant) => {
        const studentMe = student;
        const admissions = studentMe?.admission;
        
		const currentAdmission = admissions[index];
		const category = currentAdmission.category[itemIndex];

        const data = {
			admissionId: currentAdmission._id,
			categoryId: category._id,
		}

        const Query = {
            student: `${studentMe.firstName} ${studentMe.lastName}`,
            phone: studentMe.phoneNumber,
            
            category: `${currentAdmission?.course?.name} ${currentAdmission.statusType}`,
        }

        if(type === 'Admission Fee') {
            data.paymentType = 'Admission Fee';
            data.paymentMethod = category.admissionFeePaymentMethod;

            Query.paymentType = 'Admission Fee';
            Query.method = category.admissionFeePaymentMethod;
            Query.feeAmount = category.admissionFee;
        }
        else if(type === 'Course Fee One Time Settlement') {
            const paymentDueDate = new Date(category.ots.dueDate).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' });

            data.paymentType = 'Course Fee One Time Settlement';
			data.paymentMethod = category.ots.paymentMethod;

            Query.paymentType = 'Course Fee One Time Settlement';
            Query.method = category.ots.paymentMethod;
            Query.feeAmount = category.ots.amount;
            Query.paymentDueDate = paymentDueDate;
        }
        else if(type === 'Course Fee EMI') {
            if(Instant === '') {
                const feeSplit = category.feeSplit[splitIndex];
                const nextFeeSplit = category.feeSplit[splitIndex + 1];

                const paymentDueDate = new Date(feeSplit.dueDate).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' });
                const nextInstallment = nextFeeSplit ? nextFeeSplit.value : '';
			    const installmentDueDate = nextFeeSplit ? new Date(nextFeeSplit.dueDate).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }) : '';

                data.paymentType = 'Course Fee EMI';
                data.paymentMethod = feeSplit.paymentMethod;
                data.paymentId = feeSplit._id;

                Query.paymentType = 'Course Fee EMI';
                Query.method = feeSplit.paymentMethod;
                Query.feeAmount = feeSplit.value;
                Query.paymentDueDate = paymentDueDate;
				Query.totalPaid = parseFloat(calculatePaidFee(category));
				Query.balance = parseFloat(calculateBalanceFee(category) + category.unallocatedFee);
				Query.nextInstallment = nextInstallment;
				Query.installmentDueDate = installmentDueDate;
            }
            else {
                const feeSplit = category.discount[splitIndex];

                const paymentDueDate = new Date(feeSplit.dueDate).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' });

                data.paymentType = 'Course Fee EMI';
                data.paymentMethod = 'Cash';
                data.isInstant = true;
                data.paymentId = feeSplit._id;

                Query.paymentType = 'Course Fee EMI';
                Query.method = 'Cash';
                Query.feeAmount = feeSplit.value;
                Query.paymentDueDate = paymentDueDate;
				Query.totalPaid = parseFloat(calculatePaidFee(category));
				Query.balance = parseFloat(calculateBalanceFee(category) + category.unallocatedFee);
            }
        }

		const receiptData = await getReceipt(data);

		if(receiptData){

            const receiptDate = new Date(receiptData.date).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' });
            
            Query.receiptNumber = receiptData?.referenceNumber;
            Query.date = receiptDate;

			setReceiptModal(true);
			
			setReceiptData(Query);

            if(type === 'Admission Fee') {
                if(!currentAdmission._id || !category._id) {
                    category.admissionFeePayment = receiptData._id;
                    currentAdmission.category[itemIndex] = category;
                    admissions[index] = currentAdmission;
                    studentMe.admission = admissions;
                    setStudent(studentMe);
                }
            }
            else if(type === 'Course Fee One Time Settlement') {
                if(!currentAdmission._id || !category._id) {
                    category.ots.payment = receiptData._id;
                    currentAdmission.category[itemIndex] = category;
                    admissions[index] = currentAdmission;
                    studentMe.admission = admissions;
                    setStudent(studentMe);
                }
            }
            else if(type === 'Course Fee EMI') {
                if(Instant === '') {
                    const feeSplit = category.feeSplit[splitIndex];
                    if(!currentAdmission._id || !category._id || !feeSplit._id) {

                        feeSplit.payment = receiptData._id;
                        category.feeSplit[splitIndex] = feeSplit;
                        currentAdmission.category[itemIndex] = category;
                        admissions[index] = currentAdmission;
                        studentMe.admission = admissions;
                        setStudent(studentMe);
                    }
                }
                else {
                    const feeSplit = category.discount[splitIndex];
                    if(!currentAdmission._id || !category._id || !feeSplit._id) {

                        feeSplit.payment = receiptData._id;
                        category.feeSplit[splitIndex] = feeSplit;
                        currentAdmission.category[itemIndex] = category;
                        admissions[index] = currentAdmission;
                        studentMe.admission = admissions;
                        setStudent(studentMe);
                    }
                }
            }
		}
		else {
			alert('Something went wrong. Please try again');
		}
    }

    const calculatePaidFee = (item) => {

        const ots = item.ots.received && item.ots.amount > 0 ? item.ots.amount : 0;
        const totalSum = item.feeSplit?.reduce((sum, payment) => {
            const value = parseFloat(payment.value)
            if(!isNaN(value) && payment.received){
                return sum + value;
            } 
            return sum;
        }, 0);

        return totalSum + ots;
    }

    const calculateBalanceFee = (item) => {

        const ots = !item.ots.received && item.ots.amount > 0 ? item.ots.amount : 0;
        const totalSum = item.feeSplit?.reduce((sum, payment) => {
            const value = parseFloat(payment.value)
            if(!isNaN(value) && !payment.received){
                return sum + value;
            } 
            return sum;
        }, 0);

        return totalSum + ots;
    }

    const getReceipt = async (data) => {
		try {
			const receiptResponse = await studentApiService.post(`/user/print-receipt`, data, {
				headers: {
					'Content-Type': 'application/json',
				},
			});
			// console.log(receiptResponse?.data);

			if(receiptResponse?.data?.status === 'success'){
				return receiptResponse?.data?.receipt;
			}
			
		} catch(err) {
			console.error(err);
			if(err.response?.status === 400) {
				alert(err.response?.data?.message);
			}
		}
	}

    const payNow = async (index, categoryIndex, splitIndex, amount, type) => {
        const admissions = student?.admission;
        const currentAdmission = admissions[index];
		const category = currentAdmission.category[categoryIndex];

        let feeSplitId = '';
        if(type === 'EMI') {
            const feeSplit = category.feeSplit[splitIndex];
            feeSplitId = feeSplit._id;
        }

        const data = {
            admissionId: currentAdmission?._id,
            categoryObjId: category?._id,
            feeSplitId: feeSplitId,
            courseId: currentAdmission?.course?._id,
            categoryId: category?.category?._id,
            amount: amount,
            type: type,
            paperId: category?.papers,
        }

        setLoader(true);

        try {
            const response = await studentApiService.post(`/user/payment-initiate`, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const paymentLink = response?.data?.payment_links?.web;
            if(paymentLink) {
                window.open(paymentLink, '_self');
            }
            else {
                alert('Something went wrong');
                window.location.reload();
            }

        } catch(err) {
			console.error(err);
			if(err.response?.status === 400) {
				alert(err.response?.data?.message);
			}
		} finally {
            setLoader(false);
        }
    }

    if(loader) {
        return <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center', height: '100vh', width: '100%' }}>
            <CircularProgress style={{ margin: 'auto' }} />
        </div>
    }
    else {

        return (
            <Container maxWidth={'100%'} sx={{ paddingLeft: 0 }} style={{ padding: 0 }}>
                <AppBar position="static" sx={{ marginBottom: 2 }}>
                    <Toolbar>
                        <IconButton size="large" edge="start" color="inherit" onClick={handleBack} sx={{ mr: 2 }}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h6" component="div">
                            Payment Details
                        </Typography>
                    </Toolbar>
                </AppBar>
                
                <div style={{marginLeft: '10%', marginRight: '10%', marginTop: '5%'}}>
                    <Grid container spacing={2} alignItems="center" sx={{ marginBottom: 3 }}>
                        <Grid item xs={12} md={4}>
                            <Card sx={{ padding: 2, backgroundColor: '#bcf5bd' }}>
                                <Typography variant='h6'>
                                    Total Paid Amount:
                                    <br />
                                    {totalPaidAmount}
                                </Typography>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card sx={{ padding: 2, backgroundColor: '#e69e9a' }}>
                                <Typography variant='h6'>
                                    Total Due Amount:
                                    <br />
                                    {totalDueAmount}
                                </Typography>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card sx={{ padding: 2, backgroundColor: '#dde6ee' }}>
                                <Typography variant='h6'>
                                    Total Pending Amount:
                                    <br />
                                    {totalPendingAmount}
                                </Typography>
                            </Card>
                        </Grid>
                    </Grid>

                    <TabContext value={admissionTabValue}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleAdmissionTabChange} aria-label="admissions tab">
                                {student?.admission?.map((admission, index) => (
                                    <Tab label={admission?.course?.name} value={`admission${index}`} key={index} />
                                ))}
                            </TabList>
                        </Box>
                        {student?.admission?.map((admission, index) => (
                            <TabPanel value={`admission${index}`}>
                                {admission?.category?.map((category, categoryIndex) => (
                                    <TableContainer component={Paper} key={categoryIndex} sx={{ marginBottom: '20px' }}>
                                        <Typography sx={{ paddingLeft: '5px', paddingTop: '5px' }} variant="" component="p">{category?.category?.name}</Typography>
                                        <hr />
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Category</TableCell>
                                                    <TableCell>Fee Amount</TableCell>
                                                    <TableCell>Due Date</TableCell>
                                                    <TableCell>Paid Date</TableCell>
                                                    <TableCell>Status</TableCell>
                                                    <TableCell>Transaction Id</TableCell>
                                                    <TableCell>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {category?.admissionFee !== '' && category?.admissionFee !== 0 ?
                                                    <TableRow>
                                                        <TableCell>Admission Fee</TableCell>
                                                        <TableCell>{category?.admissionFee}</TableCell>
                                                        <TableCell>-</TableCell>
                                                        <TableCell>{category?.admissionFeePaidDate ? formatDate(category?.admissionFeePaidDate) : '-' }</TableCell>
                                                        <TableCell>{category?.admissionFeeCollected === true ? <span style={{color: "#4caf50"}}>Paid</span> : <span style={{color: "#f50057"}}>Pending</span>}</TableCell>
                                                        <TableCell>{category?.admissionFeeCollected === true ? category.transaction : '-' }</TableCell>
                                                        <TableCell>{category?.admissionFeeCollected === true ? <Button variant="outlined" title="Print Invoice" onClick={() => printAdmissionFeeReceipt(index, categoryIndex)}><LocalPrintshopIcon /></Button>: <Button variant="outlined" title="Pay Now" onClick={() => payNow(index, categoryIndex, '', category?.admissionFee, 'Admission Fee')}>Pay Now</Button>}</TableCell>
                                                    </TableRow>
                                                : ''}
                                                {category?.ots?.amount !== 0 ?
                                                    <TableRow>
                                                        <TableCell>One Time Settlement</TableCell>
                                                        <TableCell>{category?.ots?.amount}</TableCell>
                                                        <TableCell>{formatDate(category?.ots?.dueDate)}</TableCell>
                                                        <TableCell>{category?.ots?.received === true ? formatDate(category?.ots?.paidDate) : '-'}</TableCell>
                                                        <TableCell>{category?.ots?.received === true ? <span style={{color: "#4caf50"}}>Paid</span> : (new Date(category?.ots?.dueDate) > new Date()) && (category?.ots?.received === false) ? <span style={{color: "#ff9800"}}>Pending</span> : <span style={{color: "#f50057"}}>Pending</span>}</TableCell>
                                                        <TableCell>{category?.ots?.received === true ? category?.ots?.transaction : '-' }</TableCell>
                                                        <TableCell>{category?.ots?.received === true ? <Button variant="outlined" title="Print Invoice" onClick={() => printOtsFeeReceipt(index, categoryIndex)}><LocalPrintshopIcon /></Button> : <Button variant="outlined" title="Pay Now" onClick={() => payNow(index, categoryIndex, '', category?.ots?.amount, 'OTS')}>Pay Now</Button>}</TableCell>
                                                    </TableRow>
                                                : ''}
                                                {category?.feeSplit?.length ?
                                                    category?.feeSplit.map((row, splitIndex) => (
                                                        <TableRow key={row._id}>
                                                            <TableCell>Installment</TableCell>
                                                            <TableCell>{row?.value}</TableCell>
                                                            <TableCell>{(new Date(row?.dueDate) <= new Date()) && (row.received === false) ? <span style={{color: "#f50057"}}>{formatDate(row?.dueDate)}</span> : formatDate(row?.dueDate)}</TableCell>
                                                            <TableCell>{row.received === true ? formatDate(row?.paidDate) : '-' }</TableCell>
                                                            <TableCell>{row?.received === true ? <span style={{color: "#4caf50"}}>Paid</span> : (new Date(row?.dueDate) > new Date()) && (row.received === false) ? <span style={{color: "#ff9800"}}>Pending</span> : <span style={{color: "#f50057"}}>Pending</span> }</TableCell>
                                                            <TableCell>{row?.received === true ? row?.transaction : '-' }</TableCell>
                                                            <TableCell>{row?.received === true ? <Button variant="outlined" title="Print Invoice" onClick={() => printEmiFeeReceipt(index, categoryIndex, splitIndex)}><LocalPrintshopIcon /></Button> : <Button variant="outlined" title="Pay Now" onClick={() => payNow(index, categoryIndex, splitIndex, row?.value, 'EMI')}>Pay Now</Button> }</TableCell>
                                                        </TableRow>
                                                    ))
                                                : ''}
                                                {category?.discount?.length ? 
                                                    category?.discount?.map((discount, discountIndex) => {
                                                        if(discount?.discountType === 'Instant Discount'){
                                                            return (<TableRow key={discount._id}>
                                                                <TableCell>Installment</TableCell>
                                                                <TableCell>{discount?.value}</TableCell>
                                                                <TableCell>{(new Date(discount?.dueDate) <= new Date()) && (discount.received === false) ? <span style={{color: "#f50057"}}>{formatDate(discount?.dueDate)}</span> : formatDate(discount?.dueDate)}</TableCell>
                                                                <TableCell>{discount.received === true ? formatDate(discount?.paidDate) : '-' }</TableCell>
                                                                <TableCell>{discount?.received === true ? <span style={{color: "#4caf50"}}>Paid</span> : (new Date(discount?.dueDate) > new Date()) && (discount.received === false) ? <span style={{color: "#ff9800"}}>Pending</span> : <span style={{color: "#f50057"}}>Pending</span> }</TableCell>
                                                                <TableCell>{discount?.received === true ? discount?.transaction : '-' }</TableCell>
                                                                <TableCell>{discount?.received === true ? <Button variant="outlined" title="Print Invoice" onClick={() => printEmiFeeReceipt(index, categoryIndex, discountIndex, 'instantSettilemnt')}><LocalPrintshopIcon /></Button> : '' }</TableCell>
                                                            </TableRow>)
                                                        }
                                                        else {
                                                            return (<TableRow key={discount._id}>
                                                                <TableCell>Discount ({discount?.discountType})</TableCell>
                                                                <TableCell>{discount?.value}</TableCell>
                                                                <TableCell>-</TableCell>
                                                                <TableCell>-</TableCell>
                                                                <TableCell>-</TableCell>
                                                                <TableCell>-</TableCell>
                                                            </TableRow>)
                                                        }
                                                    })
                                                : ''}
                                                {category?.excessOrRefund?.length ? 
                                                    category?.excessOrRefund?.map((refund) => {
                                                        if(refund?.type === 'Refund'){
                                                            return (<TableRow>
                                                                <TableCell>{refund?.type}</TableCell>
                                                                <TableCell>{refund?.amount}</TableCell>
                                                                <TableCell>-</TableCell>
                                                                <TableCell>{refund.paid === true ? formatDate(refund?.paidDate) : '-' }</TableCell>
                                                                <TableCell>{refund?.paid === true ? <span style={{color: "#4caf50"}}>Paid</span> : <span style={{color: "#f50057"}}>Pending</span> }</TableCell>
                                                                <TableCell>-</TableCell>
                                                            </TableRow>)
                                                        }
                                                    })
                                                : ''}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ))}
                            </TabPanel>
                        ))}
                    </TabContext>

                    <Modal
                        open={receiptModal}
                        onClose={() => setReceiptModal(false)}
                        aria-labelledby="modal-title"
                    >
                        <Box sx={{ ...style }}>
                            <Receipt {...receiptData} />
                            <Button onClick={() => { setReceiptModal(false)}} sx={{ marginTop: 2}} variant="outlined">Close</Button>
                        </Box> 
                    </Modal>
                </div>
            </Container>
        );
    }
    
}

export default Payments;