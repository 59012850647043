import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import config from '../../../config';
import apiService from '../../../utlis/apiService';
import {
	ArrowBackIos as BackButton,
} from '@mui/icons-material';
import {
	TextField,
	Button,
	Typography,
	Select,
	MenuItem,
	Autocomplete,
	Box,
	Tab,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Grid,
	FormControl,
	InputLabel,
	FormHelperText,
} from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { MuiTelInput } from 'mui-tel-input';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import 'dayjs/locale/en';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { getPaymentMethod, getQualification, getStatusType } from '../../../utlis/commonFunction';

dayjs.extend(AdvancedFormat);

function useQuery() {
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    width: '60vw',
    maxHeight: '90vh', 
    overflowY: 'scroll',
};

const EditStudentApplication = () => {
	const query = useQuery();
	const id = query.get('id');
	const admissionId = query.get('admission');
	const categoryId = query.get('category');
	const navigate = useNavigate();
	const [courses, setCourses] = useState([]);
	const [admissionTabValue, setAdmissionTabValue] = useState('admission0');
	const [categoryTabValue, setCategoryTabValue] = useState('category00');
	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		joiningDate: new Date(),
		phoneNumber: '+91',
		parentNumber: '+91',
		email: '',
		whatsappNumber: '+91',
		address: '',
		country: '',
		state: '',
		city: '',
		pinCode : '',
		qualification: '',
		status: 'Active',
		gender: 'male',
		initialRegistration: false,
		registrationNumber: '',
		documents: [],
	});
	const [admission, setAdmission] = useState([
		{
			course: '',
			selectedCourse: null,
			statusType: '',
			categoryList: [], // List category based on course
			category: [],
		}
	]);
	const [statusType, setStatusType] = useState([]);
	const qualifications = getQualification();
	const paymentMethods = getPaymentMethod();

  	useEffect(() => {
		if (id) {
			fetchStudentData();
		}
  	}, [id]);

	const fetchStudentData = async () => {
		try {
			const response = await apiService.get(`/student/get?id=${id}`);
			
			const admissionData = await Promise.all(
				response?.data?.admission?.map(async adm => {
					if(adm._id === admissionId) {
						return {
							...adm,
							selectedCourse: adm?.course,
							categoryList: await getCategories(adm?.course?._id, adm?.statusType, adm?.year),
							course: adm?.course?._id,
							category: adm?.category?.map(cat => {
								if(cat._id === categoryId) {
									const category = {
										...cat.category,
										...cat,
									}

									const modeBasedFee = category?.fee.find(fee => fee.mode === category.mode);

									category.emiSchemeList = modeBasedFee?.emiSchemes;
									category.selectedEmiScheme = modeBasedFee?.emiSchemes?.find(scheme => scheme?._id === category.emiScheme);
									
									let unallocatedFee = 0;

									if(category.feeSplit.length && category.discount.length) {
										const totalDiscount = category.discount?.reduce((sum, dis) => sum + parseFloat((dis.value === null || dis.value === '' || isNaN(dis.value)) ? 0 : dis.value), 0);
										const totalFeeSplit = category.feeSplit?.reduce((sum, dis) => sum + parseFloat((dis.value === '' || isNaN(dis.value)) ? 0 : dis.value), 0);
										const totalRefund = category.excessOrRefund?.reduce((sum, dis) => {
											const value = parseFloat(dis.amount)
											if(!isNaN(value) && dis.type === 'Refund'){
												return sum + value;
											}
											return sum;
										}, 0);

										const totalDeduction = category.excessOrRefund?.reduce((sum, dis) => {
											const value = parseFloat(dis.deductionAmount)
											if(!isNaN(value) && dis.type === 'Refund'){
												return sum + value;
											}
											return sum;
										}, 0);

										if(cat?.ots?.amount > 0 && cat?.ots?.received) {
											unallocatedFee = category.totalFee - cat?.ots?.amount - totalFeeSplit - totalDiscount + totalRefund + totalDeduction;
										}
										else {
											unallocatedFee = category.totalFee - totalFeeSplit - totalDiscount + totalRefund + totalDeduction;
										}
									}
									else if(category.feeSplit.length) {
										const totalFeeSplit = category.feeSplit?.reduce((sum, dis) => sum + parseFloat((dis.value === '' || isNaN(dis.value)) ? 0 : dis.value), 0);
										const totalRefund = category.excessOrRefund?.reduce((sum, dis) => {
											const value = parseFloat(dis.amount)
											if(!isNaN(value) && dis.type === 'Refund'){
												return sum + value;
											}
											return sum;
										}, 0);

										const totalDeduction = category.excessOrRefund?.reduce((sum, dis) => {
											const value = parseFloat(dis.deductionAmount)
											if(!isNaN(value) && dis.type === 'Refund'){
												return sum + value;
											}
											return sum;
										}, 0);

										if(cat?.ots?.amount > 0 && cat?.ots?.received) {
											unallocatedFee = category.totalFee - cat?.ots?.amount - totalFeeSplit + totalRefund + totalDeduction;
										}
										else {
											unallocatedFee = category.totalFee - totalFeeSplit + totalRefund + totalDeduction;
										}
									}
									else if(category.discount.length) {
										const totalDiscount = category.discount?.reduce((sum, dis) => sum + parseFloat((dis.value === null || dis.value === '' || isNaN(dis.value)) ? 0 : dis.value), 0);
										const totalRefund = category.excessOrRefund?.reduce((sum, dis) => {
											const value = parseFloat(dis.amount)
											if(!isNaN(value) && dis.type === 'Refund'){
												return sum + value;
											}
											return sum;
										}, 0);

										const totalDeduction = category.excessOrRefund?.reduce((sum, dis) => {
											const value = parseFloat(dis.deductionAmount)
											if(!isNaN(value) && dis.type === 'Refund'){
												return sum + value;
											}
											return sum;
										}, 0);

										if(cat?.ots?.amount > 0 && cat?.ots?.received) {
											unallocatedFee = category.totalFee - cat?.ots?.amount - totalDiscount + totalRefund + totalDeduction;
										}
										else {
											unallocatedFee = category.totalFee - totalDiscount + totalRefund + totalDeduction;
										}
									}
									else if(cat?.ots?.amount > 0 && cat?.ots?.received) {
										const totalRefund = category.excessOrRefund?.reduce((sum, dis) => {
											const value = parseFloat(dis.amount)
											if(!isNaN(value) && dis.type === 'Refund'){
												return sum + value;
											}
											return sum;
										}, 0);

										const totalDeduction = category.excessOrRefund?.reduce((sum, dis) => {
											const value = parseFloat(dis.deductionAmount)
											if(!isNaN(value) && dis.type === 'Refund'){
												return sum + value;
											}
											return sum;
										}, 0);

										unallocatedFee = category.totalFee - cat?.ots?.amount + totalRefund + totalDeduction;
									}

									if(!cat?.feeSplit?.length && !cat?.ots?.amount){
										
										const otsData = {
											amount: cat.totalFee,
											received: false,
											paidDate: null,
											dueDate: null,
											paymentMethod: '',
										}

										category.ots = otsData;
									}

									category.unallocatedFee = unallocatedFee;
									
									return category;
								}
								else {
									return null;
								}
							}).filter(category => category !== null),
						}
					}
					else {
						return null;
					}
				})
			);
			
			setAdmission(admissionData.filter(admission => admission !== null));

			setFormData(response?.data);
			setFormData({...formData, ...response?.data});
		} catch (error) {
			console.error(error);
		}
	}
  
	useEffect(() => {
		getCourses();
		fetchStatusTypeData();
	}, []);

	const fetchStatusTypeData = async () => {
		const statusTypeData = await getStatusType();
		setStatusType(statusTypeData);
	}

	const getCategories = async (courseId, statusType, year) => {
		try {
			const response = await apiService.post(`/level/list/categoryByCourse`, { courseId: courseId, statusType: statusType, year: year });
			return response?.data?.categories;
		} catch (error) {
			console.error(error);
		}
	};


	const getCourses = async () => {
		try {
			const response = await apiService.get('/course/list');
			setCourses(response?.data?.course);
		} catch (error) {
			console.error(error);
		}
	};

	const [errors, setErrors] = useState({
		phoneNumber: '',
		parentNumber: '',
	});

	const handleChange = (e) => {
		const { name, value } = e.target;

		if(name === 'firstName' || name === 'lastName'){
			if(value && !/^[A-Za-z ]+$/.test(value)){
				alert('Accept only letters and spaces!');
			}
			else{
				setFormData((prevData) => ({
					...prevData,
					[name]: value,
				}));
			}
		}
		else {
			setFormData((prevData) => ({
				...prevData,
				[name]: value,
			}));
		}
		
	};

	const handleJoiningDateChange = (date) => {
		setFormData((prevData) => ({
			...prevData,
			joiningDate: date,
		}));
		
	};

	const handlePhoneChange = (value) => {
		setFormData((prevData) => ({
			...prevData,
			phoneNumber: value,
		}));

		if(validatePhoneNumber(value)){
			handlePhoneDuplicateCheck('student', value);
		}
	}

	const handleWhatsappNumberChange = (value) => {
		setFormData((prevData) => ({
			...prevData,
			whatsappNumber: value,
		}));

		if(validatePhoneNumber(value)){
			handlePhoneDuplicateCheck('whatsapp', value);
		}
	}

	const handleParentPhoneChange = (value) => {
		setFormData((prevData) => ({
			...prevData,
			parentNumber: value,
		}));
		if(validatePhoneNumber(value)){
			handlePhoneDuplicateCheck('parent', value);
		}
	}

	const handleChangeQualification = (e, value) => {
		setFormData((prevData) => ({
			...prevData,
			qualification: value,
		}));
	}

	const handlePhoneDuplicateCheck = async (type, number) => {
		try {
		let data = {};
		if(type === 'student'){
			data = {
				phoneNumber: number,
				id: id,
			}
		}
		else if(type === 'parent'){
			data = {
				parentNumber: number,
				id: id,
			}
		}
		else {
			data = {
				whatsappNumber: number,
				id: id,
			}
		}
		
		const response = await apiService.post(`${config.apiUrl}/student/checkStudentPhoneExist`, data, {
			headers: {
				'Content-Type': 'application/json',
			},
		});

		if(response?.data?.status === 'error' && response?.data?.studentPhone === true){
			alert(response?.data?.message);
			setFormData({
				...formData,
				phoneNumber: '+91',
			});
		}
		else if(response?.data?.status === 'error'){
			alert(response?.data?.message);
		}
		}
		catch(error) {
			console.error(error);
		}
	}

	const validatePhoneNumber = (phoneNumber) => {
		const regex = /^\+\d{1,3}\s\d{5}\s\d{5}$/;
		const regex2 = /^\+\d{1,3}\d{5}\d{5}$/;
		const regex3 = /^\+\d{1,3}\s\d{4}\s\d{4}$/;
		const regex4 = /^\+\d{1,3}\d{4}\d{4}$/;
		return regex.test(phoneNumber) || regex2.test(phoneNumber.replace(/\s/g, '')) || regex3.test(phoneNumber) || regex4.test(phoneNumber);
	};

	const validateForm = () => {
		let isValid = true;
		const newErrors = {
			phoneNumber: '',
			parentNumber: '',
		};

		if (!validatePhoneNumber(formData.phoneNumber)) {
			newErrors.phoneNumber = 'Invalid phone number';
			isValid = false;
		}

		if (!validatePhoneNumber(formData.parentNumber)) {
			newErrors.parentNumber = 'Invalid parent number';
			isValid = false;
		}

		setErrors(newErrors);
		return isValid;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
	
		if (validateForm()) {
			let murl = id ? `${config.apiUrl}/student/update?id=${id}` : `${config.apiUrl}/student/create`;
			try {
				
				const response = await apiService.post(murl, {...formData}, {
					headers: {
						'Content-Type': 'application/json',
					},
				});
				
				alert('student has been updated!');
				if(!id){
					navigate(`/dashboard/students/add?id=${response?.data?._id}`);
				}
				
			} catch (error) {
				alert(error?.response?.data?.error);
				console.error(error);
			}
		}
	};

	const handleAdmissionTabChange = (e, value) => {
		setAdmissionTabValue(value);
	}

	const handleCategoryTabChange = (e, value) => {
		setCategoryTabValue(value);
	}

	const allSubjectSelectChange = (e, index, itemIndex, paperList) => {
		const { checked } = e.target;
		const prevData = [...admission];

		const category = { ...prevData[index].category[itemIndex] };

		const selectedPapers = paperList;
		const papers = selectedPapers?.map(paper => paper._id);
		
		if(checked) {
			category.papers = papers;
		}
		else {
			category.papers = [];
		}
		
		prevData[index].category[itemIndex] = category;

		setAdmission(prevData);
	}

	const allSubjectSelectedCheck = (index, itemIndex, paperList) => {
		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };

		const selectedPapers = paperList;
		
		const papers = selectedPapers?.map(paper => paper._id);

		return papers?.length === category?.papers?.length;
	}

	const selectedSubjectChange = (e, paperId, index, itemIndex) => {
		const { checked } = e.target;
		const prevData = [...admission];

		const category = { ...prevData[index].category[itemIndex] };
		
		if(checked) {
			category.papers.push(paperId);
		}
		else {
			const paper = category?.papers?.filter(paper => paper !== paperId);
			category.papers = paper;
		}
		
		prevData[index].category[itemIndex] = category;

		setAdmission(prevData);
	}

	const checkAnyFeePaid = (item) => {
		let received = false;
		if(item?.ots?.amount > 0 && item?.ots?.received) {
			received = true;
		}

		if(item?.feeSplit?.length && item?.feeSplit.find(split => split.received)) {
			received = true;
		}

		return !received;
	}

	const handleChangeEmiScheme = (scheme, index, itemIndex) => {
		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };

		category.emiScheme = scheme?._id;
		category.selectedEmiScheme = scheme;

		prevData[index].category[itemIndex] = category;

		setAdmission(prevData);
	}

	const handleDownPaymentDateChange = (date, index, itemIndex) => {
		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };

		category.downPaymentDate = date;
		prevData[index].category[itemIndex] = category;
		
		setAdmission(prevData);
	}

	const hybridFeeChange = (e, index, itemIndex) => {
		const { value } = e.target;
		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };
		
		let ots = {
			amount: value,
			received: false,
			paidDate: null,
			dueDate: null,
			paymentMethod: '',
		}

		category.ots = ots;
		category.totalFee = value;
		prevData[index].category[itemIndex] = category;

		setAdmission(prevData);
	}

	const calculatePaidFee = (item) => {

		const ots = item?.ots.received && item?.ots.amount > 0 ? item?.ots.amount : 0;
		const totalSum = item?.feeSplit?.reduce((sum, payment) => {
			const value = parseFloat(payment.value)
			if(!isNaN(value) && payment.received){
				return sum + value;
			} 
			return sum;
		}, 0);

		return totalSum + ots;
	}

	const calculateBalanceFee = (item) => {

		const ots = !item?.ots.received && item?.ots.amount > 0 ? item?.ots.amount : 0;
		const totalSum = item?.feeSplit?.reduce((sum, payment) => {
			const value = parseFloat(payment.value)
			if(!isNaN(value) && !payment.received){
				return sum + value;
			} 
			return sum;
		}, 0);

		return totalSum + ots;
	}

  	return (
		<div>
			<h1 style={{ cursor: 'pointer', width: 'fit-content' }} onClick={() => navigate(-1)}>
				<BackButton />
				{`Registration`}
			</h1>
			<form onSubmit={handleSubmit}>
				<Typography variant="h4" gutterBottom>
					Edit Student
				</Typography>
				<Accordion defaultExpanded={true} sx={{ marginBottom: 2 }}>
					<AccordionSummary
						expandIcon={<ArrowDownwardIcon />}
						aria-controls="panel1-content"
						id="panel1-header"
					>
						<Typography>User Details</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<TextField
							label="First Name"
							name="firstName"
							value={formData.firstName}
							onChange={handleChange}
							fullWidth
							required
							margin="normal"
							disabled={false}
							size='small'
						/>
						<TextField
							label="Last Name"
							name="lastName"
							value={formData.lastName}
							onChange={handleChange}
							fullWidth
							required
							margin="normal"
							size='small'
						/>
						<LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
							<FormControl className='gap-2 mb-8' fullWidth style={{ marginTop: 10 }}>
							<MobileDatePicker
								name='joiningDate'
								label="Joining Date"
								value={formData.joiningDate ? dayjs(formData.joiningDate) : null}
								onChange={(date) => handleJoiningDateChange(date)}
								sx={{ marginTop: 1 }}
								format="DD/MM/YYYY"
							/>
							</FormControl>
						</LocalizationProvider>
						<MuiTelInput 
							label="Phone Number"
							name="phoneNumber"
							value={formData.phoneNumber}
							onChange={handlePhoneChange}
							fullWidth
							required
							error={Boolean(errors.phoneNumber)}
							helperText={errors.phoneNumber}
							margin="normal"
							size='small'
						/>
						<MuiTelInput 
							label="Whatsapp Number"
							name="whatsappNumber"
							value={formData.whatsappNumber}
							onChange={handleWhatsappNumberChange}
							fullWidth
							required
							error={Boolean(errors.whatsappNumber)}
							helperText={errors.whatsappNumber}
							margin="normal"
							size='small'
						/>
						<MuiTelInput 
							label="Parent Number"
							name="parentNumber"
							value={formData.parentNumber}
							onChange={handleParentPhoneChange}
							fullWidth
							required
							error={Boolean(errors.parentNumber)}
							helperText={errors.parentNumber}
							margin="normal"
							size='small'
						/>
						<TextField
							label="Email"
							name="email"
							value={formData.email}
							onChange={handleChange}
							fullWidth
							required
							type="email"
							margin="normal"
							size='small'
						/>
						<FormControl fullWidth style={{ marginTop: '20px'}} size='small'>
							<InputLabel id="Gender">Gender</InputLabel>
							<Select
								label="Gender"
								name="gender"
								value={formData.gender}
								onChange={handleChange}
								fullWidth
								margin="normal"
								size='small'
							>
								<MenuItem value="male">Male</MenuItem>
								<MenuItem value="female">Female</MenuItem>
								<MenuItem value="other">Other</MenuItem>
							</Select>
						</FormControl>
						<TextField
							label="Address"
							name="address"
							value={formData.address}
							onChange={handleChange}
							fullWidth
							margin="normal"
							size='small'
						/>
						<TextField
							label="Country"
							name="country"
							value={formData.country}
							onChange={handleChange}
							fullWidth
							margin="normal"
							size='small'
						/>
						<TextField
							label="State"
							name="state"
							value={formData.state}
							onChange={handleChange}
							fullWidth
							margin="normal"
							size='small'
						/>
						<TextField
							label="City"
							name="city"
							value={formData.city}
							onChange={handleChange}
							fullWidth
							margin="normal"
							size='small'
						/>
						<TextField
							label="Pin code"
							name="pinCode"
							value={formData.pinCode}
							onChange={handleChange}
							fullWidth
							margin="normal"
							size='small'
						/>

						<Autocomplete
							disablePortal
							id="combo-box-qualification"
							name="qualification"
							size='small'
							options={qualifications}
							style={{ marginTop: 20 }}
							getOptionLabel={(option) => option}
							value={qualifications.find((option) => option === formData.qualification) || null}
							onChange={(e, value) => handleChangeQualification(e, value)}
							renderInput={(params) => <TextField {...params} size='small' label="Qualification" />}
						/>

						<Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
							Update Student
						</Button>
					</AccordionDetails>
				</Accordion>
			</form>
			{id ? 
				<>
					<TabContext value={admissionTabValue}>
						<Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '50px' }}>
							<TabList onChange={handleAdmissionTabChange} aria-label="admissions tab" variant="scrollable">
								{admission.map((row, index) => (
									<Tab label={`Admission ${index+1}`} sx={row?.status === 'Drop' ? {textDecoration: 'line-through'} : {}} value={`admission${index}`} key={index} />
								))}
							</TabList>
						</Box>
						{admission.map((row, index) => (
							<React.Fragment key={index}>
								<TabPanel value={`admission${index}`}>
									<Grid container spacing={2} alignItems="center">
										<Grid item xs={4}>
											Created At: {row.createdAt ? new Date(row.createdAt).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }) : '-'}
										</Grid>
										<Grid item xs={8}></Grid>
									</Grid>
									<Autocomplete
										id="Course-autocomplete"
										options={courses}
										getOptionLabel={(option) => option.name}
										value={row.selectedCourse}
										renderInput={(params) => <TextField {...params} size='small' label={'Course *'} />}
										sx={{ marginTop: '15px' }}
										required
										size='small'
									/>
									<FormControl fullWidth style={{ marginTop: '10px'}}>
										<TextField
											label="Status Type"
											select
											value={row.statusType}
											fullWidth
											name="statusType"
											margin="normal"
											required
											size='small'
										>
											{statusType?.map((type, statusTypeIndex) => (
												<MenuItem key={statusTypeIndex} value={type}>{type}</MenuItem>
											))}
										</TextField>
									</FormControl>
									{row?.category?.length ?
										<>
											<TabContext value={categoryTabValue}>
												<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
													<TabList onChange={handleCategoryTabChange} aria-label="Category tab" variant="scrollable">
														{row?.category?.map((item, itemIndex) => (
															<Tab label={item?.name} sx={item?.status === 'Drop' ? {textDecoration: 'line-through'} : {}} value={`category${index}${itemIndex}`} key={`${index}${itemIndex}`} />
														))}
													</TabList>
												</Box>
												{row?.category?.map((item, itemIndex) => (
													<TabPanel value={`category${index}${itemIndex}`} key={`${index}${itemIndex}`}>
														<Grid container spacing={2} alignItems="center">
															<Grid item xs={12}>
																Created At: {item?.createdAt ? new Date(item?.createdAt).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }) : '-'}
															</Grid>
														</Grid>
														
														<Accordion defaultExpanded={true} sx={{ marginBottom: 2, marginTop: 2 }}>
															<AccordionSummary
																expandIcon={<ArrowDownwardIcon />}
																aria-controls="panel1-content"
																id="panel1-header"
															>
																<Typography>Selected Subjects</Typography>
															</AccordionSummary>
															<AccordionDetails>
																<TableContainer component={Paper} style={{ marginTop: '10px', marginBottom: '10px' }}>
																	<Table>
																		<TableHead>
																			<TableRow>
																				<TableCell>
																				<FormControlLabel control={<Checkbox checked={allSubjectSelectedCheck(index, itemIndex, item?.category?.papers)} onChange={(e) => allSubjectSelectChange(e, index, itemIndex, item?.category?.papers)} />} />
																					Action
																				</TableCell>
																				<TableCell>Name</TableCell>
																				{/* <TableCell>Action</TableCell> */}
																			</TableRow>
																		</TableHead>
																		<TableBody>
																			{item?.category?.papers?.map((paper) => (
																				<TableRow key={paper._id}>
																					<TableCell>
																						<FormGroup sx={{ width: 'fit-content'}}>
																							<FormControlLabel control={<Checkbox checked={item?.papers?.includes(paper?._id)} onChange={(e) => selectedSubjectChange(e, paper._id, index, itemIndex)} />} />
																						</FormGroup>
																					</TableCell>
																					<TableCell>{paper.name}</TableCell>
																				</TableRow>
																			))}
																		</TableBody>
																	</Table>
																</TableContainer>
															</AccordionDetails>
														</Accordion>

														{item?.papersError ?
															<FormHelperText error={Boolean(item?.papersError)}>{item?.papersError}</FormHelperText>
														: ''}
										
														<FormControl fullWidth style={{ marginTop: '20px'}}>
															<TextField
																label="Status Type"
																select
																value={item?.statusType}
																fullWidth
																name="statusType"
																margin="normal"
																error={Boolean(item?.statusTypeError)}
																helperText={item?.statusTypeError}
																required
																size='small'
															>
																<MenuItem value="">Select Status Type</MenuItem>
																{statusType?.map((type, index) => (
																	<MenuItem key={index} value={type}>{type}</MenuItem>
																))}
															</TextField>
														</FormControl>

														<FormControl fullWidth style={{ marginTop: '20px'}}>
															<TextField
																label="Mode"
																select
																value={item?.mode}
																fullWidth
																name="mode"
																margin="normal"
																required
																size='small'
																error={Boolean(item?.modeError)}
																helperText={item?.modeError}
															>
																<MenuItem value="">Select Mode</MenuItem>
																<MenuItem value="Offline">Offline</MenuItem>
																<MenuItem value="Online">Online</MenuItem>
																<MenuItem value="Hybrid">Hybrid</MenuItem>
																<MenuItem value="Recordings">Recordings</MenuItem>
																<MenuItem value="Online Plus Recordings">Online Plus Recordings</MenuItem>
															</TextField>
														</FormControl>
														<Grid container spacing={2} alignItems="center">
															<Grid item xs={12}>
																<Typography variant="h6" gutterBottom style={{ marginTop: '20px'}}>
																	Total Fee: {item?.status === 'Drop' ? 0 : item?.totalFee}
																</Typography>
															</Grid>
														</Grid>
														
														{checkAnyFeePaid(item) ?
															<Grid container spacing={2} alignItems="center">
																<Grid item xs={6}>
																	<Autocomplete
																		id="emi-scheme-autocomplete"
																		options={item?.emiSchemeList}
																		name="emiScheme"
																		getOptionLabel={(option) => option?.name}
																		value={item?.selectedEmiScheme}
																		onChange={(e, scheme) => handleChangeEmiScheme(scheme, index, itemIndex)}
																		renderInput={(params) => <TextField {...params} label="EMI Scheme" size='small' />}
																		sx={{ marginTop: '15px' }}
																		size='small'
																	/>
																</Grid>
																<Grid item xs={6}>
																	<LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
																		<FormControl className='gap-2 mb-8' fullWidth size='small'>
																			<MobileDatePicker
																				label="Down Payment Date"
																				name='downPaymentDate'
																				value={dayjs(item?.downPaymentDate)}
																				onChange={(date) => handleDownPaymentDateChange(date, index, itemIndex)}
																				format="DD/MM/YYYY"
																				sx={{ marginTop: '15px' }}
																				slotProps={{
																					textField: { size: 'small' }
																				}}
																			/>
																		</FormControl>
																	</LocalizationProvider>
																</Grid>
															</Grid>
														: ''}
														<Grid container spacing={2} alignItems="center">
															<Grid item xs={3} sx={{ marginTop: '20px' }}>
																<TextField
																	label="Admission Fee"
																	margin="normal"
																	name="admissionFee"
																	value={item?.admissionFee}
																	fullWidth
																	size='small'
																/>
															</Grid>
															<Grid item xs={3} sx={{ marginTop: '26px' }}>
																<FormControl fullWidth size='small'>
																	<InputLabel id="AdmissionFeePaymentMethod">Payment Method</InputLabel>
																	<Select
																		label="Payment Method"
																		value={item?.admissionFeePaymentMethod}
																		fullWidth
																		name='admissionFeePaymentMethod'
																		size='small'
																	>
																		{paymentMethods?.map((method, index) => (
																			<MenuItem key={index} value={method}>{method}</MenuItem>
																		))}
																	</Select>
																</FormControl>
															</Grid>
															<Grid item xs={3} sx={{ marginTop: '26px'}}>
																<LocalizationProvider dateAdapter={AdapterDayjs} locale="en" >
																	<FormControl className='gap-2 mb-8' fullWidth size='small'>
																		<MobileDatePicker
																			label="Payment Date"
																			value={dayjs(item?.admissionFeePaidDate)}
																			format="DD/MM/YYYY"
																			slotProps={{
																				textField: { size: 'small' }
																			}}
																		/>
																	</FormControl>
																</LocalizationProvider>
															</Grid>
															<Grid item xs={3} sx={{ marginTop: '26px' }}>
																<FormGroup sx={{ width: 'fit-content'}}>
																	<FormControlLabel control={<Checkbox checked={item?.admissionFeeCollected} />} label="Admission fee collected" />
																</FormGroup>
															</Grid>
														</Grid>
														{item?.discount?.map((row, rowIndex) => (
															<Grid container spacing={2} alignItems="center" key={itemIndex}>
																{row?.discountType === 'Instant Discount' ?
																	<>
																		<Grid item xs={3}>
																			<TextField
																				label="Discount"
																				type='number'
																				margin="normal"
																				name="value"
																				value={row.value}
																				fullWidth
																				size='small'
																			/>
																		</Grid>
																		<Grid item xs={2} style={{ paddingTop: '0px' }}>
																			<LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
																				<FormControl className='gap-2 mb-8' fullWidth>
																					<MobileDatePicker
																						label="Request Date"
																						value={dayjs(row?.dueDate)}
																						format="DD/MM/YYYY"
																						sx={{ marginTop: '24px' }}
																						slotProps={{
																							textField: { size: 'small' }
																						}}
																					/>
																				</FormControl>
																			</LocalizationProvider>
																		</Grid>
																		<Grid item xs={2} style={{ paddingTop: '0px' }}>
																			<LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
																				<FormControl className='gap-2 mb-8' fullWidth>
																					<MobileDatePicker
																						label="Approval Date"
																						value={dayjs(row?.dueDate)}
																						format="DD/MM/YYYY"
																						sx={{ marginTop: '24px' }}
																						slotProps={{
																							textField: { size: 'small' }
																						}}
																					/>
																				</FormControl>
																			</LocalizationProvider>
																		</Grid>
																		<Grid item xs={3} sx={{ marginTop: '6px' }}>
																			<FormControl fullWidth size='small'>
																				<InputLabel id="Select Type">Select Type</InputLabel>
																				<Select
																					label="Select Type"
																					value={row.discountType}
																					fullWidth
																					name='discountType'
																					size='small'
																				>
																					<MenuItem value="Sales Offer">Sales Offer</MenuItem>
																					<MenuItem value="Scholarship">Scholarship</MenuItem>
																					<MenuItem value="Settlement">Settlement</MenuItem>
																					<MenuItem value="PY Fee Adjustment">PY Fee Adjustment</MenuItem>
																					<MenuItem value="Ref Discount">Ref Discount</MenuItem>
																					<MenuItem value="Instant Discount">Instant Discount</MenuItem>  {/* For Black amount added as discount in student calculated as paid */}
																					<MenuItem value="Other">Other</MenuItem>
																				</Select>
																			</FormControl>
																		</Grid>
																		<Grid item xs={2}>
																			<Checkbox checked={row?.received} />
																		</Grid>
																	</>
																:
																	<>
																		<Grid item xs={6}>
																			<TextField
																				label="Discount"
																				type='number'
																				margin="normal"
																				name="value"
																				value={row.value}
																				fullWidth
																				size='small'
																			/>
																		</Grid>
																		<Grid item xs={6} sx={{ marginTop: '6px' }}>
																			<FormControl fullWidth size='small'>
																				<InputLabel id="Select Type">Select Type</InputLabel>
																				<Select
																					label="Select Type"
																					value={row.discountType}
																					fullWidth
																					name='discountType'
																					size='small'
																				>
																					<MenuItem value="Sales Offer">Sales Offer</MenuItem>
																					<MenuItem value="Scholarship">Scholarship</MenuItem>
																					<MenuItem value="Settlement">Settlement</MenuItem>
																					<MenuItem value="PY Fee Adjustment">PY Fee Adjustment</MenuItem>
																					<MenuItem value="Ref Discount">Ref Discount</MenuItem>
																					<MenuItem value="Instant Discount">Instant Discount</MenuItem>
																					<MenuItem value="Other">Other</MenuItem>
																				</Select>
																			</FormControl>
																		</Grid>
																	</>
																}
															</Grid>
														))}
														<Grid container spacing={2} alignItems="center">
															<Grid item xs={3}>
																<Typography variant="h6" gutterBottom style={{ marginTop: '20px'}}>
																	Total Fee: {item?.status === 'Drop' ? 0 : item?.totalFee}
																</Typography>
															</Grid>
															<Grid item xs={3}>
																<Typography variant="h6" gutterBottom>
																	Paid Fee: {parseFloat(calculatePaidFee(item))}
																</Typography>
															</Grid>
															<Grid item xs={3}>
																<Typography variant="h6" gutterBottom>
																	Balance Fee: {parseFloat(calculateBalanceFee(item))}
																</Typography>
															</Grid>
															<Grid item xs={3}>
																<Typography variant="h6" gutterBottom>
																	Unallocated Fee: {parseFloat(item?.unallocatedFee)}
																</Typography>
															</Grid>
														</Grid>
														<div>
															{item?.mode === 'Hybrid' ? 
																<Grid container spacing={2} alignItems="center">
																	<Grid item xs={5}>
																		<TextField
																			label="Fee For Hybrid Mode"
																			type='number'
																			margin="normal"
																			value={item?.totalFee}
																			onChange={(e) => hybridFeeChange(e, index, itemIndex)}
																			fullWidth
																			size='small'
																		/>
																	</Grid>
																</Grid>
															: ''}
															{item?.ots?.amount ?
																<div>
																	<InputLabel id="Settlement"><b>One Time Settlement</b></InputLabel>
																	<Grid container spacing={2} alignItems="center">
																		<Grid item xs={12}>
																			<TextField
																				label="Amount"
																				margin="normal"
																				value={item?.ots?.amount}
																				fullWidth
																				size='small'
																			/>
																		</Grid>
																	</Grid>
																</div>
															: ''}
															{item?.feeSplit && item?.feeSplit?.map((field, splitIndex) => (
																<div key={splitIndex}>
																	<Grid container spacing={2} alignItems="center">
																		<Grid item xs={12}>
																			<TextField
																				label="Amount"
																				margin="normal"
																				value={field.value}
																				fullWidth
																				size='small'
																			/>
																		</Grid>
																	</Grid>
																</div>
															))}
														</div>
													</TabPanel>
												))}
											</TabContext>
										</>
									: ''}
								</TabPanel>
							</React.Fragment>
						))}
					</TabContext>
				</>
				: ''
			}
		</div>
	);
};

export default EditStudentApplication;