import React, { useEffect, useState } from 'react';
import { Typography, Button, TextField, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import apiService from '../../../utlis/apiService';
import { useUser } from '../../../context/userContext';

const MyTable = () => {
    const { permissions } = useUser();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false); // Track loading state
    const [totalResults, setTotalResults] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 50,
        page: 0,
    });
    const pageOption = [10, 25, 50, 100];
    
    const navigate = useNavigate();

    useEffect(() => {
        getData();
    }, [searchTerm, paginationModel]);

    const getData = async () => {
        setLoading(true); // Set loading to true when fetching data
        try {
            const response = await apiService.get('/mentor/list', {
                params: {
                    searchTerm: searchTerm,
                    page: paginationModel.page,
                    pageSize: paginationModel.pageSize,
                },
            });

            setData(response?.data?.mentor);
            setTotalResults(response?.data?.totalResults);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false); // Set loading to false when data fetching is complete
        }
    };

    const deleteItem = async (id) => {
        const shouldDelete = window.confirm('Are you sure you want to delete this item?');
        if (shouldDelete) {
            try {
                await apiService.post('/mentor/delete', null, {
                    params: {
                        id: id,
                    },
                });
                getData();
            } catch (error) {
                console.error(error);
            }
        }
    };

    const createPress = () => {
        navigate('/dashboard/mentor/add');
    };

    return (
        <div>
            <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                <Typography variant="h4" gutterBottom>
                    MENTORS
                </Typography>
                {permissions.includes('createMentor') && (
                    <Button variant="contained" color="primary" style={{ marginBottom: '16px' }} onClick={createPress}>
                        + Create
                    </Button>
                )}
            </div>
            <TextField
                label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                fullWidth
                margin="normal"
                style={{ marginBottom: 10 }}
                size="small"
            />
            {loading ? ( // Render loader if loading is true
                <CircularProgress style={{ margin: '20px auto', display: 'block' }} />
            ) : (
                <div style={{ height: '70vh', width: '100%' }}>
                    <DataGridPro
                        rows={data}
                        getRowId={(row) => row._id}
                        columns={[
                            { field: 'name', headerName: 'Name', width: 200 },
                            { field: 'phoneNumber', headerName: 'Phone Number', width: 200 },
                            // { field: 'status', headerName: 'Status', width: 150 },
                            {
                                field: 'actions',
                                headerName: 'Action',
                                width: 150,
                                renderCell: (params) => (
                                    <>
                                        {permissions.includes('updateMentor') && (
                                            <Button variant="outlined" onClick={() => navigate(`/dashboard/mentor/add?id=${params.row._id}`)}>
                                                Edit
                                            </Button>
                                        )}
                                        {permissions.includes('deleteMentor') && (
                                            <Button variant="outlined" color="error" style={{ marginLeft: 5 }} onClick={() => deleteItem(params.row._id)}>
                                                Delete
                                            </Button>
                                        )}
                                    </>
                                ),
                            },
                        ]}
                        pagination
                        paginationModel={paginationModel}
                        rowCount={totalResults}
                        paginationMode="server"
                        components={{ Toolbar: GridToolbar }}
                        onPaginationModelChange={setPaginationModel}
                        pageSizeOptions={pageOption} 
                    />
                </div>
            )}
        </div>
    );
};

export default MyTable;
