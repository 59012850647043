import { Box, Button, Card, List, ListItem, ListItemText, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import apiService from "../../../utlis/apiService";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { generateDate } from "../../../utlis/commonFunction";
import ScheduleListView from "./scheduleListView";
import ScheduleClassRoomView from "./scheduleClassRoomView";
import ScheduleFacultyView from "./scheduleFacultyView";
import ScheduleBatchView from "./scheduleBatchView";

const GeneratedScheduleList = (props) => {
    const { academicYear, session, batchData, classData, isGenerate } = props;
    const [scheduleData, setScheduleData] = useState([]);
    const [scheduleTabValue, setScheduleTabValue] = useState('scheduleList');
    const [calendarData, setCalendarData] = useState([]);
    const [classSchedule, setClassSchedule] = useState([]);
    const [facultySchedule, setFacultySchedule] = useState([]);
    const [batchSchedule, setBatchSchedule] = useState([]);
    const [allBatchesList, setAllBatchesList] = useState([]);
    const [classDataList, setClassDataList] = useState([]);
    const [allScheduleList, setAllScheduleList] = useState([]);

    useEffect(() => {
        if(isGenerate) {
            getData();
        }
        else {
            getGeneratedData();
        }
    }, [batchData, classData]);

    const reGenerate = () => {
        if(isGenerate) {
            getData();
        }
    }

    const getData = async () => {
        try {
            const data = {
                academicYear: academicYear,
                session: session,
                batchId: batchData.filter(batch => batch.isAvailable),
                classId: classData,
            }

            const response = await apiService.post('/schedule/auto/generate', data);
            if(response?.data?.status === 'success') {
                setScheduleData(response?.data?.schedule);
                refactorGeneratedData(response?.data?.schedule);
            }

        } catch (error) {
            console.error('errors:' + error);
        }
    }

    const getGeneratedData = async () => {
        const response = await apiService.get('/schedule/generate-schedule/details', {
            params: {
              academicYear: academicYear,
              session: session,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if(response?.data?.status === 'success') {
            setScheduleData(response?.data?.schedule);
            refactorGeneratedData(response?.data?.schedule);
        }
    }

    const refactorGeneratedData = (schedules) => {
        let dateList = [];
        let allBatches = [];
        let allSchedules = [];

        schedules.map(list => {
            const dates = list.schedule.map(schedule => {
                allSchedules = [...allSchedules, schedule];
                return schedule.date
            });
            
            allBatches = [...allBatches, list.batchId];
            let classRooms = [];

            list.schedule.map(schedule => {
                if(schedule.classRoomName) {
                    const classRoom = {
                        classRoomName: schedule.classRoomName,
                        classRoom: schedule.classRoom,
                    }

                    classRooms = [...classRooms, classRoom];
                }
            });

            dateList = [...dateList, ...dates];
        });

        const removedNullDate = dateList.filter(date => date !== null);

        const filteredDate = [...new Set(removedNullDate)].sort((a, b) => new Date(a) - new Date(b));
        
        const startDate = filteredDate[0];
        const endDate = filteredDate[filteredDate.length - 1];
        const generatedDate = generateDate(startDate, endDate);

        const classList = [];
        const facultyList = [];
        const batchList = [];

        schedules.forEach(scheduleEntry => {
            const existingBatch = batchList.find(c => c.batchName === scheduleEntry.name);
            scheduleEntry.schedule.forEach(item => {
                
                if(item.date) {
                    const existingClassRoom = classList.find(c => c.classRoom === item.classRoomName);
                    const existingFaculty = facultyList.find(c => c.faculty === item.faculty);

                    if (existingClassRoom) {
                        existingClassRoom.schedule.push({
                            batch: item.batch,
                            batchId: item.batchId,
                            batchName: scheduleEntry.name,
                            faculty: item.faculty,
                            date: item.date,
                            start: item.start,
                            end: item.end,
                            status: item.status,
                            classRoom: item.classRoom,
                            classRoomName: item.classRoomName,
                            classBy: item.classBy,
                            type: item.type,
                        });
                    } 
                    else {
                        classList.push({
                            classRoom: item.classRoomName,
                            classRoomId: item.classRoom,
                            batchId: item.batchId,
                            schedule: [{
                                batch: item.batch,
                                batchId: item.batchId,
                                batchName: scheduleEntry.name,
                                faculty: item.faculty,
                                date: item.date,
                                start: item.start,
                                end: item.end,
                                status: item.status,
                                classRoom: item.classRoom,
                                classRoomName: item.classRoomName,
                                classBy: item.classBy,
                                type: item.type,
                            }]
                        });
                    }

                    if (existingFaculty) {
                        existingFaculty.schedule.push({
                            batch: item.batch,
                            batchId: item.batchId,
                            batchName: scheduleEntry.name,
                            faculty: item.faculty,
                            date: item.date,
                            start: item.start,
                            end: item.end,
                            status: item.status,
                            classRoom: item.classRoom,
                            classRoomName: item.classRoomName,
                            classBy: item.classBy,
                            type: item.type,
                        });
                    } 
                    else {
                        facultyList.push({
                            faculty: item.faculty,
                            availablility: scheduleEntry.lackOfAvailability,
                            schedule: [{
                                batch: item.batch,
                                batchId: item.batchId,
                                batchName: scheduleEntry.name,
                                faculty: item.faculty,
                                date: item.date,
                                start: item.start,
                                end: item.end,
                                status: item.status,
                                classRoom: item.classRoom,
                                classRoomName: item.classRoomName,
                                classBy: item.classBy,
                                type: item.type,
                            }]
                        });
                    }
                }
            });

            if (existingBatch) {
                existingBatch.push({
                    batchName: scheduleEntry.name,
                    batchId: scheduleEntry.batchId,
                });
            } 
            else {
                if(scheduleEntry.schedule.filter(schedule => schedule.date).length) {
                    batchList.push({
                        batchName: scheduleEntry.name,
                        batchId: scheduleEntry.batchId,
                        availablility: scheduleEntry.lackOfAvailability,
                        schedule: scheduleEntry.schedule
                    });
                }
            }
        });

        setCalendarData(generatedDate);
        setClassSchedule(classList);
        setFacultySchedule(facultyList);
        setBatchSchedule(batchList);
        setAllBatchesList([...new Set(allBatches)]);
        setAllScheduleList(allSchedules.filter(schedule => schedule.type === 'Schedule'));
    }

    const addUpdateSchedule = (newSchedule) => {
        const newList = scheduleData.map(schedule => {
            const scheduleItem = schedule;

            if(schedule.batchId === newSchedule.batchId) {
                const schedules = [...schedule.schedule, newSchedule];
                scheduleItem.schedule = schedules;
            }

            return scheduleItem;
        });
        
        setScheduleData(newList);
        refactorGeneratedData(newList);
    }

    const removeUpdateSchedule = (id, date, selectedBatch) => {
        const newList = scheduleData.map(schedule => {
            const scheduleItem = schedule;
            
            if(schedule.batchId === selectedBatch) {
                if(id && schedule._id === id) {
                    const schedules = schedule.schedule.filter(schedule => schedule._id !== id);
                    scheduleItem.schedule = schedules;
                }
                else {
                    const schedules = schedule.schedule.filter(schedule => new Date(schedule.date).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }) !== new Date(date).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }));
                    scheduleItem.schedule = schedules;
                }
            }

            return scheduleItem;
        });
        
        setScheduleData(newList);
        refactorGeneratedData(newList);
    }

    useEffect(() => {
        getClass();
    }, [allBatchesList]);

    const getClass = async () => {
        try {
          // setLoading(true);
    
          const response = await apiService.post('/schedule/get/classBy/batch', { batch: allBatchesList });
    
          if(response?.data?.status === 'success') {
            setClassDataList(response?.data?.class);
          }
        } catch (error) {
          console.error(error);
        } finally {
            // setLoading(false);
        }
    }

    const performUpdate = async (scheduleUpdateList, year, session) => {
        try {
            // setLoading(true);
      
            const response = await apiService.post('/schedule/draft-schedule/update', { 
                updateList: scheduleUpdateList,
                session: session,
                year: year,
            });
      
            if(response?.data?.status === 'success') {
                setScheduleData(response?.data?.schedule);
                refactorGeneratedData(response?.data?.schedule);
            }
          } catch (error) {
            console.error(error);
          } finally {
              // setLoading(false);
          }
    }

    const handleScheduleTabChange = (e, value) => {
		setScheduleTabValue(value);
	}

    return (
        <>
            <TabContext value={scheduleTabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '50px' }}>
                    <TabList onChange={handleScheduleTabChange} aria-label="Schedule tab">
                        <Tab label="Schedule List" value="scheduleList" />
                        <Tab label="Class Room View" value="classRoomView" />
                        <Tab label="Faculty View" value="facultyView" />
                        <Tab label="Batch View" value="batchView" />
                    </TabList>
                </Box>
                
                <TabPanel value="scheduleList">
                    {scheduleTabValue === 'scheduleList' &&
                        <ScheduleListView scheduleData={scheduleData} />
                    }
                </TabPanel>
                <TabPanel value="classRoomView">
                    {scheduleTabValue === 'classRoomView' &&
                        <ScheduleClassRoomView classSchedule={classSchedule} calendarData={calendarData} />
                    }
                </TabPanel>
                <TabPanel value="facultyView">
                    {scheduleTabValue === 'facultyView' &&
                        <ScheduleFacultyView facultySchedule={facultySchedule} calendarData={calendarData} />
                    }
                </TabPanel>
                <TabPanel value="batchView">
                    {scheduleTabValue === 'batchView' &&
                        <ScheduleBatchView batchSchedule={batchSchedule} calendarData={calendarData} classDataList={classDataList} allScheduleList={allScheduleList} addUpdateSchedule={addUpdateSchedule} removeUpdateSchedule={removeUpdateSchedule} performUpdate={performUpdate} />
                    }
                </TabPanel>
            </TabContext>

            {isGenerate && 
                <Button type="button" onClick={reGenerate} variant="contained" color="primary" style={{ marginTop: '20px' }}>
                    Re Generate
                </Button>
            }

            <Button type="button" onClick={reGenerate} variant="contained" color="primary" style={{ marginTop: '20px', marginLeft: '10px' }}>
                Submit
            </Button>
        </>
    )
}

export default GeneratedScheduleList;
