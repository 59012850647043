import { Box, Button, Container, Typography } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { Link, useLocation } from "react-router-dom";
import { useMemo } from "react";

const styles = {
    success: {
        boxShadow: '0 15px 25px #00000019',
        padding: '45px',
        textAlign: 'center',
        margin: '40px auto',
        borderBottom: 'solid 4px #28a745',
    },
    successIcon: {
        fontSize: '55px',
        color: '#28a745',
    },
    successHeading: {
        marginBottom: '12px',
        marginTop: '10px',
    },
    successSubHeading: {
        marginBottom: '0px',
        fontSize: {
            md: '18px',
            xs: '12px',
        },
        color: '#495057',
        fontWeight: 500,
    },
    backIcon: {
        fontSize: '30px',
        color: '#28a745',
    },
}

const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

const PaymentSuccess = () => {
    let query = useQuery();
    let orderId = query.get('order');
    let amount = query.get('amount');

    return (
        <Container maxWidth={'100%'} sx={{ paddingLeft: 0 }} style={{ padding: 0 }}>
            <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Box sx={{ alignSelf: 'center' }}>
                    <Box sx={styles.success}>
                        <CheckCircleIcon sx={styles.successIcon} />
                        <Typography variant="h4" sx={styles.successHeading}> Your payment was successful </Typography>
                        <Typography sx={styles.successSubHeading}> Thank you for your payment. </Typography> 
                        {orderId ? <Typography sx={styles.successSubHeading}> Order Id: {orderId} </Typography> : ''}
                        {amount ? <Typography sx={styles.successSubHeading}> Amount: {amount} </Typography> : ''}
                        <Link to="/student"><Button type="button" color="success"><KeyboardReturnIcon sx={styles.backIcon} /> Go To Home</Button></Link> 
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}

export default PaymentSuccess;