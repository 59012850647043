import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate, useLocation } from 'react-router-dom';
import apiService from '../../../utlis/apiService';
import { ArrowBackIos as BackButton } from '@mui/icons-material';
import { Checkbox, FormControl, FormControlLabel, Grid, InputLabel } from '@mui/material';
import { generateRandomString, generateYearRange, getStatusType } from '../../../utlis/commonFunction';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CreateLevelForm = () => {
  let query = useQuery();
  let id = query.get('id');
  const navigate = useNavigate();
  const [papers, setPapers] = useState([]);
  const [courses, setCourses] = useState([]);
  const [emiScheme, setEmiScheme] = useState([]);
  const [selectedPapers, setSelectedPapers] = useState([]);
  const [selectedEmiScheme, setSelectedEmiScheme] = useState([]);
  const [statusType, setStatusType] = useState([]);

  const [formData, setFormData] = useState({
    name: '',
    year: '',
    course: '',
    paperLimit: '',
    statusType: '',
    availableForResit: false,
    notAvailableForResit: false,
    fee: [
      {
        mode: '',
        value: '',
        revisitCount: '',
        admissionFee: '',
        selectedEmiScheme: []
      },
    ],
  });

  const years = generateYearRange();

  useEffect(() => {
    const fetchLevelData = async () => {
      try {
        if (id) {
          const response = await apiService.get('/level/get', {
            params: {
              id: id,
            },
          });
          const data = response?.data;
          const feeData = data.fee.map(fee => {
            const emiSchemes = fee.emiSchemes.map(emi => emi._id);
            const selectedEmiScheme = fee.emiSchemes;
            return {
              ...fee,
              emiSchemes: emiSchemes,
              selectedEmiScheme: selectedEmiScheme
            }
          });
          data.fee = feeData;
          
          setFormData(data);
          setSelectedPapers(response?.data?.papers);
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchLevelData();
  }, [id]);

  useEffect(() => {
    getCourseData();
    getEmiSchemeData();
    fetchStatusTypeData();
  }, []);

  const fetchStatusTypeData = async () => {
    const statusTypeData = await getStatusType();
    setStatusType(statusTypeData);
  }

  const getCourseData = async () => {
    try {
      const response = await apiService.get('/course/list', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      setCourses(response?.data?.course);
    } catch (error) {
      console.error(error);
    }
  };

  const getEmiSchemeData = async () => {
    try {
      const response = await apiService.get('/emi-scheme/list', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      setEmiScheme(response?.data?.emiScheme);
    } catch (error) {
      console.error(error);
    }
  };

  const [errors, setErrors] = useState({
    phoneNumber: '',
    parentNumber: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      name: '',
      year: '',
      course: '',
      statusType: '',
      subject: '',
      paperLimit: '',
    };

    if(!formData.name){
      newErrors.name = 'Invalid name';
      isValid = false;
    }

    if(!formData.year){
      newErrors.year = 'Invalid academic year';
      isValid = false;
    }

    if(!formData.course){
      newErrors.course = 'Invalid course';
      isValid = false;
    }

    if(!formData.statusType) {
      newErrors.statusType = 'Invalid status type';
      isValid = false;
    }

    if(!formData.paperLimit) {
      newErrors.paperLimit = 'Invalid paper limit';
      isValid = false;
    }

    if(formData.paperLimit < 0) {
      newErrors.paperLimit = 'Invalid paper limit';
      isValid = false;
    }

    if(!selectedPapers.length){
      newErrors.subject = 'Invalid subject';
      isValid = false;
    }

    if(validateFee()){
      alert('Enter amount for all modes');
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const validateFee = () => {
    const hasEmptyValue = formData.fee.some(item => item.value === '');
    return hasEmptyValue;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (validateForm()) {
      const apiUrl = id ? `/level/update?id=${id}` : '/level/create';
      try {
        const response = await apiService.post(apiUrl, { ...formData, papers: selectedPapers });
        navigate(-1);
        console.log(JSON.stringify(response.data));
      } catch (error) {
        alert(error?.response?.data?.error);
        console.error(error);
      }
    }
  };

  const handleChangePaper = (newValue) => {
    setSelectedPapers(newValue);
  };

  const handleChangeEmiScheme = (index, newValue) => {
    const values = [...formData.fee];
    values[index].selectedEmiScheme = newValue;
    values[index].emiSchemes = newValue.map(scheme => scheme._id);
    setFormData({ ...formData, fee: values });
  }

  const handleChangeYear=(name, value)=>{
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  const handleAddFeeFields = async () => {
    const string = generateRandomString(4);
    try {
      const response = await checkDuplicate(string);
      if(response === true) {
        await handleAddFeeFields();
      }
      else if(response === 'error') {
      }
      else {
        setFormData({
          ...formData,
          fee: [...formData.fee, { mode: '', value: '', revisitCount: '', identifier: string }]
        });
      }
    } catch (error) {
      alert('Something went wrong. Please try again');
      console.error(error);
    }
  }

  const checkDuplicate = async (string) => {
    try {
      const response = await apiService.post('/level/check-duplicate-identifier', { string: string });
      return response?.data?.categoryExist;
    } catch (error) {
      alert('Something went wrong. Please try again');
      console.error(error);
      return 'error';
    }
  }

  const handleRemoveFeeFields = (index) => {
    if(formData.fee.length === 1){
      alert("You can't remove this field! At least one payment mode is required.");
      return;
    }
    const values = [...formData.fee];
    values.splice(index, 1);
    setFormData({ ...formData, fee: values });
  }

  const handleModeChange = (event, index) => {
    let value = event.target.value;
    const values = [...formData.fee];
    const checkExist = formData.fee.filter((fee) => fee.mode === value);
    console.log(checkExist.length);
    if(checkExist.length){
      alert('This mode has already been chosen. Please select another mode.');
      return;
    }
    values[index].mode = value;
    setFormData({ ...formData, fee: values });
  }

  const handleFeeChange = (index, event) => {
    let value = event.target.value;
    if(isNaN(value)){
      alert('Please enter a valid number');
      return;
    }

    const values = [...formData.fee];
    values[index].value = value;
    setFormData({ ...formData, fee: values });
  }

  const handleAdmissionFeeChange = (index, e) => {
    const { name, value } = e.target;
    if(isNaN(value)){
      alert('Enter valid amount');
      return;
    }
    
    const values = [...formData.fee];
    values[index][name] = value;
    setFormData({ ...formData, fee: values });
  };

  const handleRevisitCountChange = (index, event) => {
    let value = event.target.value;
    if(isNaN(value)){
      alert('Please enter a valid number');
      return;
    }

    const values = [...formData.fee];
    values[index].revisitCount = value;
    setFormData({ ...formData, fee: values });
  }

  const handleIdentifierChange = (index, event) => {
    let value = event.target.value;

    const values = [...formData.fee];
    values[index].identifier = value;
    setFormData({ ...formData, fee: values });
  }

  const handleChangeCourse = (value) => {
    // alert(value?._id)
    setFormData((prevData) => ({
      ...prevData,
      course: value?._id,
    }));
  };

  const handleResitOptionChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  }

  const getPapersByCourse=async(cid)=>{
    try {
      const response = await apiService.get(`/paper/paperbycourse?id=${cid}`);
      console.log(response.data);
      setPapers(response.data)
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
      getPapersByCourse(formData.course);
  },[formData.course]);


  return (
    <div>
      <h1 style={{ cursor: 'pointer', width: 'fit-content' }} onClick={() => navigate(-1)}>
        <BackButton />
        {`Categories`}
      </h1>
      <form onSubmit={handleSubmit}>
        <Typography variant="h4" gutterBottom>
          {id ? 'Edit' : 'Create'} Category
        </Typography>
        <TextField
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          required
          margin="normal"
          size='small'
        />
        <Autocomplete
          disablePortal
          id="academicYear-box"
          name="year"
          required
          options={years}
          style={{ marginTop: 15 }}
          getOptionLabel={(option) => option}  
          value={formData.year || null}
          onChange={(e, value) => handleChangeYear('year', value)} 
          size='small'
          renderInput={(params) => <TextField {...params} label="Academic Year" size='small' />}
        />

        <Autocomplete
          disablePortal
          id="combo-box-demo"
          name="courses"
          options={courses}
          style={{marginTop:15}}
          getOptionLabel={(option) => option.name}
          value={courses.find((option) => option._id === formData.course) || null}
          onChange={(e, value) => handleChangeCourse(value)}
          size='small'
          renderInput={(params) => <TextField {...params} error={Boolean(errors.course)} helperText={errors.course} label="Select Course" size='small' />}
        />
        <FormControl fullWidth size='small'>
          <TextField
            label="Status Type"
            select
            value={formData.statusType}
            onChange={handleChange}
            fullWidth
            name="statusType"
            margin="normal"
            error={Boolean(errors.statusType)}
            helperText={errors.statusType}
            required
            size='small'
          >
            <MenuItem value="">Select Status Type</MenuItem>
            {statusType?.map((type, index) => (
              <MenuItem key={index} value={type}>{type}</MenuItem>
            ))}
          </TextField>
        </FormControl>
        <TextField
          type="number"
          label="Paper Limit"
          name="paperLimit"
          value={formData.paperLimit}
          onChange={handleChange}
          fullWidth
          required
          margin="normal"
          error={Boolean(errors.paperLimit)}
          helperText={errors.paperLimit}
          size='small'
        />
        <Autocomplete
          multiple
          id="paper-autocomplete"
          options={papers}
          getOptionLabel={(option) => option.name}
          value={selectedPapers}
          onChange={(event, newValue) => handleChangePaper(newValue)}
          renderInput={(params) => <TextField {...params} error={Boolean(errors.subject)} helperText={errors.subject} label="Subjects" size='small' />}
          sx={{ marginTop: '15px' }}
          size='small'
        />
        
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6} sx={{ marginTop: '6px' }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6} sx={{ marginTop: '6px' }}>
                <FormControlLabel control={<Checkbox checked={formData?.availableForResit} name='availableForResit' onChange={(e) => handleResitOptionChange(e)} />} label="Available For Re-sit" />
              </Grid>
              <Grid item xs={6} sx={{ marginTop: '6px' }}>
                <FormControlLabel control={<Checkbox checked={formData?.notAvailableForResit} name='notAvailableForResit' onChange={(e) => handleResitOptionChange(e)} />} label="Not Available For Re-sit" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sx={{ marginTop: '6px' }}></Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={11} sx={{ marginTop: '6px' }}></Grid>
          <Grid item xs={1} sx={{ marginTop: '6px' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddFeeFields}
              size='small'
            >
              Add Fee
            </Button>
          </Grid>
        </Grid>
        {formData.fee && formData.fee.map((row, index) => (
          <div key={index}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={2} sx={{ marginTop: '6px' }}>
                <FormControl fullWidth size='small'>
                  <InputLabel id="Mode">Mode</InputLabel>
                  <Select
                    label="Mode"
                    value={row.mode}
                    onChange={(e) => handleModeChange(e, index)}
                    fullWidth
                    name="mode"
                    size='small'
                  >
                    <MenuItem value="Offline">Offline</MenuItem>
                    <MenuItem value="Online">Online</MenuItem>
                    <MenuItem value="Hybrid">Hybrid</MenuItem>
                    <MenuItem value="Recordings">Recordings</MenuItem>
                    <MenuItem value="Online Plus Recordings">Online Plus Recordings</MenuItem>
                    <MenuItem value="Cohort">Cohort</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Amount"
                  type='number'
                  margin="normal"
                  name="value"
                  value={row.value}
                  onChange={(e) => handleFeeChange(index, e)}
                  fullWidth
                  size='small'
                />
              </Grid>
              <Grid item xs={2}>
              <TextField
                  label="Admission Fee"
                  name="admissionFee"
                  value={row.admissionFee}
                  onChange={(e) => handleAdmissionFeeChange(index, e)}
                  fullWidth
                  required
                  margin="normal"
                  size='small'
                />
              </Grid>
              <Grid item xs={2}>
              <Autocomplete
                multiple
                id="emi-scheme-autocomplete"
                options={emiScheme}
                getOptionLabel={(option) => option?.name}
                value={row.selectedEmiScheme}
                onChange={(event, newValue) => handleChangeEmiScheme(index, newValue)}
                renderInput={(params) => <TextField {...params} label="EMI Scheme" />}
                sx={{ marginTop: '15px' }}
                size='small'
              />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Re-sit Count"
                  type='number'
                  margin="normal"
                  name="revisitCount"
                  value={row.revisitCount}
                  onChange={(e) => handleRevisitCountChange(index, e)}
                  fullWidth
                  size='small'
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                    type='text'
                    margin="normal"
                    onChange={(e) => handleIdentifierChange(index, e)}
                    value={row.identifier}
                    fullWidth
                    size='small'
                  />
              </Grid>
              <Grid item xs={1} sx={{ marginTop: '6px' }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleRemoveFeeFields(index)}
                  size='small'
                >
                  Remove
                </Button>
              </Grid>
            </Grid>
          </div>
        ))}

        <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }} size='small'>
          {id ? 'Update Category' : 'Create Category'}
        </Button>
      </form>
    </div>
  );
};

export default CreateLevelForm;
