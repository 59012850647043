import { useEffect, useState } from "react";
import { useUser } from "../../../context/userContext";
import { useNavigate } from "react-router-dom";
import { Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import apiService from "../../../utlis/apiService";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import dayjs from 'dayjs';
import 'dayjs/locale/en'; 

dayjs.extend(AdvancedFormat);
const MyTable = () => {
    const { permissions } = useUser();
    const [data, setData] = useState([]);
    const [totalResults, setTotalResults] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 50,
        page: 0,
    });
    const [filterData, setFilterData] = useState({
        dateFrom: null,
        dateTo: null,
        status: '',
        examType: '',
    });

    const pageOption = [10, 25, 50, 100];

    useEffect(() => {
        getData();
    }, [searchTerm, paginationModel, filterData]);

    const getData = async () => {
        try {
            setLoading(true); // Start loading
            const response = await apiService.get('/exam/list', {
                params: { searchTerm, page: paginationModel.page, pageSize: paginationModel.pageSize, ...filterData },
            });
            setData(response?.data?.exams);
            setTotalResults(response?.data?.totalResults);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false); // Stop loading, whether success or error
        }
    };

    const deleteItem = async (id) => {
        const shouldDelete = window.confirm('Are you sure you want to delete this item?');
        if (shouldDelete) {
            try {
                setLoading(true); // Start loading
                await apiService.post(`/exam/delete?id=${id}`);
                getData();
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false); // Stop loading, whether success or error
            }
        }
    };

    const clearLocalStorageKeysWithSubstring = (substring) => {
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key.includes(substring)) {
                localStorage.removeItem(key);
            }
        }
    };

    const handleDateChange = (name, value) => {
        setFilterData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFilterData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    const createPress = () => {
        clearLocalStorageKeysWithSubstring('FormData');
        navigate('/dashboard/exam/add');
    };

    return (
        <div>
            <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                <Typography variant="h4" gutterBottom>EXAMS</Typography>
                {permissions.includes('createExam') && (
                    <Button variant="contained" color="primary" style={{ marginBottom: '16px' }} onClick={createPress} size='small'>
                        + Create
                    </Button>
                )}
            </div>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={4}>
                    <TextField
                        label="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        fullWidth
                        margin="dense"
                        style={{ marginBottom: 10 }}
                        size='small'
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth size='small'>
                        <InputLabel id="Status">Status</InputLabel>
                        <Select
                            label="Status"
                            name="status"
                            value={filterData.status}
                            onChange={handleChange}
                            fullWidth
                            margin="dense"
                            size='small'
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value="Active">Active</MenuItem>
                            <MenuItem value="Inactive">Inactive</MenuItem>
                            <MenuItem value="Cancelled">Cancelled</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth size='small'>
                        <InputLabel id="Status">Exam Type</InputLabel>
                        <Select
                            label="Exam Type"
                            name="examType"
                            value={filterData.examType}
                            onChange={handleChange}
                            fullWidth
                            margin="dense"
                            size='small'
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value="Mock">Mock</MenuItem>
                            <MenuItem value="Main">Main</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center" sx={{ marginBottom: '20px' }}>
                <Grid item xs={6} style={{ marginTop: 10 }}>
                    <InputLabel id="DateFrom">Date From</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
                        <FormControl className='gap-2 mb-8' fullWidth>
                            <MobileDatePicker
                                value={filterData.dateFrom}
                                onChange={(date) => handleDateChange('dateFrom', date)}
                                sx={{ marginTop: 1 }}
                                format="DD/MM/YYYY"
                                slotProps={{
                                    actionBar: {
                                      actions: ['clear', 'cancel', 'accept'],
                                    },
                                    textField: { size: 'small' }
                                }}
                            />
                        </FormControl>
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={6} style={{ marginTop: 10 }}>
                    <InputLabel id="DateTo">Date To</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
                        <FormControl className='gap-2 mb-8' fullWidth>
                            <MobileDatePicker
                                value={filterData.dateTo}
                                onChange={(date) => handleDateChange('dateTo', date)}
                                sx={{ marginTop: 1 }}
                                format="DD/MM/YYYY"
                                slotProps={{
                                    actionBar: {
                                      actions: ['clear', 'cancel', 'accept'],
                                    },
                                    textField: { size: 'small' }
                                }}
                            />
                        </FormControl>
                    </LocalizationProvider>
                </Grid>
            </Grid>

            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 400 }}>
                    <CircularProgress />
                </div>
            ) : (
                <div style={{ height: '70vh', width: '100%' }}>
                    <DataGridPro
                        rows={data}
                        getRowId={(row) => row._id}
                        columns={[
                            { field: 'name', headerName: 'Name', flex: 1 },
                            { field: 'examType', headerName: 'Type', flex: 1 },
                            { field: 'subject', headerName: 'Subject', flex: 1, valueGetter: (params) => (params?.row?.subject?.name) },
                            { field: 'totalMark', headerName: 'Total Mark', flex: 1 },
                            { field: 'passMark', headerName: 'Pass Mark', flex: 1 },
                            {
                                field: 'actions',
                                headerName: 'Action',
                                width: 250,
                                renderCell: (params) => (
                                    <>
                                        {permissions.includes('readExam') && (
                                            <Button variant="outlined" onClick={() => navigate(`/dashboard/exam/view?id=${params?.row?._id}`)}>View</Button>
                                        )}
                                        {permissions.includes('updateExam') && (
                                            <Button variant="outlined" style={{ marginLeft: 5 }} onClick={() => navigate(`/dashboard/exam/add?id=${params?.row?._id}`)}>Edit</Button>
                                        )}
                                        {permissions.includes('deleteExam') && (
                                            <Button variant="outlined" color="error" style={{ marginLeft: 5 }} onClick={() => deleteItem(params?.row?._id)}>Delete</Button>
                                        )}
                                    </>
                                ),
                            },
                        ]}
                        components={{ Toolbar: GridToolbar }}
                        pagination
                        paginationModel={paginationModel}
                        rowCount={totalResults}
                        paginationMode="server"
                        onPaginationModelChange={setPaginationModel}
                        rowsPerPageOptions={pageOption}
                    />
                </div>
            )}
        </div>
    );
};

export default MyTable;