import React, { useState,useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import { useNavigate,useLocation } from "react-router-dom";
import config from '../../../config'; 
import apiService from '../../../utlis/apiService';
import {
  ArrowBackIos as BackButton,
} from '@mui/icons-material';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CreateStudentForm = () => {
  let query = useQuery();
  let id = query.get("id")
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: ''
  });

  useEffect(() => {
    const fetchMentorData = async () => {
      try {
        if (id) {
          const response = await apiService.get('/mentor/get', {
            params: {
              id: id,
            },
          });
          console.log(response.data);
          setFormData(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchMentorData();
  }, [id]);

  const [errors, setErrors] = useState({
    phoneNumber: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validatePhoneNumber = (phoneNumber) => {
    const regex = /^[0-9]{10}$/;
    return regex.test(phoneNumber);
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      phoneNumber: '',
    };

    if (!validatePhoneNumber(formData.phoneNumber)) {
      newErrors.phoneNumber = 'Invalid phone number';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (validateForm()) {
      const apiUrl = id ? `/mentor/update?id=${id}` : '/mentor/create';
  
      try {
        const response = await apiService.post(apiUrl, formData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        navigate(-1);
        console.log(JSON.stringify(response.data));
      } catch (error) {
        alert(error?.response?.data?.error);
        console.error(error);
      }
    }
  };

  return (
    <div>
      <h1 style={{cursor:'pointer'}} onClick={()=>navigate(-1)}>
      <BackButton />
        {`Mentors`}
      </h1>
    <form onSubmit={handleSubmit}>
      <Typography variant="h4" gutterBottom>
       {id ? 'Edit' : 'Create'}  Mentor
      </Typography>
      <TextField
        label="Name"
        name="name"
        value={formData.name}
        onChange={handleChange}
        fullWidth
        required
        margin="normal"
        size="small"
      />
      <TextField
        label="Phone Number"
        name="phoneNumber"
        value={formData.phoneNumber}
        onChange={handleChange}
        fullWidth
        required
        error={Boolean(errors.phoneNumber)}
        helperText={errors.phoneNumber}
        margin="normal"
        size="small"
      />


      <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
       {id ? 'Update Mentor' : 'Create Mentor' }
      </Button>
 
    </form>
         
    </div>
  );
};

export default CreateStudentForm;
