import { useState, useEffect, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import config from '../../../config';
import apiService from '../../../utlis/apiService';
import { ArrowBackIos as BackButton } from '@mui/icons-material';
import { DemoContainer,DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { 
  FormControl,
  FormLabel,
  List,
  Card,
  CardContent,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
  InputLabel
} from '@mui/material';
import AdvancedFormat from 'dayjs/plugin/advancedFormat'; // Import the advancedFormat plugin
import 'dayjs/locale/en'; 
import { useUser } from '../../../context/userContext';
import { generateYearRange, getSessions } from '../../../utlis/commonFunction';
import GeneratedScheduleList from './generatedSchedule';


const ScheduleGenerator = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    academicYear: '',
    session: '',
  });
  const [errors, setErrors] = useState({
    academicYear: '',
    session: '',
  });
  const [page, setPage] = useState(1);
  // const [loading, setLoading] = useState(false);
  const [batchData, setBatchData] = useState([]);
  const [classData, setClassData] = useState([]);
  const [scheduleList, setScheduleList] = useState([]);
  const [isGenerate, setIsGenerate] = useState(true);
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    
  };

  const years = generateYearRange();

  const sessions = getSessions();

  useEffect(() => {
    getScheduleList();
  }, []);

  const getScheduleList = async () => {
    try {
      // setLoading(true);
      const response = await apiService.get('/schedule/generate-schedule/list', {
        headers: {
            'Content-Type': 'application/json',
        },
      });

      if(response?.data?.status === 'success') {
        setScheduleList(response?.data?.schedule);
      }

    } catch (error) {
      console.error(error);
    } finally {
        // setLoading(false);
    }
  }

  const handleChangeYear = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setErrors((prevError) => ({
      ...prevError,
      [name]: '',
    }));
  }

  const nextPage = () => {
    if(page === 1) {
      if(pageValidator(page)) {
        getBatch();
        setPage(page + 1);
      }
    }
    else if(page === 2) {
      if(pageValidator(page)) {
        getClass();
        setPage(page + 1);
      }
    }
    else if(page === 3) {
      if(pageValidator(page)) {
        setPage(page + 1);
      }
    }
  }

  const getBatch = async () => {
    try {
      // setLoading(true);
      const response = await apiService.get('/schedule/get/session-academic/batch', {
        params: {
          academicYear: formData.academicYear,
          session: formData.session,
        },
        headers: {
            'Content-Type': 'application/json',
        },
      });

      const formatedBatch = response?.data?.batch?.map(batch => ({
        ...batch,
        isAvailable: true,
      }));

      setBatchData(formatedBatch);
    } catch (error) {
      console.error(error);
    } finally {
        // setLoading(false);
    }
  }

  const getClass = async () => {
    try {
      // setLoading(true);
      const filteredBatch = batchData.filter(batch => batch.isAvailable);
      const branch = filteredBatch.map(batch => batch.branch);
      const uniqueBranch = [...new Set(branch)];

      const response = await apiService.post('/schedule/get/classBy/branch', { branch: uniqueBranch });

      const formatedClass = response?.data?.class?.map(classD => ({
        ...classD,
        isAvailable: true,
      }));

      setClassData(formatedClass);
    } catch (error) {
      console.error(error);
    } finally {
        // setLoading(false);
    }
  }

  const pageValidator = (page) => {
    let isValid = true;
    if(page === 1) {
      if(!formData.academicYear) {
        isValid = false;
        setErrors((prevError) => ({
          ...prevError,
          academicYear: 'Academic year is required',
        }));
      }
      if(!formData.session) {
        isValid = false;
        setErrors((prevError) => ({
          ...prevError,
          session: 'Session year is required',
        }));
      }
    }
    else if(page === 2) {
      const availableBatch = batchData.filter(batch => batch.isAvailable === true);

      if(!availableBatch.length) {
        isValid = false;
        alert('Please select atleast one batch');
      }
    }
    else if(page === 3) {
      const availableClass = classData.filter(classD => classD.isAvailable === true);

      if(!availableClass.length) {
        isValid = false;
        alert('Please select atleast one class');
      }
    }

    return isValid;
  }

  const prevPage = () => {
    setPage(page - 1)
  }

  const ListBatches = () => {
    const handleCheckboxChange = (e, id) => {
      setBatchData((prevData) => {
        return prevData.map(batch => {
          if(batch._id === id) {
            return {
              ...batch,
              isAvailable: !batch.isAvailable,
            }
          }
          return batch;
        })
      });
    }

    return <List style={{marginTop:10,marginBottom:20}}>
      {batchData?.map((batch) => (
        <Card key={batch?._id} sx={{ marginBottom: 2,padding:0,paddingBottom:0  }} >
          <ListItem>
            <ListItemText onClick={(e) => handleCheckboxChange(e, batch?._id)} sx={{ textDecoration: 'none', cursor: 'pointer' }}
              primary={
                <Typography>{batch?.name}</Typography>
              }
            />
            <ListItemSecondaryAction>
              <Checkbox
                checked={batch?.isAvailable}
                onChange={(e) => handleCheckboxChange(e, batch?._id)}
              />
            </ListItemSecondaryAction>
          </ListItem>
        </Card>
      ))}
    </List>
  }

  const listClasses = () => {
    const handleCheckboxChange = (e, id) => {
      setClassData((prevData) => {
        return prevData.map(classD => {
          if(classD._id === id) {
            return {
              ...classD,
              isAvailable: !classD.isAvailable,
            }
          }
          return classD;
        })
      });
    }
    
    return <List style={{marginTop:10,marginBottom:20}}>
      {classData?.map((classD) => (
        <Card key={classD?._id} sx={{ marginBottom: 2,padding:0,paddingBottom:0  }} >
          <ListItem>
            <ListItemText onClick={(e) => handleCheckboxChange(e, classD?._id)} sx={{ textDecoration: 'none', cursor: 'pointer' }}
              primary={
                <Typography>{classD?.name}</Typography>
              }
            />
            <ListItemSecondaryAction>
              <Checkbox
                checked={classD?.isAvailable}
                onChange={(e) => handleCheckboxChange(e, classD?._id)}
              />
            </ListItemSecondaryAction>
          </ListItem>
        </Card>
      ))}
    </List>
  }

  const getScheduleDetails = (year, session) => {
    setIsGenerate(false);
    setPage(4);
    setFormData({
      academicYear: year,
      session: session,
    });
  }

  return (
    <div>
      <h1 style={{ cursor: 'pointer', width: 'fit-content' }} onClick={() => navigate('/dashboard')}>
        <BackButton />
        {`Dashboard`}
      </h1>

      <form onSubmit={handleSubmit}>
        <Typography variant="h4" gutterBottom>
          {page === 4 ? 'Generated Schedules' : 'Schedule Generator'}
        </Typography>

        {page === 1 ?
          <>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              name="year"
              required
              options={years}
              style={{ marginTop: 15 }}
              getOptionLabel={(option) => option}  
              value={formData.academicYear || null}
              onChange={(e, value) => handleChangeYear('academicYear', value)}
              renderInput={(params) => <TextField {...params} error={Boolean(errors.academicYear)} helperText={errors.academicYear} label="Academic Year" />}
            />
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              name="session"
              required
              options={sessions}
              style={{ marginTop: 15 }}
              getOptionLabel={(option) => option}  
              value={formData.session || null}
              onChange={(e, value) => handleChangeYear('session', value)} 
              renderInput={(params) => <TextField {...params} error={Boolean(errors.session)} helperText={errors.session} label="Session" />}
            />
          </>
          : page === 2 ? 
            ListBatches()
          : page === 3 ?
            listClasses()
          :
            <GeneratedScheduleList academicYear={formData.academicYear} session={formData.session} batchData={batchData} classData={classData} isGenerate={isGenerate} />
        }
      
        {page !== 4 ? 
          <Button disabled={page === 1} type="button" onClick={prevPage} variant="contained" color="primary" style={{ marginTop: '20px', marginRight: '10px' }}>
            Previous
          </Button>
        : ''}
        {page === 3 ? 
          <Button type="button" onClick={nextPage} variant="contained" color="primary" style={{ marginTop: '20px' }}>
            Generate
          </Button>
        : page !== 4 ?
          <Button type="button" onClick={nextPage} variant="contained" color="primary" style={{ marginTop: '20px' }}>
            Next
          </Button>
        : ''
        }
      </form>

      {page === 1 ?
        <List style={{marginTop:10, marginBottom:20}}>
          {scheduleList?.map((schedule, index) => {
            return <Card key={index} onClick={() => getScheduleDetails(schedule?._id?.year, schedule?._id?.session)} sx={{ marginBottom: 2,padding:0,paddingBottom:0  }}>
              <ListItem>
                <ListItemText sx={{ textDecoration: 'none', cursor: 'pointer' }}
                  primary={<Typography>{schedule?._id?.year} - {schedule?._id?.session}</Typography>}
                />
              </ListItem>
            </Card>
          })}
        </List>
      :''}

    </div>
  );
};

export default ScheduleGenerator;
