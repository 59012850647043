import { Box, Button, CircularProgress, Container, Grid, IconButton, ListItemIcon, Menu, MenuItem, Modal, TextField, Typography } from "@mui/material";
import { DataGridPro } from '@mui/x-data-grid-pro';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Logout from '@mui/icons-material/Logout';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import referrerApiService from "../../utlis/apiReferrer";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    maxHeight: '80%',
};

const MyDashboard = () => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [totalResults, setTotalResults] = useState(0);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 50,
        page: 0,
    });
    const [bankModal, setBankModal] = useState(0);
    const [eligibilityModal, setEligibilityModal] = useState(0);
    const [formData, setFormData] = useState({
        nameOnBank: '',
        accountNumber: '',
        reAccountNumber: '',
        ifscCode: '',
        branch: '',
    });

    const [error, setError] = useState({
        nameOnBank: '',
        accountNumber: '',
        reAccountNumber: '',
        ifscCode: '',
        branch: '',
    });
    
    const pageOption = [10, 25, 50, 100];

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        localStorage.removeItem('rtoken');
        navigate('/referrer');
        window.location.reload();
    }

    const handleBankChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        setError((prevError) => ({
            ...prevError,
            [name]: '',
        }));
    }

    const onAccountBlur = () => {
        if(formData.accountNumber && formData.reAccountNumber) {
            if(formData.accountNumber !== formData.reAccountNumber) {
                setError((prevError) => ({
                    ...prevError,
                    reAccountNumber: 'Account Number and Re Enter Account Number Miss Match',
                }));
            }
        }
    }

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const response = await referrerApiService.get('/referrer/user/referral/list', {
                // params: {
                //     page: paginationModel.page,
                //     pageSize: paginationModel.pageSize,
                // },
            });

            setFormData(response?.data?.userData);
            setData(response?.data?.referrals);
            setTotalResults(response?.data?.totalResults);
        }
        catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    const handleBankSubmit = async () => {
        try {
            const response = await referrerApiService.post('/referrer/user/update/bank-details', formData);
            alert(response?.data?.message);
            setBankModal(false);
        }
        catch (error) {
            console.error(error);
        }
    }

    const payoutRequest = async (id) => {
        try {
            const response = await referrerApiService.post('/referrer/user/payout-request', { id: id });
            getData();
            alert(response?.data?.message);
        }
        catch (error) {
            console.error(error);
        }
    }

    return (
        <Container maxWidth="md" sx={{ marginTop: 5 }}>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mb: 3 }}
                >
                    <Typography variant="h4">DASHBOARD</Typography>
                    <IconButton color="primary" onClick={handleClick}>
                        <AccountCircleIcon sx={{ fontSize: 30 }} />
                    </IconButton>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
                {/* <MenuItem onClick={() => navigate('/student/profile')}>
                    <ListItemIcon>
                        <PersonOutlineIcon fontSize="small" />
                    </ListItemIcon>
                    Profile
                </MenuItem> */}
            </Menu>
            <Grid container spacing={2} alignItems="center" sx={{ marginBottom: '20px' }}>
                <Grid item xs={8}></Grid>
                <Grid item xs={4}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <Button type="button" onClick={() => setBankModal(true)} variant="contained" color="primary" style={{ marginTop: '20px' }}>Bank Details</Button>
                        </Grid>
                        <Grid item>
                            <Button type="button" onClick={() => setEligibilityModal(true)} variant="contained" color="primary" style={{ marginTop: '20px' }}>Eligibility</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 400 }}>
                    <CircularProgress />
                </div>
            ) : (
                <div style={{ height: '70vh', width: '100%' }}>
                    <DataGridPro
                        rows={data}
                        getRowId={(row) => row._id}
                        columns={[
                            { field: 'sno', headerName: 'S.No', width: 10 },
                            { field: 'name', headerName: 'Name', flex: 1 },
                            { field: 'studentId', headerName: 'ID', flex: 1 },
                            { field: 'category', headerName: 'Category', flex: 1 },
                            { field: 'status', headerName: 'Status', flex: 1 },
                            { field: 'amount', headerName: 'Amount', flex: 1 },
                            { field: 'action', headerName: 'Action', renderCell: (params) => (
                                <>
                                    {params?.row?.status === 'Due' && (
                                        <Button variant="outlined" onClick={() => payoutRequest(params?.row?._id)}>Request Payout</Button>
                                    )}
                                </>
                            ), flex: 2 },
                        ]}
                        pagination
                        paginationModel={paginationModel}
                        rowCount={totalResults}
                        paginationMode="server"
                        onPaginationModelChange={setPaginationModel}
                        rowsPerPageOptions={pageOption}
                    />
                </div>
            )}

            <Modal
                open={bankModal}
                onClose={() => setBankModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div>
                        <form>
                            <Typography variant="h4" gutterBottom>Bank Details</Typography>
                            <TextField
                                label="Name on Bank"
                                name="nameOnBank"
                                value={formData.nameOnBank}
                                onChange={handleBankChange}
                                fullWidth
                                required
                                margin="normal"
                                size='small'
                                error={Boolean(error.nameOnBank)}
                                helperText={error.nameOnBank}
                            />
                            <TextField
                                label="Account Number"
                                name="accountNumber"
                                value={formData.accountNumber}
                                onChange={handleBankChange}
                                onBlur={onAccountBlur}
                                fullWidth
                                required
                                margin="normal"
                                size='small'
                                error={Boolean(error.accountNumber)}
                                helperText={error.accountNumber}
                            />
                            <TextField
                                label="Re Enter Account Number"
                                name="reAccountNumber"
                                value={formData.reAccountNumber}
                                onChange={handleBankChange}
                                onBlur={onAccountBlur}
                                fullWidth
                                required
                                margin="normal"
                                size='small'
                                error={Boolean(error.reAccountNumber)}
                                helperText={error.reAccountNumber}
                            />
                            <TextField
                                label="IFSC Code"
                                name="ifscCode"
                                value={formData.ifscCode}
                                onChange={handleBankChange}
                                fullWidth
                                required
                                margin="normal"
                                size='small'
                                error={Boolean(error.ifscCode)}
                                helperText={error.ifscCode}
                            />
                            <TextField
                                label="Branch Name"
                                name="branch"
                                value={formData.branch}
                                onChange={handleBankChange}
                                fullWidth
                                required
                                margin="normal"
                                size='small'
                                error={Boolean(error.branch)}
                                helperText={error.branch}
                            />
                            <div style={{ textAlign: 'center' }}>
                                <Button type="button" variant="outlined" sx={{ marginRight: '10px' }} onClick={() => setBankModal(false)}>Cancel</Button>
                                <Button type="button" onClick={handleBankSubmit} variant="contained">Save</Button>
                            </div>
                        </form>
                    </div>
                </Box>
            </Modal>

            <Modal
                open={eligibilityModal}
                onClose={() => setEligibilityModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div>
                        <Typography variant="h4" gutterBottom>Eligibility Criteria</Typography>
                            
                    </div>
                </Box>
            </Modal>
        </Container>
    );
}

export default MyDashboard;