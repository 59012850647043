import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Typography, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import apiService from '../../../utlis/apiService';
import { useUser } from '../../../context/userContext';

const MyTable = () => {
    const { permissions } = useUser();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalResults, setTotalResults] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 50,
        page: 0,
    });
    const pageOption = [10, 25, 50, 100];

    useEffect(() => {
        getData();
    }, [searchTerm, paginationModel]);

    let navigate = useNavigate();

    const getData = async () => {
        setLoading(true); // Set loading to true when fetching data
        try {
            const response = await apiService.get('/faculty/list', {
                params: {
                    searchTerm: searchTerm,
                    page: paginationModel.page,
                    pageSize: paginationModel.pageSize,
                },
            });

            setData(response?.data?.faculty);
            setTotalResults(response?.data?.totalResults);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false); // Set loading to false when data fetching is complete
        }
    };

    const deleteItem = (id) => {
        const shouldDelete = window.confirm('Are you sure you want to delete this item?');
        if (shouldDelete) {
            deleteServer(id);
        }
    };

    const deleteServer = async (mid) => {
        try {
            const response = await apiService.post('/faculty/delete', null, {
                params: {
                    id: mid,
                },
            });
            getData();
        } catch (error) {
            console.error(error);
        }
    };

    const createPress = () => {
        navigate('/dashboard/faculty/add');
    };

    return (
        <div>
            <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                <Typography variant="h4" gutterBottom>
                    FACULTIES
                </Typography>
                {permissions.includes('createFaculty') && (
                    <Button variant="contained" color="primary" style={{ marginBottom: '16px' }} onClick={createPress}>
                        + Create
                    </Button>
                )}
            </div>
            <TextField
                label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                fullWidth
                margin="dense"
                style={{ marginBottom: 10 }}
                size='small'
            />
            {loading ? ( 
                <CircularProgress style={{ margin: '20px auto', display: 'block' }} />
            ) : (
                <div style={{ height: '70vh', width: '100%' }}>
                    <DataGridPro
                        rows={data}
                        getRowId={(row) => row._id}
                        columns={[
                            { field: 'name', headerName: 'Name', flex: 1 },
                            { field: 'phoneNumber', headerName: 'Phone', flex: 1 },
                            { field: 'email', headerName: 'Email', flex: 1 },
                            {
                                field: 'actions',
                                headerName: 'Action',
                                flex: 1,
                                renderCell: (params) => (
                                    <>
                                        {permissions.includes('updateFaculty') && (
                                            <Button variant="outlined" onClick={() => navigate(`/dashboard/faculty/add?id=${params.row._id}`)}>Edit</Button>
                                        )}
                                        {permissions.includes('deleteFaculty') && (
                                            <Button variant="outlined" color="error" style={{ marginLeft: 5 }} onClick={() => deleteItem(params.row._id)}>Delete</Button>
                                        )}
                                    </>
                                ),
                            },
                        ]}
                        pagination
                        paginationModel={paginationModel}
                        rowCount={totalResults}
                        paginationMode="server"
                        components={{ Toolbar: GridToolbar }}
                        onPaginationModelChange={setPaginationModel}
                        rowsPerPageOptions={pageOption}
                    />
                </div>
            )}
        </div>
    );
};

export default MyTable;
