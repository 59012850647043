import React, { useEffect, useState } from 'react';
import { Typography, Button, TextField, CircularProgress, Box, Tab, Modal, Grid, FormControl, FormHelperText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DataGridPro } from '@mui/x-data-grid-pro';
import apiService from '../../../../utlis/apiService';
import { useUser } from '../../../../context/userContext';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import dayjs from 'dayjs';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    maxHeight: '80%',
};

const MyTable = () => {
    const { permissions } = useUser();
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 50,
        page: 0,
    });
    const [unpaidData, setUnpaidData] = useState([]);
    const [paidData, setPaidData] = useState([]);
    const [accessGrantedData, setAccessGrantedData] = useState([]);
    const [expiredData, setExpiredData] = useState([]);
    const [disabledData, setDisabledData] = useState([]);

    const [admissionTabValue, setAdmissionTabValueChange] = useState('unPaid');
    const [unpaidTotalResult, setUnpaidTotalResult] = useState(0);
    const [paidTotalResult, setPaidTotalResult] = useState(0);
    const [accessGrantedTotalResults, setAccessGrantedTotalResults] = useState(0);
    const [expiredTotalResults, setExpiredTotalResults] = useState(0);
    const [disabledTotalResults, setDisabledTotalResults] = useState(0);
    const [filterType, setFilterType] = useState('Application Pending');
    const [access, setAccess] = useState({
        id: '',
        status: '',
        expiry: '',
    });
    const [accessExpiryModal, setAccessExpiryModal] = useState(false);
    const [expiryError, setExpiryError] = useState('');

    const pageOption = [10, 25, 50, 100];
      

    useEffect(() => {
        getData();
    }, [searchTerm, paginationModel, admissionTabValue]);

    const getData = async () => {
        setLoading(true);
        try {
            const response = await apiService.get('/recordings-admission/list', {
                params: {
                    searchTerm: searchTerm,
                    page: paginationModel.page,
                    pageSize: paginationModel.pageSize,
                    type: filterType,
                },
            });

            if(filterType === 'Application Pending') {
                setUnpaidData(response?.data?.recordings);
            }
            else if(filterType === 'Paid') {
                setPaidData(response?.data?.recordings);
            }
            else if(filterType === 'Access Granted') {
                setAccessGrantedData(response?.data?.recordings);
            }
            else if(filterType === 'Expired') {
                setExpiredData(response?.data?.recordings);
            }
            else if(filterType === 'Disabled') {
                setDisabledData(response?.data?.recordings);
            }

            setUnpaidTotalResult(response?.data?.totalUnPaidResults);
            setPaidTotalResult(response?.data?.totalPaidResults);
            setAccessGrantedTotalResults(response?.data?.totalAccessGrantedResults);
            setExpiredTotalResults(response?.data?.totalExpiredResults);
            setDisabledTotalResults(response?.data?.totalDisabledResults);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const admissionTabValueChange = (value) => {
        setAdmissionTabValueChange(value);
        if(value === 'unPaid') {
            setFilterType('Application Pending');
        }
        else if(value === 'paid') {
            setFilterType('Paid');
        }
        else if(value === 'accessGranted') {
            setFilterType('Access Granted');
        }
        else if(value === 'expired') {
            setFilterType('Expired');
        }
        else if(value === 'disabled') {
            setFilterType('Disabled');
        }
    }

    const statusUpdate = async (id, status) => {
        try {
            const data = {
                id: id,
                status: status
            }

            const response = await apiService.post('/recordings-admission/status-update', data);
            getData();

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    const accessGrandSet = (id, status, expiry) => {
        setAccess({
            id: id,
            status: status,
            expiry: expiry
        });

        setAccessExpiryModal(true);
        setExpiryError('');
    }

    const handleGrandAccessSubmit = async () => {
        try {
            if(access.expiry) {
                const response = await apiService.post('/recordings-admission/status-update', access);
                getData();
                setAccessExpiryModal(false);
                setAccess({
                    id: '',
                    status: '',
                    expiry: '',
                });
            }
            else {
                setExpiryError('Expiry Date is required');
            }

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div>
            <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                <Typography variant="h4" gutterBottom>
                    Recordings Admissions
                </Typography>
            </div>
            <TextField
                label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                fullWidth
                margin="normal"
                style={{ marginBottom: 10 }}
                size='small'
            />
            <TabContext value={admissionTabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(e, newValue) => admissionTabValueChange(newValue)} aria-label="Admission tab">
                        <Tab label={`Unpaid - ${unpaidTotalResult}`} value="unPaid" />
                        <Tab label={`Paid - ${paidTotalResult}`} value="paid" />
                        <Tab label={`Access Granted - ${accessGrantedTotalResults}`} value="accessGranted" />
                        <Tab label={`Expired - ${expiredTotalResults}`} value="expired" />
                        <Tab label={`Disabled - ${disabledTotalResults}`} value="disabled" />
                    </TabList>
                </Box>
                <TabPanel value="unPaid">
                    {loading ? (
                        <CircularProgress style={{ margin: '20px auto', display: 'block' }} />
                    ) : (
                        <div style={{ height: '70vh', width: '100%' }}>
                            <DataGridPro
                                rows={unpaidData}
                                getRowId={(row) => row._id}
                                columns={[
                                    { field: 'name', headerName: 'Name', flex: 1, valueGetter: (params) => `${params.row?.firstName} ${params.row?.lastName}` },
                                    { field: 'email', headerName: 'Email', flex: 1 },
                                    { field: 'phoneNumber', headerName: 'Phone Number', flex: 1 },
                                    { field: 'course', headerName: 'Course', flex: 1, valueGetter: (params) => params.row?.course?.name },
                                    { field: 'statusType', headerName: 'Status Type', flex: 1 },
                                    { field: 'category', headerName: 'Category', flex: 1, valueGetter: (params) => params.row?.category?.name },
                                    { field: 'subject', headerName: 'Subject', flex: 1 },
                                    { field: 'price', headerName: 'Price', flex: 1 },
                                    { field: 'status', headerName: 'Status', flex: 1, valueGetter: (params) => 'Unpaid' },
                                ]}
                                pagination
                                paginationModel={paginationModel}
                                rowCount={unpaidTotalResult}
                                paginationMode="server"
                                onPaginationModelChange={setPaginationModel}
                                pageSizeOptions={pageOption}
                            />
                        </div>
                    )}
                </TabPanel>
                <TabPanel value="paid">
                    {loading ? (
                        <CircularProgress style={{ margin: '20px auto', display: 'block' }} />
                    ) : (
                        <div style={{ height: '70vh', width: '100%' }}>
                            <DataGridPro
                                rows={paidData}
                                getRowId={(row) => row._id}
                                columns={[
                                    { field: 'name', headerName: 'Name', width: 150, valueGetter: (params) => `${params.row?.firstName} ${params.row?.lastName}` },
                                    { field: 'email', headerName: 'Email', width: 200 },
                                    { field: 'phoneNumber', headerName: 'Phone Number', width: 150 },
                                    { field: 'course', headerName: 'Course', width: 100, valueGetter: (params) => params.row?.course?.name },
                                    { field: 'statusType', headerName: 'Status Type', width: 100 },
                                    { field: 'category', headerName: 'Category', width: 100, valueGetter: (params) => params.row?.category?.name },
                                    { field: 'subject', headerName: 'Subject', width: 200 },
                                    { field: 'price', headerName: 'Price', width: 100 },
                                    { field: 'status', headerName: 'Status', width: 150 },
                                    {
                                        field: 'actions',
                                        headerName: 'Action',
                                        width: 200,
                                        renderCell: (params) => (
                                            <>
                                                {permissions.includes('changeAdmissionStatusToAccessGranted') && (
                                                    <Button variant="outlined" onClick={() => accessGrandSet(params?.row?._id, 'Access Granted', params?.row?.expiry)}>Grant Access</Button>
                                                )}
                                            </>
                                        ),
                                    },
                                ]}
                                pagination
                                paginationModel={paginationModel}
                                rowCount={paidTotalResult}
                                paginationMode="server"
                                onPaginationModelChange={setPaginationModel}
                                pageSizeOptions={pageOption}
                            />
                        </div>
                    )}
                </TabPanel>
                <TabPanel value="accessGranted">
                    {loading ? (
                        <CircularProgress style={{ margin: '20px auto', display: 'block' }} />
                    ) : (
                        <div style={{ height: '70vh', width: '100%' }}>
                            <DataGridPro
                                rows={accessGrantedData}
                                getRowId={(row) => row._id}
                                columns={[
                                    { field: 'name', headerName: 'Name', flex: 1, valueGetter: (params) => `${params.row?.firstName} ${params.row?.lastName}` },
                                    { field: 'email', headerName: 'Email', flex: 1 },
                                    { field: 'phoneNumber', headerName: 'Phone Number', flex: 1 },
                                    { field: 'course', headerName: 'Course', flex: 1, valueGetter: (params) => params.row?.course?.name },
                                    { field: 'statusType', headerName: 'Status Type', flex: 1 },
                                    { field: 'category', headerName: 'Category', flex: 1, valueGetter: (params) => params.row?.category?.name },
                                    { field: 'subject', headerName: 'Subject', flex: 1 },
                                    { field: 'price', headerName: 'Price', flex: 1 },
                                    { field: 'expiry', headerName: 'Expiry', flex: 1, valueGetter: (params) => new Date(params?.row?.expiry).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }) },
                                    { field: 'status', headerName: 'Status', flex: 1 },
                                ]}
                                pagination
                                paginationModel={paginationModel}
                                rowCount={accessGrantedTotalResults}
                                paginationMode="server"
                                onPaginationModelChange={setPaginationModel}
                                pageSizeOptions={pageOption}
                            />
                        </div>
                    )}
                </TabPanel>
                <TabPanel value="expired">
                    {loading ? (
                        <CircularProgress style={{ margin: '20px auto', display: 'block' }} />
                    ) : (
                        <div style={{ height: '70vh', width: '100%' }}>
                            <DataGridPro
                                rows={expiredData}
                                getRowId={(row) => row._id}
                                columns={[
                                    { field: 'name', headerName: 'Name', width: 150, valueGetter: (params) => `${params.row?.firstName} ${params.row?.lastName}` },
                                    { field: 'email', headerName: 'Email', width: 200 },
                                    { field: 'phoneNumber', headerName: 'Phone Number', width: 150 },
                                    { field: 'course', headerName: 'Course', width: 100, valueGetter: (params) => params.row?.course?.name },
                                    { field: 'statusType', headerName: 'Status Type', width: 100 },
                                    { field: 'category', headerName: 'Category', width: 100, valueGetter: (params) => params.row?.category?.name },
                                    { field: 'subject', headerName: 'Subject', width: 200 },
                                    { field: 'price', headerName: 'Price', width: 100 },
                                    { field: 'expiry', headerName: 'Expiry', width: 100, valueGetter: (params) => new Date(params?.row?.expiry).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }) },
                                    { field: 'status', headerName: 'Status', width: 150 },
                                    {
                                        field: 'actions',
                                        headerName: 'Action',
                                        width: 200,
                                        renderCell: (params) => (
                                            <>
                                                {permissions.includes('changeAdmissionStatusToDisabled') && (
                                                    <Button variant="outlined" onClick={() => statusUpdate(params.row._id, 'Disabled')}>Disable</Button>
                                                )}
                                            </>
                                        ),
                                    },
                                ]}
                                pagination
                                paginationModel={paginationModel}
                                rowCount={expiredTotalResults}
                                paginationMode="server"
                                onPaginationModelChange={setPaginationModel}
                                pageSizeOptions={pageOption}
                            />
                        </div>
                    )}
                </TabPanel>
                <TabPanel value="disabled">
                    {loading ? (
                        <CircularProgress style={{ margin: '20px auto', display: 'block' }} />
                    ) : (
                        <div style={{ height: '70vh', width: '100%' }}>
                            <DataGridPro
                                rows={disabledData}
                                getRowId={(row) => row._id}
                                columns={[
                                    { field: 'name', headerName: 'Name', width: 150, valueGetter: (params) => `${params.row?.firstName} ${params.row?.lastName}` },
                                    { field: 'email', headerName: 'Email', width: 200 },
                                    { field: 'phoneNumber', headerName: 'Phone Number', width: 150 },
                                    { field: 'course', headerName: 'Course', width: 100, valueGetter: (params) => params.row?.course?.name },
                                    { field: 'statusType', headerName: 'Status Type', width: 100 },
                                    { field: 'category', headerName: 'Category', width: 100, valueGetter: (params) => params.row?.category?.name },
                                    { field: 'subject', headerName: 'Subject', width: 200 },
                                    { field: 'price', headerName: 'Price', width: 100 },
                                    { field: 'status', headerName: 'Status', width: 150 },
                                ]}
                                pagination
                                paginationModel={paginationModel}
                                rowCount={disabledTotalResults}
                                paginationMode="server"
                                onPaginationModelChange={setPaginationModel}
                                pageSizeOptions={pageOption}
                            />
                        </div>
                    )}
                </TabPanel>
            </TabContext>

            <Modal
                open={accessExpiryModal}
                onClose={() => setAccessExpiryModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
                                <FormControl className='gap-2 mb-8' fullWidth style={{ marginTop: '10px', marginBottom: '10px' }} size="small">
                                    <MobileDatePicker
                                        label="Expiry Date *"
                                        value={access.expiry ? dayjs(access.expiry) : null}
                                        onChange={(date) => setAccess((prevData) => ({...prevData, expiry: date}))}
                                        sx={{ marginTop: 1 }}
                                        format="DD/MM/YYYY"
                                        slotProps={{
                                            textField: { size: 'small' }
                                        }}
                                    />
                                    <FormHelperText error>{expiryError}</FormHelperText>
                                </FormControl>
                            </LocalizationProvider>
                            </Grid>
                        </Grid>
                        <div style={{ textAlign: 'center' }}>
                            <Button variant="outlined" sx={{ marginRight: '10px' }} onClick={() => { setAccessExpiryModal(false) }}>Cancel</Button>
                            <Button variant="contained" onClick={handleGrandAccessSubmit}>Grand Access</Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default MyTable;
