import React, { useState,useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import { useNavigate,useLocation } from "react-router-dom";
import config from '../../../config'; 
import apiService from '../../../utlis/apiService';
import {
  ArrowBackIos as BackButton,
} from '@mui/icons-material';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CreateBranchForm = () => {
  let query = useQuery();
  let id = query.get("id")
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    location: ''
  });

  useEffect(() => {
    const fetchBranchData = async () => {
      try {
        if (id) {
          const response = await apiService.get('/branch/get', {
            params: {
              id: id,
            },
          });
          setFormData(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchBranchData();
  }, [id]);

  const [errors, setErrors] = useState({
    phoneNumber: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      location: '',
    };

    setErrors(newErrors);
    return isValid;
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
  
    if (validateForm()) {
      const apiUrl = id ? `/branch/update?id=${id}` : '/branch/create';
  
      try {
        const response = await apiService.post(apiUrl, formData);
        navigate(-1);
        console.log(JSON.stringify(response.data));
      } catch (error) {
        alert(error?.response?.data?.error);
        console.error(error);
      }
    }
  };

  return (
    <div>
      <h1 style={{cursor:'pointer'}} onClick={()=>navigate(-1)}>
      <BackButton />
        {`Branches`}
      </h1>
    <form onSubmit={handleSubmit}>
      <Typography variant="h4" gutterBottom>
      {id ? 'Edit' : 'Create'} Branch
      </Typography>
      <TextField
        label="Name"
        name="name"
        value={formData.name}
        onChange={handleChange}
        fullWidth
        required
        margin="normal"
        size="small"
      />
      <TextField
        label="Location"
        name="location"
        value={formData.location}
        onChange={handleChange}
        fullWidth
        required
        error={Boolean(errors.location)}
        helperText={errors.location}
        margin="normal"
        size="small"
      />


      <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
       {id ? 'Update Branch' : 'Create Branch' }
      </Button>
 
    </form>
         
    </div>
  );
};

export default CreateBranchForm;
