import { Box, Button, Container, Typography } from "@mui/material";
import WarningIcon from '@mui/icons-material/Warning';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { Link } from "react-router-dom";

const styles = {
    error: {
        boxShadow: '0 15px 25px #00000019',
        padding: '45px',
        textAlign: 'center',
        margin: '40px auto',
        borderBottom: 'solid 4px #475d80',
    },
    errorIcon: {
        fontSize: '55px',
        color: '#475d80',
    },
    errorHeading: {
        marginBottom: '12px',
        marginTop: '10px',
    },
    errorSubHeading: {
        marginBottom: '0px',
        fontSize: {
            md: '18px',
            xs: '12px',
        },
        color: '#495057',
        fontWeight: 500,
    },
    linkButton: {
        color: '#475d80',
    },
    backIcon: {
        fontSize: '30px',
        color: '#475d80',
    },
}

const PaymentPending = () => {
    return (
        <Container maxWidth={'100%'} sx={{ paddingLeft: 0 }} style={{ padding: 0 }}>
            <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Box sx={{ alignSelf: 'center' }}>
                    <Box sx={styles.error}>
                        <WarningIcon sx={styles.errorIcon} />
                        <Typography variant="h4" sx={styles.errorHeading}> Your payment was pending </Typography>
                        <Typography sx={styles.errorSubHeading}> Your payment has not been confirmed by the merchant. <br /> Please wait a moment before attempting another payment! </Typography> 
                        <Link to="/student"><Button type="button" sx={styles.linkButton}><KeyboardReturnIcon sx={styles.backIcon} /> Go To Home</Button></Link> 
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}

export default PaymentPending;