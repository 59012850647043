import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { memo } from "react";

const ScheduleClassRoomView = memo(({classSchedule, calendarData}) => {
    return <div style={{ width: '100%', overflowX: 'auto' }}>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="custom table">
                <TableHead>
                    <TableRow>
                        <TableCell>Class Rooms</TableCell>
                        {calendarData.map((date, index) => (
                            <TableCell align="center" key={index}>{new Date(date).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' })}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {classSchedule.map((classes, index) => {
                        return <TableRow key={index}>
                            <TableCell component="th" scope="row">{classes.classRoom}</TableCell>
                            {calendarData.map((date, index1) => {
                                const thisDate = new Date(date).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' });
                                const scheduleItem = classes.schedule.find(schedule => new Date(schedule.date).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }) === thisDate);

                                let cell = <TableCell>-</TableCell>
                                
                                if(scheduleItem) {
                                    if(['Schedule', 'Weird Schedule'].includes(scheduleItem.type)) {
                                        cell = <TableCell sx={scheduleItem.type === 'Schedule' ? {color: 'green'} : {color: 'red'}} component="th" scope="row" key={index + index1}>{scheduleItem?.batchName + ' ' + scheduleItem?.faculty + ' ' + (scheduleItem?.start === 0 ? '-' : new Date(scheduleItem?.start).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })) + ' - ' + (scheduleItem?.end === 0 ? '-' : new Date(scheduleItem?.end).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }))}</TableCell>
                                    }
                                    else {
                                        cell = <TableCell sx={{color: 'red'}} component="th" scope="row" key={index + index1}>{scheduleItem?.batchName + ', ' + scheduleItem.faculty + ', ' + scheduleItem.type }</TableCell>
                                    }
                                }

                                return cell;
                            })}
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    </div>
});

export default ScheduleClassRoomView;