import React, { useEffect, useState } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Autocomplete, Button, Card, CircularProgress, FormControl, Grid, InputLabel, List, ListItem, TextField, Typography } from '@mui/material';
import apiService from '../../../utlis/apiService';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../context/userContext';
import DownloadIcon from '@mui/icons-material/Download';
import { apiUrl } from '../../../config';
import { generateYearRange } from '../../../utlis/commonFunction';
// import utc from 'dayjs/plugin/utc';
// import timezone from 'dayjs/plugin/timezone';

// dayjs.extend(utc);

export default function FeeReceivableReport() {
    const currentDate = new Date();
    const oneMonthAgo = new Date(currentDate);
    oneMonthAgo.setMonth(currentDate.getMonth() - 1);

    const [data, setData] = useState([]);
    const [batches, setBatches] = useState([]);
    const [grandTotalDueAmount, setGrandTotalDueAmount] = useState(0);
    const [branches, setBranches] = useState([]);
    const [totalResults, setTotalResults] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [isSelecting, setIsSelecting] = useState(false);

    const [filterData, setFilterData] = useState({
        name: '',
        batch: '',
        branch: '',
        year: '',
        dueDateFrom: oneMonthAgo,
        dueDateTo: new Date(),
    });

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 50,
        page: 0,
    });
    const [loading, setLoading] = useState(false);

    const pageOption = [10, 25, 50, 100];

    const years = generateYearRange();

    const navigate = useNavigate();
    const { permissions } = useUser();

    useEffect(() => {
        getReportsData();
    },[filterData, paginationModel]);

    useEffect(() => {
        getBranchData();
    }, []);

    useEffect(() => {
        if (!isSelecting) {
            getBatchData(searchQuery);
        }
    }, [searchQuery, isSelecting]);

    const getReportsData = async() => {
        setLoading(true);
        try {
            const studentsResponse = await apiService.get('/report/fee-receivable', {
                params: {
                    ...filterData,
                    page: paginationModel.page,
                    pageSize: paginationModel.pageSize,
                },
            });

            setData(studentsResponse?.data?.report);
            setTotalResults(studentsResponse?.data?.totalResults);
            setGrandTotalDueAmount(studentsResponse?.data?.grandTotalDueAmount);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    const getBranchData = async () => {
        try {
            const branchResponse = await apiService.get('/branch/list');

            setBranches(branchResponse?.data?.branchs);
        } catch (error) {
            console.error(error);
        }
    };

    const getBatchData = async (query) => {
        try {
            const batcheResponse = await apiService.get('/batch/list', {
                params: {
                    searchTerm: query,
                },
            });

            setBatches(batcheResponse?.data?.batchs);
        } catch (error) {
            console.error(error);
        }
    };

    const handleBatchSearchOptionChange = (event, newQuery, reason) => {
        if (reason === 'reset') {
            return;
        }
        setSearchQuery(newQuery);
    }

    const handleChangeSubmit = (e, value, name) => {
        if(name === 'batch') {
            setIsSelecting(true);
        }
        setFilterData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        if(name === 'batch') {
            setIsSelecting(false);
        }
    };

    const handleChange = (name, value) => {
        setFilterData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
    };

    const CustomDueDate = (categoryProp) => {
        const categories = categoryProp.value;
        return (
            <List>
                {categories?.map((category) => {
                    if(category?.ots?.amount > 0 && !category?.ots?.received) {
                        return <>
                            <ListItem key={category._id} sx={{ paddingTop: 0, paddingBottom: 0 }}>
                                {new Date(category?.ots?.dueDate).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' })}
                            </ListItem>
                            {category.feeSplit.map((fee) => {
                                if(!fee.received) {
                                    return <ListItem key={fee._id} sx={{ paddingTop: 0, paddingBottom: 0 }}>
                                        {new Date(fee.dueDate).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' })}
                                    </ListItem>
                                }
                            })}
                        </>
                    }
                    else {

                        return category.feeSplit.map((fee) => {
                            if(!fee.received) {
                                return <ListItem key={fee._id} sx={{ paddingTop: 0, paddingBottom: 0 }}>
                                    {new Date(fee.dueDate).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' })}
                                </ListItem>
                            }
                        });
                    }
                })}
            </List>
        );
    }

    const CustomDueAmount = (categoryProp) => {
        const categories = categoryProp.value;
        return (
            <List>
                {categories?.map((category) => {
                    if(category?.ots?.amount > 0 && !category?.ots?.received) {
                        return <>
                            <ListItem key={category._id} sx={{ paddingTop: 0, paddingBottom: 0 }}>{category?.ots?.amount}</ListItem>
                            {category.feeSplit.map((fee) => {
                                if(!fee.received) {
                                    return <ListItem key={fee._id} sx={{ paddingTop: 0, paddingBottom: 0 }}>{fee.value}</ListItem>
                                }
                            })}
                            {category.additionalCharge.map((fee) => {
                                if(!fee.received) {
                                    return <ListItem key={fee._id} sx={{ paddingTop: 0, paddingBottom: 0 }}>{fee.value}</ListItem>
                                }
                            })}
                        </>
                    }
                    else {

                        return <>
                            {category.feeSplit.map((fee) => {
                                if(!fee.received) {
                                    return <ListItem key={fee._id} sx={{ paddingTop: 0, paddingBottom: 0 }}>{fee.value}</ListItem>
                                }
                            })}
                            {category.additionalCharge.map((fee) => {
                                if(!fee.received) {
                                    return <ListItem key={fee._id} sx={{ paddingTop: 0, paddingBottom: 0 }}>{fee.value}</ListItem>
                                }
                            })}
                        </>
                    }
                })}
            </List>
        );
    }

    const CustomTotalDueAmount = (categoryProp) => {
        const categories = categoryProp.value;
        let dueAmount = 0;

        {categories?.map((category) => {
            if(category?.ots?.amount > 0 && !category?.ots?.received) {
                dueAmount += parseFloat(category?.ots?.amount);
                {category.feeSplit.map((fee) => {
                    if(!fee.received) {
                        dueAmount += parseFloat(fee.value);
                    }
                })}
                {category.additionalCharge.map((fee) => {
                    if(!fee.received) {
                        dueAmount += parseFloat(fee.value);
                    }
                })}
            }
            else {
                category.feeSplit.map((fee) => {
                    if(!fee.received) {
                        dueAmount += parseFloat(fee.value);
                    }
                });
                {category.additionalCharge.map((fee) => {
                    if(!fee.received) {
                        dueAmount += parseFloat(fee.value);
                    }
                })}
            }
        })}

        return dueAmount;
    }

    const CustomDueByDay = (categoryProp) => {
        const categories = categoryProp.value;
        return (
            <List>
                {categories?.map((category) => {
                    if(category?.ots?.amount > 0 && !category?.ots?.received) {
                        return <>
                            <ListItem key={category._id} sx={{ paddingTop: 0, paddingBottom: 0 }}>{Math.ceil((new Date() - new Date(category?.ots?.dueDate)) / (1000 * 60 * 60 * 24))}</ListItem>
                            {category.feeSplit.map((fee) => {
                                if(!fee.received) {
                                    return <ListItem key={category._id} sx={{ paddingTop: 0, paddingBottom: 0 }}>{Math.ceil((new Date() - new Date(fee.dueDate)) / (1000 * 60 * 60 * 24))}</ListItem>
                                }
                            })}
                            {category.additionalCharge.map((fee) => {
                                if(!fee.received) {
                                    return <ListItem key={category._id} sx={{ paddingTop: 0, paddingBottom: 0 }}>{Math.ceil((new Date() - new Date(fee.dueDate)) / (1000 * 60 * 60 * 24))}</ListItem>
                                }
                            })}
                        </>
                    }
                    else {
                        return <>
                            {category.feeSplit.map((fee) => {
                                if(!fee.received) {
                                    return <ListItem key={category._id} sx={{ paddingTop: 0, paddingBottom: 0 }}>{Math.ceil((new Date() - new Date(fee.dueDate)) / (1000 * 60 * 60 * 24))}</ListItem>
                                }
                            })}
                            {category.additionalCharge.map((fee) => {
                                if(!fee.received) {
                                    return <ListItem key={category._id} sx={{ paddingTop: 0, paddingBottom: 0 }}>{Math.ceil((new Date() - new Date(fee.dueDate)) / (1000 * 60 * 60 * 24))}</ListItem>
                                }
                            })}
                        </>
                    }
                })}
            </List>
        );
    }

    const CustomAcademicYear = (categoryProp) => {
        const categories = categoryProp.value;
        return (
            <List>
                {categories?.map((category) => {
                    return <ListItem key={category._id} sx={{ paddingTop: 0, paddingBottom: 0 }}>{category.category.year}</ListItem>
                })}
            </List>
        );
    }

    const CustomBranch = (CategoryProp) => {
        const category = CategoryProp.value;
        const uniqueBatchesMap = new Map();

        category.forEach(category => {
            category?.batch?.forEach(batch => {
                if (!uniqueBatchesMap.has(batch.branch_name)) {
                    
                    uniqueBatchesMap.set(batch.branch_name, batch.branch.name);
                }
            });
        })

        const uniqueBatches = Array.from(uniqueBatchesMap.values());
        return (
            <List>
                {uniqueBatches?.map((batch, index) => {
                    return (
                        <ListItem key={index} title={batch}>{batch}</ListItem>
                    );
                })}
            </List>
        );
    }

    const CustomBatch = (CategoryProp) => {
        const category = CategoryProp.value;
        const uniqueBatchesMap = new Map();

        category.forEach(category => {
            category?.batch?.forEach(batch => {
                if (!uniqueBatchesMap.has(batch.name)) {
                    uniqueBatchesMap.set(batch.name, batch.name);
                }
            });
        });

        const uniqueBatches = Array.from(uniqueBatchesMap.values());

        return(
            <List>
                {uniqueBatches?.map((batch, index) => {
                    return (
                        <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }} key={index} title={batch}>{batch.substring(0, 15)}...</ListItem>
                    );
                })}
            </List>
        );
    }

    const navigateToStudent = (params) => {
        if(permissions.includes('updateStudent')) {
            navigate(`/dashboard/students/view?id=${params?.row?.student?._id}`);
        }
    }

    const exportReport = async () => {
        try {
            const response = await apiService.get('/export/report/fee-receivable', {
                params: {
                    ...filterData,
                },
            });

            if(response?.data?.status === 'success') {
                
                window.open(`${apiUrl}${response?.data?.path}`, '_blank');
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    return (
        <div style={{ height: '75vh', width: '100%' }}>
            <Typography variant="h4" gutterBottom>
                FEE RECEIVABLE REPORT
            </Typography>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={3}>
                    <TextField
                        label="Name / Phone Number"
                        margin="normal"
                        name="name"
                        value={filterData.name}
                        onChange={(e) => handleChange('name', e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-branch"
                        name="branch"
                        options={branches}
                        style={{ marginTop: 15 }}
                        getOptionLabel={(option) => option.name}
                        value={branches.find((option) => option._id === filterData.branch) || null}
                        onChange={(e, value) => handleChangeSubmit(e, value?._id, 'branch')}
                        renderInput={(params) => <TextField {...params} label="Branch" />}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-batch"
                        name="batch"
                        options={batches}
                        style={{ marginTop: 15 }}
                        getOptionLabel={(option) => option.name}
                        value={batches.find((option) => option._id === filterData.batch) || null}
                        onChange={(e, value) => handleChangeSubmit(e, value?._id, 'batch')}
                        onInputChange={handleBatchSearchOptionChange}
                        renderInput={(params) => <TextField {...params} label="Batch" />}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        name="year"
                        required
                        options={years}
                        style={{ marginTop: 15 }}
                        getOptionLabel={(option) => option}  
                        value={years.find((option) => option === filterData.year) || null}
                        onChange={(e, value) => handleChangeSubmit(e, value, 'year')} 
                        renderInput={(params) => <TextField {...params} label="Academic Year" />}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center" sx={{ marginBottom: 5}}>
                <Grid item xs={6} style={{ marginTop: 10 }}>
                    <InputLabel id="dueDateFrom">Due Date From</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
                        <FormControl className='gap-2 mb-8' fullWidth>
                            <MobileDatePicker
                                value={filterData.dueDateFrom ? dayjs(filterData.dueDateFrom) : null}
                                onChange={(date) => handleChange('dueDateFrom', date)}
                                sx={{ marginTop: 1 }}
                                format="DD/MM/YYYY"
                                slotProps={{
                                    actionBar: {
                                      actions: ['clear', 'cancel', 'accept'],
                                    },
                                }}
                            />
                        </FormControl>
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={6} style={{ marginTop: 10 }}>
                    <InputLabel id="dueDateTo">Due Date To</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
                        <FormControl className='gap-2 mb-8' fullWidth>
                            <MobileDatePicker
                                value={filterData.dueDateTo ? dayjs(filterData.dueDateTo) : null}
                                onChange={(date) => handleChange('dueDateTo', date)}
                                sx={{ marginTop: 1 }}
                                format="DD/MM/YYYY"
                                slotProps={{
                                    actionBar: {
                                      actions: ['clear', 'cancel', 'accept'],
                                    },
                                }}
                            />
                        </FormControl>
                    </LocalizationProvider>
                </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center" sx={{ marginBottom: 3 }}>
                <Grid item xs={4}>
                    <Card sx={{ padding: 2, backgroundColor: '#dde6ee' }}>
                        <Typography variant='h6'>
                            Total Due Amount:
                            <br />
                            {grandTotalDueAmount}
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={8}>
                    {permissions.includes('exportFeeReceivableReport') && (
                        <Button variant="outlined" sx={{ float: 'right' }} title="Export" onClick={exportReport}><DownloadIcon /></Button>
                    )}
                </Grid>
            </Grid>
            
            {loading ? (
                <div style={{ textAlign: 'center' }}>
                    <CircularProgress style={{ margin: 'auto' }} />
                </div>

            ) : (
                <DataGridPro
                    getRowId={(row) => row._id}
                    rows={data}
                    onRowClick={navigateToStudent}
                    getRowHeight={() => 'auto'}
                    disableDensitySelector
                    columns={[
                        { field: 'student_name', headerName: 'Student', flex: 1, valueGetter: (params) =>  params?.row?.student?.firstName + ' ' + params?.row?.student?.lastName },
                        { field: 'student_phone', headerName: 'Phone', flex: 1, valueGetter: (params) =>  params?.row?.student?.phoneNumber },
                        { field: 'dueDate', headerName: 'Due Date', flex: 1, renderCell: (params) => <CustomDueDate value={params?.row?.category} /> },
                        { field: 'dueAmount', headerName: 'Due Amount', flex: 1, renderCell: (params) => <CustomDueAmount value={params?.row?.category} /> },
                        { field: 'totalDueAmount', headerName: 'Total Due Amount', flex: 1, renderCell: (params) => <CustomTotalDueAmount value={params?.row?.category} /> },
                        { field: 'dueByDays', headerName: 'Due By Days', flex: 1, renderCell: (params) => <CustomDueByDay value={params?.row?.category} /> },
                        { field: 'year', headerName: 'Academic Year', flex: 1, renderCell: (params) => <CustomAcademicYear value={params?.row?.category} /> },
                        { field: 'branch', headerName: 'Branch', flex: 1, renderCell: (params) => <CustomBranch value={params?.row?.category} /> },
                        { field: 'batch', headerName: 'Batch', flex: 1, renderCell: (params) => <CustomBatch value={params?.row?.category} /> },
                        { field: 'category', headerName: 'Category', flex: 1, valueGetter: (params) => params?.row?.category.map(category => category?.category.name).join(', ') },
                    ]}
                    pagination
                    paginationModel={paginationModel}
                    rowCount={totalResults}
                    paginationMode="server"
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={pageOption}
                />
            )}
        </div>
    );
}
