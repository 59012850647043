// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './admin/Login';
import Dashboard from './admin/App';
import Student from './student/App';
import Faculty from './faculty/App'
import FacultyLogin from './faculty/loginForm'
import ProtectedRoute from '../src/utlis/protectedRoute';
import ProtectedFroute from '../src/utlis/protectedFroute';
import PaymentSuccess from './common/pages/paymentSuccess';
import PaymentError from './common/pages/paymentError';
import InternalPaymentError from './common/pages/internalPaymentError';
import PaymentPending from './common/pages/paymentPending';
import Referrer from './referrer/App';
import Error404 from './common/pages/404';

const App = () => {

  const token = localStorage.getItem('token');
  const fToken = localStorage.getItem('ftoken');

  return (
    <Router>
      <Routes>
        <Route
          path="/dashboard/*"
          element={<ProtectedRoute element={<Dashboard />} />}
        />
        <Route path="/admin" element={token ? (<Navigate replace to={'/dashboard'} />) : (<Login />)} />
        <Route path="/student/*" element={<Student />} />

        <Route
          path="/faculty/*"
          element={<ProtectedFroute element={<Faculty />} />}
        />
        <Route path="/faculty-login" element={fToken ? (<Navigate replace to={'/faculty/home'} />) : (<FacultyLogin />)} />
        <Route path="/" element={<Navigate to="/student" />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="/payment-error" element={<PaymentError />} />
        <Route path="/payment-pending" element={<PaymentPending />} />
        <Route path="/internal-payment-error" element={<InternalPaymentError />} />

        <Route path="/referrer/*" element={<Referrer />} />

        <Route path="/error-404" element={<Error404 />} />
      </Routes>
    </Router>
  );
};

export default App;
