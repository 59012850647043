import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { memo, useState } from "react";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    maxHeight: '80%',
};

const ScheduleBatchView = memo(({batchSchedule, calendarData, classDataList, allScheduleList, addUpdateSchedule, removeUpdateSchedule, performUpdate}) => {
    const [scheduleAddModal, setScheduleAddModal] = useState(false);
    const [scheduleDeleteModal, setScheduleDeleteModal] = useState(false);
    const [editMode, setEditMode] = useState('');
    const [selectedBatch, setSelectedBatch] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedSlot, setSelectedSlot] = useState('');
    const [selectedBatchSize, setSelectedBatchSize] = useState(0);
    const [deleteScheduleId, setDeleteScheduleId] = useState('');
    const [scheduleUpdateList, setScheduleUpdateList] = useState({
        addList: [],
        deleteList: [],
    });

    const addScheduleToDate = (date) => {
        setSelectedDate(date);
        setScheduleAddModal(true);
        setSelectedSlot('');
    }

    const removeScheduleFromDate = (date, id) => {
        setSelectedDate(date);
        setDeleteScheduleId(id);
        setScheduleDeleteModal(true);
    }

    const editBatchScheduleRow = (index, batchSize, batch) => {
        setEditMode(index);
        setSelectedBatch(batch);
        setSelectedBatchSize(batchSize);
    }

    const handleAddToBatchScheduleSubmit = () => {
        const now = new Date(selectedDate);
        const time1 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), '09', '30');
        const time2 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), '13', '00');
        const time3 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), '14', '00');
        const time4 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), '17', '30');

        const timeSlots = [
            { start: time1, end: time4 }, // Full Day
            { start: time1, end: time2 }, // FN
            { start: time3, end: time4 }, // AN
        ];

        const slotSplit = selectedSlot.split('-');
        let selectedTimeSlot = timeSlots[1];

        if(slotSplit[0] === '09' && slotSplit[1] === '17') {
            selectedTimeSlot = timeSlots[0];
        }
        else if(slotSplit[0] === '09' && slotSplit[1] === '13') {
            selectedTimeSlot = timeSlots[1];
        }
        else if(slotSplit[0] === '14' && slotSplit[1] === '17') {
            selectedTimeSlot = timeSlots[2];
        }

        const filteredClass = classDataList.filter(classL => classL.capacity >= selectedBatchSize).sort((a, b) => a - b);

        const dateChecker = (date1, date2) => {
            const formatedDate1 = new Date(date1).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' });
            const formatedDate2 = new Date(date2).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' });
            
            return formatedDate1 === formatedDate2;
        }

        const timeChecker = (slot1, slot2) => {
            const start1 = getMinutesSinceMidnight(new Date(slot1.start));
            const end1 = getMinutesSinceMidnight(new Date(slot1.end));
            const start2 = getMinutesSinceMidnight(new Date(slot2.start));
            const end2 = getMinutesSinceMidnight(new Date(slot2.end));

            return ((start1 >= start2 && start1 < end2) || (end1 > start2 && end1 <= end2) || (start1 <= start2 && end1 >= end2));
        }

        const getMinutesSinceMidnight = (date) => {
            return date.getHours() * 60 + date.getMinutes();
        }
        
        for(const classRoom of filteredClass) {
            const isClassAvailable = allScheduleList.find(schedule => {
                const isDateSame = dateChecker(schedule.date, selectedDate);
                const isSameClassRoom = schedule.classRoomName === classRoom.name;
                const isTimeSame = timeChecker({start: schedule.start, end: schedule.end}, selectedTimeSlot);

                return (isDateSame && isSameClassRoom && isTimeSame);
            });

            if(!isClassAvailable) {
                const anyValidSchedule = allScheduleList.find(schedule => schedule.type === 'Schedule' && schedule.batchId === selectedBatch);
                if(anyValidSchedule) {
                    const newSchedule = {
                        year: anyValidSchedule.year,
                        session: anyValidSchedule.session,
                        batch: anyValidSchedule.batch,
                        batchId: selectedBatch,
                        faculty: anyValidSchedule.faculty,
                        date: new Date(selectedDate),
                        start: selectedTimeSlot.start,
                        end: selectedTimeSlot.end,
                        status: anyValidSchedule.status,
                        classRoom: classRoom._id,
                        classRoomName: classRoom.name,
                        classBy: anyValidSchedule.classBy,
                        type: 'Schedule',
                    }

                    addUpdateSchedule(newSchedule);

                    const storedSchedule = {
                        year: anyValidSchedule.year,
                        session: anyValidSchedule.session,
                        batch: selectedBatch,
                        date: new Date(selectedDate),
                        start: selectedTimeSlot.start,
                        end: selectedTimeSlot.end,
                        status: anyValidSchedule.status,
                        classRoom: classRoom._id,
                        classBy: anyValidSchedule.classBy,
                        type: 'Schedule',
                    }

                    setScheduleUpdateList((prevDate) => ({
                        addList: [...prevDate.addList, storedSchedule],
                        deleteList: prevDate.deleteList,
                    }));
                }

                break;
            }
        }

        setScheduleAddModal(false);
    }

    const handleRemoveScheduleFromBatchSubmit = () => {
        removeUpdateSchedule(deleteScheduleId, selectedDate, selectedBatch);
        setScheduleDeleteModal(false);
        setScheduleUpdateList((prevDate) => ({
            addList: prevDate.addList.filter(schedule => (schedule.batch === selectedBatch) && (new Date(schedule.date).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }) !== new Date(selectedDate).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }))),
            deleteList: deleteScheduleId ? [...prevDate.deleteList, deleteScheduleId] : prevDate.deleteList,
        }));
    }

    const saveEditBatchScheduleRow = (index, schedule) => {
        const year = schedule?.year;
        const session = schedule?.session;

        console.log(scheduleUpdateList, 'scheduleUpdateList');
        if(scheduleUpdateList.addList.length || scheduleUpdateList.deleteList.length) {
            performUpdate(scheduleUpdateList, year, session);
        }

        setEditMode('');
    }

    return <div style={{ width: '100%', overflowX: 'auto' }}>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="custom table">
                <TableHead>
                    <TableRow>
                        <TableCell>Batchs</TableCell>
                        <TableCell>Report</TableCell>
                        {calendarData.map((date, index) => (
                            <TableCell align="center" key={index}>{new Date(date).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' })}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {batchSchedule.map((batch, index) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row">
                                {editMode === index ?
                                    <Button type="button" variant="contained" color="primary" onClick={() => saveEditBatchScheduleRow(index, batch.schedule[0])}>Save</Button>
                                :
                                    <Button type="button" variant="contained" color="primary" onClick={() => editBatchScheduleRow(index, batch?.availablility?.size, batch.batchId)}>Edit</Button>
                                }
                                {batch.batchName}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                Total Hrs: {batch?.availablility?.totalHours}
                                <br />
                                Remaining Hrs: {batch?.availablility?.allotedHours > batch?.availablility?.totalHours ? 0 : batch?.availablility?.remainingHours}
                                <br />
                                Alloted Hrs: {batch?.availablility?.allotedHours}
                                <br />
                                Batch Size: {batch?.availablility?.size}
                                <br />
                                {/* {faculty?.availablility?.reason ? `Reason: ${faculty?.availablility?.reason}` : ''} */}
                            </TableCell>
                            {calendarData.map((date, index1) => {
                                const thisDate = new Date(date).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' });
                                const scheduleItem = batch.schedule.find(schedule => new Date(schedule.date).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }) === thisDate);

                                let cell = <TableCell>
                                        {editMode === index ?
                                            <Button type="button" onClick={() => addScheduleToDate(date)}>Add</Button>
                                        : '-'}
                                    </TableCell>

                                if(scheduleItem) {
                                    if(['Schedule', 'Weird Schedule'].includes(scheduleItem.type)) {
                                        cell = <TableCell sx={scheduleItem.type === 'Schedule' ? {color: 'green'} : {color: 'red'}} component="th" scope="row" key={index + index1}>
                                            {editMode === index ?
                                                <Button type="button" color="error" onClick={() => removeScheduleFromDate(date, scheduleItem._id)}>Delete</Button>
                                            : ''}
                                            {scheduleItem?.faculty + ' ' + scheduleItem?.classRoomName + ' ' + (scheduleItem?.start === 0 ? '-' : new Date(scheduleItem?.start).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })) + ' - ' + (scheduleItem?.end === 0 ? '-' : new Date(scheduleItem?.end).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }))}
                                        </TableCell>
                                    }
                                    else {
                                        cell = <TableCell sx={{color: 'red'}} component="th" scope="row" key={index + index1}>
                                            {editMode === index ?
                                                <Button type="button" color="error" onClick={() => removeScheduleFromDate(date, scheduleItem._id)}>Delete</Button>
                                            : ''}
                                            {scheduleItem?.faculty + ', ' + scheduleItem?.type}
                                        </TableCell>
                                    }
                                }

                                return cell;
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

        <Modal
            open={scheduleAddModal}
            onClose={() => {setScheduleAddModal(false); setSelectedSlot(''); setSelectedDate('');}}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div>
                    <FormControl fullWidth style={{ marginTop: '20px', marginBottom: '20px'}}>
                        <InputLabel id="TimeSlot">Time Slot</InputLabel>
                        <Select
                            label="Time Slot"
                            name="slot"
                            value={selectedSlot}
                            onChange={(e) => setSelectedSlot(e.target.value)}
                            fullWidth
                            margin="dense"
                        >
                            <MenuItem value="09-17">09:30 AM - 05:30 PM</MenuItem>
                            <MenuItem value="09-13">09:30 AM - 01:00 PM</MenuItem>
                            <MenuItem value="14-17">02:00 PM - 05:30 PM</MenuItem>
                        </Select>
                    </FormControl>
                    <div style={{ textAlign: 'center' }}>
                        <Button variant="outlined" sx={{ marginRight: '10px' }} onClick={() => {setScheduleAddModal(false); setSelectedSlot(''); setSelectedDate('');}}>Cancel</Button>
                        <Button variant="contained" onClick={handleAddToBatchScheduleSubmit}>Add</Button>
                    </div>
                </div>
            </Box>
        </Modal>

        <Modal
            open={scheduleDeleteModal}
            onClose={() => {setScheduleDeleteModal(false); setSelectedDate('');}}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div>
                    <p>Do you really want to delete this?</p>
                    <div style={{ textAlign: 'center' }}>
                        <Button variant="outlined" sx={{ marginRight: '10px' }} onClick={() => {setScheduleDeleteModal(false); setSelectedDate('');}}>Cancel</Button>
                        <Button variant="contained" onClick={handleRemoveScheduleFromBatchSubmit}>Delete</Button>
                    </div>
                </div>
            </Box>
        </Modal>
    </div>
});

export default ScheduleBatchView;