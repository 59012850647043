import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Modal, Select, Tab, TextField, Typography } from '@mui/material';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import apiService from '../../../utlis/apiService';
import { useUser } from '../../../context/userContext';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useNavigate } from 'react-router-dom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    maxHeight: '80%',
};

const RegistrationTable = () => {
    const { permissions } = useUser();
    const [pendingData, setPendingData] = useState([]);
    const [operationData, setOperationData] = useState([]);
    const [accountsData, setAccountsData] = useState([]);
    const [completedData, setCompletedData] = useState([]);
    const [rejectedData, setRejected] = useState([]);
    const [loading, setLoading] = useState(false);
    const [approveModal, setApproveModal] = useState(false);
    const [rejectModal, setRejectModal] = useState(false);
    const [selectdApprovalAdmissionId, setSelectdApprovalAdmissionId] = useState('');
    const [selectdApprovalCategoryId, setSelectdApprovalCategoryId] = useState('');
    const [approvalRejectOperation, setApprovalRejectOperation] = useState('');
    const [rejectRemark, setRejectRemark] = useState('');
    const [applicationTotalResults, setApplicationTotalResults] = useState(0);
    const [operationTotalResults, setOperationTotalResults] = useState(0);
    const [accountsTotalResults, setAccountsTotalResults] = useState(0);
    const [completedTotalResults, setCompletedTotalResults] = useState(0);
    const [rejectedTotalResults, setRejectedTotalResults] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 50,
        page: 0,
    });
    const [filterData, setFilterData] = useState({
        dateFrom: null,
        dateTo: null,
    });
    const [registrationTabValue, setRegistrationTabValue] = useState('pendingApplication');
    const [filterType, setFilterType] = useState('Application Pending');
    const [resendLinkPhone, setResendLinkPhone] = useState('');

    const pageOption = [10, 25, 50, 100];

    const navigate = useNavigate();

    useEffect(() => {
        getData();
    }, [searchTerm, paginationModel, filterData, filterType]);

    const getData = async () => {
        setLoading(true);
        try {
            const response = await apiService.get(`/student/registration/list`, {
                params: {
                    ...filterData,
                    searchTerm: searchTerm,
                    page: paginationModel.page,
                    pageSize: paginationModel.pageSize,
                    type: filterType,
                },
            });

            if(filterType === 'Application Pending') {
                setPendingData(response?.data?.students);
            }
            else if(filterType === 'Operation Pending') {
                setOperationData(response?.data?.students);
            }
            else if(filterType === 'Accounts Pending') {
                setAccountsData(response?.data?.students);
            }
            else if(filterType === 'Completed') {
                setCompletedData(response?.data?.students);
            }
            else if(filterType === 'Rejected') {
                setRejected(response?.data?.students);
            }
            setApplicationTotalResults(response?.data?.applicationPendingTotalResults);
            setOperationTotalResults(response?.data?.operationPendingTotalResults);
            setAccountsTotalResults(response?.data?.accountsPendingTotalResults);
            setCompletedTotalResults(response?.data?.completedTotalResults);
            setRejectedTotalResults(response?.data?.rejectedTotalResults);

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleApprovalSubmit = async () => {
        try {
            if(approvalRejectOperation === 'Application Rejected' && !rejectRemark) {
                alert('Remark is required');
            }
            else if(approvalRejectOperation === 'Application Pending' && !resendLinkPhone) {
                alert('Phone number is required');
            }
            else {
                const data = {
                    categoryId: selectdApprovalCategoryId,
                    admissionId: selectdApprovalAdmissionId,
                    operation: approvalRejectOperation,
                    remark: rejectRemark,
                    resendLinkPhone: resendLinkPhone,
                }
                
                const response = await apiService.post(`/student/registration/submit` , data, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if(response?.data?.status === 'success'){
                    if(approvalRejectOperation === 'Application Rejected') {
                        setRejectModal(false);
                    }
                    else {
                        setApproveModal(false);
                    }
                    alert(response?.data?.message);
                    getData();
                }
            }

        } catch (error) {
            console.error(error);
        }
    }

    const handleFilterChange = (name, value) => {
        setFilterData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
    };

    const registrationTabValueChange = (value) => {
        setRegistrationTabValue(value);
        if(value === 'pendingApplication') {
            setFilterType('Application Pending');
        }
        else if(value === 'operationPending') {
            setFilterType('Operation Pending');
        }
        else if(value === 'accountsPending') {
            setFilterType('Accounts Pending');
        }
        else if(value === 'completed') {
            setFilterType('Completed');
        }
        else if(value === 'rejected') {
            setFilterType('Rejected');
        }
    }

    const approveOrReject = (categoryId, admissionId, action) => {
        setSelectdApprovalCategoryId(categoryId);
        setSelectdApprovalAdmissionId(admissionId);
        setApprovalRejectOperation(action);
    }

    const createPress = () => {
        navigate('/dashboard/registrations/add');
    }

    return (
        <div>
            <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                <Typography variant="h4" gutterBottom>REGISTRATIONS</Typography>
                {permissions.includes('createStudent') && <Button variant="contained" color="primary" style={{ marginBottom: '16px' }} onClick={() => createPress()}>
                    + Create
                </Button>}
            </div>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={4}>
                    <TextField
                        label="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        fullWidth
                        margin="normal"
                        size='small'
                        style={{ marginBottom: 10 }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
                        <FormControl className='gap-2 mb-8' fullWidth size='small'>
                            <MobileDatePicker
                                label="Date From"
                                value={filterData.dateFrom}
                                onChange={(date) => handleFilterChange('dateFrom', date)}
                                sx={{ marginTop: 1 }}
                                format="DD/MM/YYYY"
                                slotProps={{
                                    actionBar: {
                                        actions: ['clear', 'cancel', 'accept'],
                                    },
                                    textField: { size: 'small' }
                                }}
                            />
                        </FormControl>
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
                        <FormControl className='gap-2 mb-8' fullWidth size='small'>
                            <MobileDatePicker
                                label="Date To"
                                value={filterData.dateTo}
                                onChange={(date) => handleFilterChange('dateTo', date)}
                                sx={{ marginTop: 1 }}
                                format="DD/MM/YYYY"
                                slotProps={{
                                    actionBar: {
                                        actions: ['clear', 'cancel', 'accept'],
                                    },
                                    textField: { size: 'small' }
                                }}
                            />
                        </FormControl>
                    </LocalizationProvider>
                </Grid>
            </Grid>

            <TabContext value={registrationTabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(e, newValue) => registrationTabValueChange(newValue)} aria-label="Registration tab">
                        <Tab label={`Pending Application - ${applicationTotalResults}`} value="pendingApplication" />
                        <Tab label={`Operation Pending - ${operationTotalResults}`} value="operationPending" />
                        <Tab label={`Accounts Pending - ${accountsTotalResults}`} value="accountsPending" />
                        <Tab label={`Completed - ${completedTotalResults}`} value="completed" />
                        <Tab label={`Rejected - ${rejectedTotalResults}`} value="rejected" />
                    </TabList>
                </Box>
                <TabPanel value="pendingApplication">
                    {loading ? (
                        <div style={{ textAlign: 'center' }}>
                            <CircularProgress style={{ margin: 'auto' }} />
                        </div>
                    ) : (
                        <div style={{ height: '70vh', width: '100%' }}>
                            <DataGridPro
                                rows={pendingData}
                                getRowId={(row) => row._id}
                                columns={[
                                    { field: 'createdAt', headerName: 'Date', flex: 1, valueFormatter: (params) => new Date(params.value).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }) },
                                    { field: 'name', headerName: 'Name', flex: 1 },
                                    { field: 'phoneNumber', headerName: 'Phone Number', flex: 1 },
                                    {
                                        field: 'action',
                                        headerName: 'Action',
                                        flex: 1,
                                        renderCell: (params) => (
                                            <div>
                                                {(permissions.includes('editRegistration')) && <Button variant="outlined" onClick={() => { navigate(`/dashboard/registrations/edit?id=${params?.row?.studentId}&admission=${params?.row?.admissionId}&category=${params?.row?._id}`) }} sx={{ marginRight: '10px' }}>Edit</Button>}
                                                {(permissions.includes('resendRegistration') && params.row?.registrationStatus === 'Application Pending') && <Button variant="outlined" onClick={() => { setApproveModal(true); approveOrReject(params.row?._id, params?.row?.admissionId, 'Application Pending'); setResendLinkPhone(params.row?.phoneNumber); }} >Resend Link</Button>}
                                            </div>
                                        ),
                                    },
                                ]}
                                pagination
                                paginationModel={paginationModel}
                                rowCount={applicationTotalResults}
                                paginationMode="server"
                                onPaginationModelChange={setPaginationModel}
                                pageSizeOptions={pageOption} 
                            />
                        </div>
                    )}
                </TabPanel>
                <TabPanel value="operationPending">
                    {loading ? (
                        <div style={{ textAlign: 'center' }}>
                            <CircularProgress style={{ margin: 'auto' }} />
                        </div>
                    ) : (
                        <div style={{ height: '70vh', width: '100%' }}>
                            <DataGridPro
                                rows={operationData}
                                getRowId={(row) => row._id}
                                columns={[
                                    { field: 'createdAt', headerName: 'Date', flex: 1, valueFormatter: (params) => new Date(params.value).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }) },
                                    { field: 'name', headerName: 'Name', flex: 1 },
                                    { field: 'phoneNumber', headerName: 'Phone Number', flex: 1 },
                                    {
                                        field: 'actions',
                                        headerName: 'Actions',
                                        flex: 1,
                                        renderCell: (params) => (
                                            <div>
                                                {(permissions.includes('editRegistration')) && <Button variant="outlined" onClick={() => { navigate(`/dashboard/registrations/edit?id=${params?.row?.studentId}&admission=${params?.row?.admissionId}&category=${params?.row?._id}`) }}>Edit</Button>}
                                                {(permissions.includes('operationsApproveRegistration') && params.row?.registrationStatus === 'Operation Pending') && <Button variant="outlined" onClick={() => { setApproveModal(true); approveOrReject(params.row?._id, params?.row?.admissionId, 'Operation Pending'); }} sx={{ marginLeft: '10px' }}>Approve</Button>}
                                            </div>
                                        ),
                                    },
                                ]}
                                pagination
                                paginationModel={paginationModel}
                                rowCount={operationTotalResults}
                                paginationMode="server"
                                onPaginationModelChange={setPaginationModel}
                                pageSizeOptions={pageOption} 
                            />
                        </div>
                    )}
                </TabPanel>
                <TabPanel value="accountsPending">
                    {loading ? (
                        <div style={{ textAlign: 'center' }}>
                            <CircularProgress style={{ margin: 'auto' }} />
                        </div>
                    ) : (
                        <div style={{ height: '70vh', width: '100%' }}>
                            <DataGridPro
                                rows={accountsData}
                                getRowId={(row) => row._id}
                                columns={[
                                    { field: 'createdAt', headerName: 'Date', flex: 1, valueFormatter: (params) => new Date(params.value).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }) },
                                    { field: 'name', headerName: 'Name', flex: 1 },
                                    { field: 'phoneNumber', headerName: 'Phone Number', flex: 1 },
                                    {
                                        field: 'actions',
                                        headerName: 'Actions',
                                        flex: 1,
                                        renderCell: (params) => (
                                            <div>
                                                {(permissions.includes('editRegistration')) && <Button variant="outlined" onClick={() => { navigate(`/dashboard/registrations/edit?id=${params?.row?.studentId}&admission=${params?.row?.admissionId}&category=${params?.row?._id}`) }}>Edit</Button>}
                                                {(permissions.includes('approveRegistration') && params.row?.registrationStatus === 'Accounts Pending') && <Button variant="outlined" onClick={() => { setApproveModal(true); approveOrReject(params.row?._id, params?.row?.admissionId, 'Accounts Pending'); }} sx={{ marginLeft: '10px' }}>Approve</Button>}
                                                {(permissions.includes('rejectRegistration') && params.row?.registrationStatus === 'Accounts Pending') && <Button variant="outlined" color="error" onClick={() => { setRejectModal(true); approveOrReject(params.row?._id, params?.row?.admissionId, 'Application Rejected'); }} sx={{ marginLeft: '10px' }}>Reject</Button>}
                                            </div>
                                        ),
                                    },
                                ]}
                                pagination
                                paginationModel={paginationModel}
                                rowCount={accountsTotalResults}
                                paginationMode="server"
                                onPaginationModelChange={setPaginationModel}
                                pageSizeOptions={pageOption} 
                            />
                        </div>
                    )}
                </TabPanel>
                <TabPanel value="completed">
                    {loading ? (
                        <div style={{ textAlign: 'center' }}>
                            <CircularProgress style={{ margin: 'auto' }} />
                        </div>
                    ) : (
                        <div style={{ height: '70vh', width: '100%' }}>
                            <DataGridPro
                                rows={completedData}
                                getRowId={(row) => row._id}
                                columns={[
                                    { field: 'createdAt', headerName: 'Date', flex: 1, valueFormatter: (params) => new Date(params.value).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }) },
                                    { field: 'name', headerName: 'Name', flex: 1 },
                                    { field: 'phoneNumber', headerName: 'Phone Number', flex: 1 },
                                ]}
                                pagination
                                paginationModel={paginationModel}
                                rowCount={completedTotalResults}
                                paginationMode="server"
                                onPaginationModelChange={setPaginationModel}
                                pageSizeOptions={pageOption} 
                            />
                        </div>
                    )}
                </TabPanel>
                <TabPanel value="rejected">
                    {loading ? (
                        <div style={{ textAlign: 'center' }}>
                            <CircularProgress style={{ margin: 'auto' }} />
                        </div>
                    ) : (
                        <div style={{ height: '70vh', width: '100%' }}>
                            <DataGridPro
                                rows={rejectedData}
                                getRowId={(row) => row._id}
                                columns={[
                                    { field: 'createdAt', headerName: 'Date', flex: 1, valueFormatter: (params) => new Date(params.value).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }) },
                                    { field: 'name', headerName: 'Name', flex: 1 },
                                    { field: 'phoneNumber', headerName: 'Phone Number', flex: 1 },
                                    { field: 'rejectedRemark', headerName: 'Remark', flex: 1 },
                                    {
                                        field: 'actions',
                                        headerName: 'Actions',
                                        flex: 1,
                                        renderCell: (params) => (
                                            <div>
                                                {(permissions.includes('resendRegistration') && params.row?.registrationStatus === 'Rejected') && <Button variant="outlined" onClick={() => { setApproveModal(true); approveOrReject(params.row?._id, params?.row?.admissionId, 'Rejected'); }} >Resend Link</Button>}
                                            </div>
                                        ),
                                    },
                                ]}
                                pagination
                                paginationModel={paginationModel}
                                rowCount={rejectedTotalResults}
                                paginationMode="server"
                                onPaginationModelChange={setPaginationModel}
                                pageSizeOptions={pageOption} 
                            />
                        </div>
                    )}
                </TabPanel>
            </TabContext>

            <Modal
                open={approveModal}
                onClose={() => setApproveModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div>
                        <p>Do you really want to {approvalRejectOperation === 'Application Pending' ? 'resend the link' : 'approve this'}?</p>
                        {approvalRejectOperation === 'Application Pending' ?
                            <TextField
                                label="Phone Number"
                                name="phoneNumber"
                                value={resendLinkPhone}
                                onChange={(e) => setResendLinkPhone(e.target.value)}
                                fullWidth
                                margin="normal"
                                size='small'
                                required
                            />
                        : ''}
                        <div style={{ textAlign: 'center' }}>
                            <Button variant="outlined" sx={{ marginRight: '10px' }} onClick={() => setApproveModal(false)}>Cancel</Button>
                            <Button variant="contained" onClick={handleApprovalSubmit}>{approvalRejectOperation === 'Application Pending' ? 'Send' : 'Approve'}</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Modal
                open={rejectModal}
                onClose={() => setRejectModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div>
                        <TextField
							label="Remark"
							name="remark"
							value={rejectRemark}
							onChange={(e) => setRejectRemark(e.target.value)}
							fullWidth
							margin="normal"
							size='small'
                            required
						/>
                        <div style={{ textAlign: 'center' }}>
                            <Button variant="outlined" sx={{ marginRight: '10px' }} onClick={() => setRejectModal(false)}>Cancel</Button>
                            <Button variant="contained" onClick={handleApprovalSubmit}>Reject</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

        </div>
    );
};

export default RegistrationTable;