import apiService from "./apiService";

const generateYearRange = () => {
    const today = new Date();

    const currentYear = today.getFullYear();
    const ranges = [];
    const startYear = 2020;
    
    // Generate year ranges up to the current year
    for (let year = startYear; year <= currentYear + 1; year++) {
        const yearEnd = year + 1;
        ranges.push(`${year}-${yearEnd}`);
    }
    
    return ranges;
}

const generateYears = () => {
    const today = new Date();

    const currentYear = today.getFullYear();
    const years = [];
    const startYear = 2020;
    let lastYear = startYear;
    
    // Generate years up to the current year
    for (let year = startYear; year <= currentYear; year++) {
        years.push(''+year);
        lastYear = year;
    }
    
    years.push(''+ (lastYear + 1));
    return years;
}

const getSessions = () => {
    return [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sept',
        'Oct',
        'Nov',
        'Dec',
    ];
}

const generateDate = (startDate, endDate) => {
    const dates = [];
    let currentDate = new Date(startDate);
    let lastDate = new Date(endDate);

    while (currentDate <= lastDate) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
}

const getQualification = () => {
    return [
        'SSLC',
        'PLUS TWO',
        'B.COM',
        'BBA',
        'OTHER DEGREE',
        'POST GRADUATION',
        'OTHER',
    ];
}

const getStatusType = async () => {
    try {
        const response = await apiService.get('status-type/list');
        
        const data = response?.data?.statusType?.map(statusType => statusType.name);
        
        return data;
        
    } catch (error) {
        console.error(error);
    }
}

const getPaymentMethod = () => {
    return [
        'Bank Transfer',
        'Cash',
        'Card',
        'Auto Debit',
        'GQ Loan',
        'Elance EMI',
        'Cheque',
        'CC Avenue ',
        'Qfix ',
        'HDFC Bank Payment Gateway',
        'Adjusted To Refund',
    ];
}

const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
    return result;
}

const payoutPercentageCheck = async (admissionId, categoryObjId) => {}

export {
    generateYearRange,
    generateYears,
    getSessions,
    generateDate,
    getQualification,
    getStatusType,
    getPaymentMethod,
    generateRandomString,
};