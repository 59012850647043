import React, { useState,useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import { useNavigate,useLocation } from "react-router-dom";
import config from '../../../config'; 
import apiService from '../../../utlis/apiService';
import {
  ArrowBackIos as BackButton,
} from '@mui/icons-material';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CreateStudentForm = () => {
  let query = useQuery();
  let id = query.get("id")
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    course:''
  });
  const [courses,setCourses]=useState([])

  useEffect(() => {
    const fetchPaperData = async () => {
      try {
        if (id) {
          const response = await apiService.get('/paper/get', {
            params: {
              id: id,
            },
          });
          console.log(response.data);
          setFormData(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchPaperData();
  }, [id]);

  useEffect(() => {
    const storedFormData = JSON.parse(localStorage.getItem('paperFormData'));
    const storedTimestamp = localStorage.getItem('paperFormDataTimestamp');

    if (storedFormData && isRecent(storedTimestamp)) {
      setFormData(storedFormData);
    }
  }, []);


  const isRecent = (timestamp) => {
    const currentTimestamp = new Date().getTime();
    console.log(currentTimestamp,parseInt(timestamp))
    console.log(currentTimestamp - parseInt(timestamp))
    return currentTimestamp - timestamp < 50000; 
  };

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await apiService.get('/course/list');
        setCourses(response?.data?.course);
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchCourses();
  }, []);

  const [errors, setErrors] = useState({
    name: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      name: '',
    };

    if (!formData.name) {
      newErrors.name = 'Invalid name';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (validateForm()) {
      let murl = id ? `/paper/update?id=${id}` : `/paper/create`;
  
      try {
        const response = await apiService.post(murl, formData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
        navigate(-1);
        console.log(JSON.stringify(response.data));
      } catch (error) {
        alert(error?.response?.data?.error);
        console.error(error);
      }
    }
  };

  const handleChangeCourse = (e, value) => {
    setFormData((prevData) => ({
      ...prevData,
      ['course']: value?._id,
    }));
  };

  const createOnClick=()=>{
    localStorage.setItem('paperFormData', JSON.stringify(formData));
    localStorage.setItem('paperFormDataTimestamp', new Date().getTime());
    navigate('/course/add')
  }

  return (
    <div>
      <h1 style={{ cursor:'pointer', width: 'fit-content' }} onClick={()=>navigate(-1)}>
      <BackButton />
        {`Subjects`}
      </h1>
    <form onSubmit={handleSubmit}>
      <Typography variant="h4" gutterBottom>
       {id ? 'Edit' : 'Create'}  Subject
      </Typography>
      <TextField
        label="Name"
        name="name"
        value={formData.name}
        onChange={handleChange}
        fullWidth
        required
        margin="normal"
        size='small'
      />
        <div style={{textAlign:'right'}}>
          <Typography  gutterBottom style={{fontSize:13,color:'#1876d2',cursor:'pointer'}} onClick={()=>createOnClick()}>
            + create course
          </Typography>
        </div>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          name="course"
          options={courses}
          style={{marginTop:15}}
          getOptionLabel={(option) => option.name}
          value={courses.find((option) => option._id === formData.course) || null}
          onChange={(e, value) => handleChangeCourse(e, value)}
          size='small'
          renderInput={(params) => <TextField {...params} label="Course" size='small' />}
        />

      <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }} size='small'>
       {id ? 'Update Subject' : 'Create Subject' }
      </Button>
 
    </form>
         
    </div>
  );
};

export default CreateStudentForm;
