import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import config from '../../../config';
import apiService from '../../../utlis/apiService';
import {
  ArrowBackIos as BackButton,
} from '@mui/icons-material';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CreateClassForm = () => {
  let query = useQuery();
  let id = query.get('id');
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    location: '',
    floor: '',
    branch: null,
    capacity: '',
  });

  const [branches, setBranches] = useState([]);

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await apiService.get('/branch/list');
        setBranches(response.data.branchs);
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchBranches();
  }, []);
  
  useEffect(() => {
    const fetchClassData = async () => {
      try {
        if (id) {
          const response = await apiService.get('/class/get', {
            params: {
              id: id,
            },
          });
          setFormData(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchClassData();
  }, [id]);

  useEffect(() => {
    const storedFormData = JSON.parse(localStorage.getItem('classFormData'));
    const storedTimestamp = localStorage.getItem('classFormDataTimestamp');

    if (storedFormData && isRecent(storedTimestamp)) {
      setFormData(storedFormData);
    }
  }, []);


  const isRecent = (timestamp) => {
    const currentTimestamp = new Date().getTime();
    console.log(currentTimestamp,parseInt(timestamp))
    console.log(currentTimestamp - parseInt(timestamp))
    return currentTimestamp - timestamp < 50000; 
  };


  const [errors, setErrors] = useState({
    location: '',
    floor: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangeBranch = (e, value) => {
    setFormData((prevData) => ({
      ...prevData,
      ['branch']: value?._id,
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      location: '',
      floor: '',
      branch: '',
      capacity: '',
    };

    if (!formData.branch) {
      newErrors.branch = 'Please select a branch.';
      alert('Please select a branch.');
      isValid = false;
    }

    if(!formData.capacity) {
      newErrors.capacity = 'Please enter capacity.';
      alert('Please enter capacity.');
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      let murl = id ? `${config.apiUrl}/class/update?id=${id}` : `${config.apiUrl}/class/create`;
      try {
        const response = await apiService.post(murl, formData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        localStorage.removeItem('classFormData');
        navigate(-1);
        console.log(JSON.stringify(response.data));
      } catch (error) {
        alert(error?.response?.data?.error);
        console.error(error);
      }
    }
  };

  const createOnClick=()=>{
    localStorage.setItem('classFormData', JSON.stringify(formData));
    localStorage.setItem('classFormDataTimestamp', new Date().getTime());
    navigate('/branch/add')
  }

  return (
    <div>
      <h1 style={{ cursor: 'pointer' }} onClick={() => navigate(-1)}>
      <BackButton />
        {`classes`}
      </h1>
      <form onSubmit={handleSubmit}>
        <Typography variant="h4" gutterBottom>
          {id ? 'Edit' : 'Create'} Class
        </Typography>
        <TextField
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          required
          margin="normal"
          size="small"
        />
        <TextField
          label="Location"
          name="location"
          value={formData.location}
          onChange={handleChange}
          fullWidth
          required
          error={Boolean(errors.location)}
          helperText={errors.location}
          margin="normal"
          size="small"
        />
        <TextField
          label="Floor"
          name="floor"
          value={formData.floor}
          onChange={handleChange}
          fullWidth
          required
          error={Boolean(errors.floor)}
          helperText={errors.floor}
          margin="normal"
          size="small"
        />

        <TextField
          label="Capacity"
          name="capacity"
          value={formData.capacity}
          onChange={handleChange}
          fullWidth
          required
          error={Boolean(errors.capacity)}
          helperText={errors.capacity}
          margin="normal"
          size="small"
        />
        
        <div style={{textAlign:'right'}}>
        <Typography  gutterBottom style={{fontSize:13,color:'#1876d2',cursor:'pointer'}} onClick={()=>createOnClick()}>
          + create branch
        </Typography>
        </div>
    

        {/* Branch Autocomplete */}

        <Autocomplete
          disablePortal
          id="combo-box-demo"
          name="branch"
          options={branches}
          style={{marginTop: 10}}
          getOptionLabel={(option) => option.name}
          value={branches.find((option) => option._id === formData.branch) || null}
          onChange={(e, value) => handleChangeBranch(e, value)} 
          renderInput={(params) => <TextField {...params} label="Branch" required />}
          required
          size="small"
        />

        <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
          {id ? 'Update Class' : 'Create Class'}
        </Button>
      </form>
    </div>
  );
};

export default CreateClassForm;
