import React, { useEffect, useState } from 'react';
import { Typography, Button, TextField, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import apiService from '../../../../utlis/apiService';
import { useUser } from '../../../../context/userContext';

const MyTable = () => {
    const { permissions } = useUser();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalResults, setTotalResults] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 50,
        page: 0,
    });

    const pageOption = [10, 25, 50, 100];
      

    useEffect(() => {
        getData();
    }, [searchTerm, paginationModel]);

    const getData = async () => {
        setLoading(true);
        try {
            const response = await apiService.get('/recordings-admission/list', {
                params: {
                    searchTerm: searchTerm,
                    page: paginationModel.page,
                    pageSize: paginationModel.pageSize,
                },
            });

            setData(response?.data?.recordings);
            setTotalResults(response?.data?.totalResults);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const sendLink = async (id) => {
        const shouldSend = window.confirm('Are you sure you want to send link?');
        if (shouldSend) {
            try {
                await apiService.post('/recordings-admission/send-link', { id: id });
                getData();
            } catch (error) {
                console.error(error);
            }
        }
    };

    function clearLocalStorageKeysWithSubstring(substring) {
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key.includes(substring)) {
                localStorage.removeItem(key);
            }
        }
    }

    const createPress = () => {
        clearLocalStorageKeysWithSubstring('FormData');
        navigate('/dashboard/recordings/application/add');
    };

    return (
        <div>
            <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                <Typography variant="h4" gutterBottom>
                    Recordings Applications
                </Typography>
                {permissions.includes('createApplication') && (
                    <Button variant="contained" color="primary" style={{ marginBottom: '16px' }} onClick={createPress} size='small'>
                        + Create
                    </Button>
                )}
            </div>
            <TextField
                label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                fullWidth
                margin="normal"
                style={{ marginBottom: 10 }}
                size='small'
            />
            {loading ? (
                <CircularProgress style={{ margin: '20px auto', display: 'block' }} />
            ) : (
                <div style={{ height: '70vh', width: '100%' }}>
                    <DataGridPro
                        rows={data}
                        getRowId={(row) => row._id}
                        columns={[
                            { field: 'name', headerName: 'Name', width: 150, valueGetter: (params) => `${params.row?.firstName} ${params.row?.lastName}` },
                            { field: 'email', headerName: 'Email', width: 200 },
                            { field: 'phoneNumber', headerName: 'Phone Number', width: 150 },
                            { field: 'course', headerName: 'Course', width: 100, valueGetter: (params) => params.row?.course?.name },
                            { field: 'statusType', headerName: 'Status Type', width: 100 },
                            { field: 'category', headerName: 'Category', width: 100, valueGetter: (params) => params.row?.category?.name },
                            { field: 'subject', headerName: 'Subject', width: 200 },
                            { field: 'price', headerName: 'Price', width: 100 },
                            { field: 'status', headerName: 'Status', width: 150 },
                            {
                                field: 'actions',
                                headerName: 'Action',
                                width: 200,
                                renderCell: (params) => (
                                    <>
                                        {(params?.row?.status === 'Link Pending' || params?.row?.status === 'Application Pending') ? 
                                            <>
                                                {permissions.includes('editApplication') && (
                                                    <Button variant="outlined" onClick={() => navigate(`/dashboard/recordings/application/add?id=${params.row._id}`)}>Edit</Button>
                                                )}
                                                {permissions.includes('sendApplicationLink') && (
                                                    <Button variant="outlined" style={{ marginLeft: 5 }} onClick={() => sendLink(params.row._id)}>Send Link</Button>
                                                )}
                                            </>
                                        : ''}
                                    </>
                                ),
                            },
                        ]}
                        pagination
                        paginationModel={paginationModel}
                        rowCount={totalResults}
                        paginationMode="server"
                        onPaginationModelChange={setPaginationModel}
                        pageSizeOptions={pageOption}
                    />
                </div>
            )}
        </div>
    );
};

export default MyTable;
