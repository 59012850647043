import { Card, List, ListItem, ListItemText, Typography } from "@mui/material";
import React, { memo } from "react"

const ScheduleListView = memo(({scheduleData}) => {
    return <List style={{marginTop:10,marginBottom:20}}>
        {scheduleData.map(batch => (
            <React.Fragment key={batch._id}>
                <Typography variant="h6">Batch: {batch.name}</Typography>
                {batch.schedule.map((schedule, index) => (
                    <Card sx={{ marginBottom: 2,padding:0,paddingBottom:0  }} key={index} >
                        <ListItem>
                            <ListItemText sx={{ textDecoration: 'none' }} primary={<Typography><b>Faculty</b>: {schedule.faculty}, <b>Class Room</b>: {schedule.classRoomName}, <b>Date</b>: {new Date(schedule.date).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' })}, <b>Time</b>: {(schedule.start === 0 || schedule.start === '0') ? '-' : new Date(schedule.start).toLocaleTimeString('en-GB', { hour: 'numeric', minute: 'numeric', hour12: true })} - {(schedule.end === 0 || schedule.end === '0') ? '-' : new Date(schedule.end).toLocaleTimeString('en-GB', { hour: 'numeric', minute: 'numeric', hour12: true })}</Typography>} />
                        </ListItem>
                    </Card>
                ))}
            </React.Fragment>
        ))}
    </List>
});

export default ScheduleListView