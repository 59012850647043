import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Grid,
  Paper,
  Box,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
} from '@mui/material';
 
import AssignmentIcon from '@mui/icons-material/Assignment';
import GetAppIcon from '@mui/icons-material/GetApp';
import SupportIcon from '@mui/icons-material/Support';
import SchoolIcon from '@mui/icons-material/School';
import PaymentIcon from '@mui/icons-material/Payment';
import { useNavigate } from 'react-router-dom';
import { Notifications as NotificationIcon } from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Logout from '@mui/icons-material/Logout';
import studentApiService from '../utlis/apiStudent';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import BatchIcon from '@mui/icons-material/DynamicFeed';
import Intercom from "@intercom/messenger-js-sdk";
import { show } from '@intercom/messenger-js-sdk';
 
const MyDashboard = () => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
 
    const handleLogout = () => {
      localStorage.removeItem('stoken');
      navigate('/student');
      window.location.reload();
    }
    const getDetails = async () => {
        try {
            const response = await studentApiService.get('/user/get/me');
            
            if(response?.data?.status === 'success'){
                const student = response?.data?.student;
                Intercom({
                  app_id: "asspy0k2",
                    user_id: student._id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
                    name: `${student.firstName} ${student.lastName}`, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
                    email: student.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email address
                    created_at: student.createdAt,
                    hide_default_launcher: true,
                });
            }
      
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const batchRequestAction = () => {
      window.open('https://forms.gle/wVNxKfDsiTA6KKvk8', '_blank');
    }
      
    useEffect(() => {
        getDetails();
    }, []);
 
  return (
    <Container maxWidth="md" sx={{ marginTop: 5 }}>
 
      <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 3 }}
        >
        <Typography variant="h4">DASHBOARD</Typography>
        <IconButton color="primary" onClick={handleClick}>
          <AccountCircleIcon sx={{ fontSize: 30 }} />
        </IconButton>
      </Box>
 
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
        <MenuItem onClick={() => navigate('/student/profile')}>
          <ListItemIcon>
            <PersonOutlineIcon fontSize="small" />
          </ListItemIcon>
          Profile
        </MenuItem>
      </Menu>
  
      <Grid container spacing={3} >
 
        <Grid item xs={6} md={4} sx={{ marginTop: 5,cursor:'pointer' }} onClick={()=>navigate('/student/attendance')}>
          <Paper elevation={3} sx={{
            p: 3,
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            borderRadius: 10,
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#fff',
            transition: 'background-color 0.3s',
            '&:hover': {
              backgroundColor: '#f1f1f1',
            }
          }}>
            <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
              <AssignmentIcon fontSize="large" color="primary" />
            </Box>
            <Typography align="center" variant="subtitle1">
              ATTENDANCE
            </Typography>
          </Paper>
        </Grid>
 
        {/* <Grid item xs={6} md={4} sx={{ marginTop: 5 }} onClick={()=>navigate('/student/materials')}>
          <Paper elevation={3} sx={{
            p: 3,
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            borderRadius: 10,
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#fff',
            transition: 'background-color 0.3s',
            '&:hover': {
              backgroundColor: '#f1f1f1',
            }
          }}>
            <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
              <PersonIcon fontSize="large" color="primary" />
            </Box>
            <Typography align="center" variant="subtitle1">
              PROFILE
            </Typography>
          </Paper>
        </Grid> */}
 
        <Grid item xs={6} md={4} sx={{ marginTop: 5,cursor:'pointer' }} onClick={()=>navigate('/student/materials')}>
          <Paper elevation={3} sx={{
            p: 3,
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            borderRadius: 10,
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#fff',
            transition: 'background-color 0.3s',
            '&:hover': {
              backgroundColor: '#f1f1f1',
            }
          }}>
            <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
              <GetAppIcon fontSize="large" color="primary" />
            </Box>
            <Typography align="center" variant="subtitle1">
              MATERIALS
            </Typography>
          </Paper>
        </Grid>
 
 
        {/* <Grid item xs={6} md={4} sx={{ marginTop: 5,cursor:'pointer' }} onClick={()=>navigate('/student/notification')}>
          <Paper elevation={3} sx={{
            p: 3,
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            borderRadius: 10,
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#fff',
            transition: 'background-color 0.3s',
            '&:hover': {
              backgroundColor: '#f1f1f1',
            }
          }}>
            <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
              <NotificationIcon fontSize="large" color="primary" />
            </Box>
            <Typography align="center" variant="subtitle1">
              NOTIFICATIONS
            </Typography>
          </Paper>
        </Grid> */}

        <Grid item xs={6} md={4} sx={{ marginTop: 5, cursor:'pointer' }} onClick={() => navigate('/student/academics')}>
          <Paper elevation={3} sx={{
            p: 3,
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            borderRadius: 10,
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#fff',
            transition: 'background-color 0.3s',
            '&:hover': {
              backgroundColor: '#f1f1f1',
            }
          }}>
            <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
              <SchoolIcon fontSize="large" color="primary" />
            </Box>
            <Typography align="center" variant="subtitle1">
              ACADEMICS
            </Typography>
          </Paper>
        </Grid>
 
        <Grid item xs={6} md={4} sx={{ marginTop: 10, cursor:'pointer' }} onClick={()=>navigate('/student/payments')}>
          <Paper elevation={3} sx={{
            p: 3,
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            borderRadius: 10,
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#fff',
            transition: 'background-color 0.3s',
            '&:hover': {
              backgroundColor: '#f1f1f1',
            }
          }}>
            <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
              <PaymentIcon fontSize="large" color="primary" />
            </Box>
            <Typography align="center" variant="subtitle1">
              PAYMENTS
            </Typography>
          </Paper>
        </Grid>
 
 
        {/* <Grid item xs={6} md={4} sx={{ marginTop: 10 }}>
          <Paper elevation={3} sx={{
            p: 3,
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            borderRadius: 10,
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#fff',
            transition: 'background-color 0.3s',
            '&:hover': {
              backgroundColor: '#f1f1f1',
            }
          }}>
            <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
              <SchoolIcon fontSize="large" color="primary" />
            </Box>
            <Typography align="center" variant="subtitle1">
              Class Rooms
            </Typography>
          </Paper>
        </Grid> */}
 
        <Grid item xs={6} md={4} sx={{ marginTop: 10,cursor:'pointer' }} onClick={() => batchRequestAction()}>
          <Paper elevation={3} sx={{
            p: 3,
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            borderRadius: 10,
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#fff',
            transition: 'background-color 0.3s',
            '&:hover': {
              backgroundColor: '#f1f1f1',
            }
          }}>
            <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
              <BatchIcon fontSize="large" color="primary" />
            </Box>
            <Typography align="center" variant="subtitle1">
              BATCH REQUEST
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={6} md={4} sx={{ marginTop: 10,cursor:'pointer' }} onClick={() => show()}>
          <Paper elevation={3} sx={{
            p: 3,
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            borderRadius: 10,
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#fff',
            transition: 'background-color 0.3s',
            '&:hover': {
              backgroundColor: '#f1f1f1',
            }
          }}>
            <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
              <SupportIcon fontSize="large" color="primary" />
            </Box>
            <Typography align="center" variant="subtitle1">
              SUPPORT
            </Typography>
          </Paper>
        </Grid>
 
      </Grid>
    </Container>
  );
};
 
export default MyDashboard;