import { Autocomplete, Box, Button, CircularProgress, Container, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Modal, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { DataGridPro } from '@mui/x-data-grid-pro';
import studentApiService from "../../../utlis/apiStudent";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    maxHeight: '80%',
};

const Batches = () => {
    const [data, setData] = useState([]);
    const [paperStatus, setPaperStatus] = useState([]);
    const [papers, setPapers] = useState([]);
    const [faculties, setFaculties] = useState([]);
    const [totalResults, setTotalResults] = useState(0);
    const [filterData, setFilterData] = useState({
        searchTerm: '',
        paper: '',
        faculty: '',
    });
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(0);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 50,
        page: 0,
    });
    const [resitAdmissionModal, setResitAdmissionModal] = useState(false);
    const [resitStatus, setResitStatus] = useState(false);
    const [resitMessage, setResitMessage] = useState(false);
    const [batchId, setBatchId] = useState('');
    const [courseId, setCourseId] = useState('');
    const [resitData, setResitData] = useState({
        fee: {},
        resitPaper: [],
        allPapers: [],
        category: {},
        selectedPaper: '',
    });
    const [loader, setLoader] = useState(false);

    const pageOption = [10, 25, 50, 100];

    useEffect(() => {
        getData();
    }, [filterData, paginationModel]);
    
    const getData = async () => {
        setLoading(true); // Start loading
        try {
            const response = await studentApiService.get('/user/batch', {
                params: {
                    ...filterData,
                    page: paginationModel.page,
                    pageSize: paginationModel.pageSize
                },
            });
    
            const { status, batchs, papers, faculties, paperStatus, totalResults } = response?.data || {};
            setData(batchs || []);
            setPapers(papers);
            setFaculties(faculties);
            setPaperStatus(paperStatus)
            setTotalResults(totalResults || 0);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false); // Stop loading, whether success or error
        }
    }

    const enrollToBatch = async (id, course) => {
        setBatchId(id);
        setCourseId(course);
        try {
            const response = await studentApiService.post('/user/batch/enroll', { batchId: id }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            getData();
        } catch (error) {
            if(error?.response?.status === 402) {
                setResitMessage(error?.response?.data?.message);
                setResitStatus(error?.response?.data?.resitPossible);
                setResitAdmissionModal(true);
                setStep(1);
            }
            else {
                alert(error?.response?.data?.message);
                console.error(error);
            }
        }
    }
    
    const studentEnrolledCheck = (batch) => {
        const studentId = localStorage.getItem('user_id');
        let status = '';
        if(batch.status === 'Open For Enrollment') {
            if(batch.student.includes(studentId))
                status =  'Enrolled';
            else {
                const availableStatus = paperStatus.find(status => batch.statusOptions.includes(status.statusType) && status.papers.includes(batch.paper));
                if(availableStatus) {
                    return false;
                }
                else {
                    return 'Waiting List'
                }
            }
        }
        else {
            status = batch.status;
        }
    
        return status;
    }

    const handleChange = (name, value) => {
        setFilterData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    const addNewAdmission = async () => {
        try {
            const response = await studentApiService.post('/user/get/resit-category', { batchId: batchId }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            setResitData((prevData) => ({
                ...prevData,
                fee: response?.data?.fee,
                resitPaper: response?.data?.resitPaper,
                allPapers: response?.data?.allPapers,
                category: response?.data?.category,
            }));

            setStep(2);
        } catch (error) {
            alert(error?.response?.data?.message);
            console.error(error);
        }
    }

    const closeNewResitAdmission = () => {
        setBatchId('');
        setStep(0);
        setResitData({
            fee: {},
            resitPaper: [],
            allPapers: [],
            category: {},
            selectedPaper: '',
        });
        setResitAdmissionModal(false);
    }

    const paperChange = (e) => {
        setResitData((prevData) => ({
            ...prevData,
            selectedPaper: e.target.value,
        }));
    }

    const payForNewAdmission = async () => {
        try {
            const data = {
                batchId: batchId,
                courseId: courseId,
                categoryId: resitData.category._id,
                amount: resitData.fee.value,
                paperId: [resitData.selectedPaper],
                type: 'Resit Admission',
            }

            setLoader(true);

            const response = await studentApiService.post('/user/payment-initiate', data, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            const paymentLink = response?.data?.payment_links?.web;
            if(paymentLink) {
                window.open(paymentLink, '_self');
            }
            else {
                alert('Something went wrong');
                window.location.reload();
            }

        } catch(err) {
			console.error(err);
			if(err.response?.status === 400) {
				alert(err.response?.data?.message);
			}
		} finally {
            setLoader(false);
        }
    }

    if(loader) {
        return <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center', height: '100vh', width: '100%' }}>
            <CircularProgress style={{ margin: 'auto' }} />
        </div>
    }
    else {
        return (
            <Container maxWidth={'100%'} sx={{ paddingLeft: 0 }} style={{ padding: 0 }}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextField
                            label="Search"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="searchTerm"
                            value={filterData.searchTerm}
                            onChange={(e) => handleChange('searchTerm', e.target.value)}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-faculty"
                            name="faculty"
                            options={faculties}
                            style={{marginTop:15}}
                            getOptionLabel={(option) => option.name}
                            value={faculties.find((option) => option?._id === filterData.faculty) || null}
                            onChange={(e, value) => handleChange('faculty', value?._id)} 
                            renderInput={(params) => <TextField {...params} size="small" label="Faculty" />}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-paper"
                            name="paper"
                            options={papers}
                            style={{marginTop:15}}
                            getOptionLabel={(option) => option.name}
                            value={papers.find((option) => option?._id === filterData.paper) || null}
                            onChange={(e, value) => handleChange('paper', value?._id)} 
                            renderInput={(params) => <TextField {...params} size="small" label="Paper" />}
                        />
                    </Grid>
                </Grid>
                {loading ? (
                    <div style={{ textAlign: 'center' }}>
                        <CircularProgress style={{ margin: '20px auto', display: 'block' }} />
                    </div>
                ) : (
                    <div style={{ height: 350, width: '100%' }}>
                        <DataGridPro
                            rows={data}
                            getRowId={(row) => row._id}
                            columns={[
                                { field: 'name', headerName: 'Name', width: 500 },
                                { field: 'faculty', headerName: 'Faculty', width: 300,valueGetter: (params) => params.row?.faculty?.name },
                                { headerName: 'Remaining Seats', width: 200, valueGetter: (params) => params.row?.limit - params.row?.student?.length },
                                { field: 'startingDate', headerName: 'Starting Date', width: 200,valueGetter: (params) => new Date(params.row?.date).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }) },
                                {
                                    field: 'actions',
                                    headerName: 'Action',
                                    width: 150,
                                    renderCell: (params) => (
                                        <>
                                            {params.row?.limit === params.row?.student?.length ? 'Batch Full' : studentEnrolledCheck(params.row) ? studentEnrolledCheck(params.row) : <Button variant="outlined" onClick={() => enrollToBatch(params.row?._id, params.row?.course)}>Enroll To</Button>}
                                        </>
                                    ),
                                },
                            ]}
                            pagination
                            paginationModel={paginationModel}
                            rowCount={totalResults}
                            paginationMode="server"
                            onPaginationModelChange={setPaginationModel}
                            pageSizeOptions={pageOption}
                        />
                    </div>
                )}

                <Modal
                    open={resitAdmissionModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div>
                            {step === 1 ? 
                                <>
                                    <p>{resitMessage}</p>
                                    <div style={{ textAlign: 'center' }}>
                                        <Button variant="outlined" sx={{ marginRight: '10px' }} onClick={() => setResitAdmissionModal(false)}>Cancel</Button>
                                        {resitStatus ? <Button variant="contained" onClick={addNewAdmission}>Proceed</Button> : ''}
                                    </div>
                                </>
                            :
                            step === 2 ?
                                <>
                                    <div>
                                        <FormControl>
                                            <FormLabel id="demo-radio-buttons-group-label">Choose your course you want to resit</FormLabel>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue="female"
                                                name="radio-buttons-group"
                                            >
                                                {resitData?.allPapers?.map((paper, index) => (
                                                    <FormControlLabel key={index} value={paper?._id} checked={resitData?.selectedPaper === paper?._id} control={<Radio />} onChange={paperChange} disabled={resitData?.resitPaper?.includes(paper?._id)} label={`${paper?.name} ${(resitData?.resitPaper?.includes(paper?._id)) ? '- Resit not possible' : ''}`} />
                                                ))}
                                            </RadioGroup>
                                            <Typography variant="h6" gutterBottom style={{ marginTop: '15px' }}>Course Fee: {resitData?.fee?.value}</Typography>
                                        </FormControl>
                                    </div>
                                    <div>
                                        <Button variant="outlined" color="error" onClick={closeNewResitAdmission} sx={{ marginRight: 1 }}>Cancel</Button>
                                        <Button variant="contained" onClick={payForNewAdmission}>Paynow</Button>
                                    </div>
                                </>
                            :''
                            }
                        </div>
                    </Box>
                </Modal>

            </Container>
        );
    }
}

export default Batches;