import { Autocomplete, Avatar, Box, Button, Checkbox, createTheme, CssBaseline, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, InputLabel, Link, MenuItem, Modal, Paper, Select, Step, StepLabel, Stepper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ThemeProvider, Typography } from "@mui/material";
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { useEffect, useMemo, useRef, useState } from "react";
import { MuiTelInput } from "mui-tel-input";
import axios from "axios";
import config from "../config";
import { useLocation, useNavigate } from "react-router-dom";
import SignatureCanvas from 'react-signature-canvas'

const defaultTheme = createTheme();

const Copyright = (props) => {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://elancelearning.com/">
                Elance Learning
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: '100%',
        md: '30%'
    },
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    maxHeight: '80%',
};

const useQuery = () => {
	const { search } = useLocation();
	return useMemo(() => new URLSearchParams(search), [search]);
}

const RegistrationForm = () => {
    let query = useQuery();
	let id = query.get('id');
	let categoryObjectId = query.get('category');

    const [formData, setFormData] = useState({
        firstName: '',
		lastName: '',
		phoneNumber: '+91',
		parentNumber: '+91',
        whatsappNumber: '+91',
		email: '',
        gender: '',
		address: '',
		country: '',
		state: '',
		city: '',
		pinCode : '',
		qualification: '',
        studentSign: null,
        parentSign: null,
        agreed: false,
        initialRegistration: '',
        registrationNumber: '',
    });
    const [errors, setErrors] = useState({
		firstName: '',
		lastName: '',
		phoneNumber: '',
		parentNumber: '',
		whatsappNumber: '',
        email: '',
		address: '',
		country: '',
		state: '',
		city: '',
		pinCode : '',
		qualification: '',
        studentSign: '',
        parentSign: '',
        agreed: '',
        registrationNumber: '',
	});
    const [signatureModal, setSignatureModal] = useState(false);
    const [signIsParent, setSignIsParent] = useState(false);
    const [signIsStudent, setSignIsStudent] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [steps, setSteps] = useState(3);
    const [studentExist, setStudentExist] = useState(false);
    const [admission, setAdmission] = useState({});
    const [category, setCategory] = useState({});
    const [paymentScheme, setPaymentScheme] = useState([]);
    const [emiAcknowldgement, setEmiAcknowldgement] = useState(false);

    const navigate = useNavigate();
    const singatureRef = useRef();

    useEffect(() => {
        if(id) {
            fetchStudentData();
        }
    }, [id]);

    const fetchStudentData = async () => {
        let murl = `${config.apiUrl}/student/get/registration/details`;
        try {
            const response = await axios.get(murl, {
                params: {
                    id: id,
                }
            });

            setFormData(response?.data);
            response?.data?.admission?.map(admission => {
                admission.category?.map(category => {
                    if(category?._id === categoryObjectId) {
                        setAdmission(admission);
                        setCategory(category);
                        const fee = category?.category?.fee?.find(fee => fee.mode === category.mode);
                        setPaymentScheme(fee.emiSchemes);

                        if(category?.registrationStatus === 'Completed') {
                            setStudentExist(true);
                        }
                        if(category?.registrationStatus !== 'Application Pending'){
                            navigate('/error-404');
                        }
                    }
                });
            });
            
        } catch (error) {
            console.error(error);
            if(error?.response?.status === 404) {
                navigate('/error-404');
            }
        }
    }

    const validateForm = () => {
		let isValid = true;
		const newErrors = {
			firstName: '',
            lastName: '',
            phoneNumber: '',
            parentNumber: '',
            whatsappNumber: '',
            email: '',
            gender: '',
            address: '',
            country: '',
            state: '',
            city: '',
            pinCode : '',
            qualification: '',
            studentSign: '',
            parentSign: '',
            agreed: '',
            registrationNumber: '',
		};

        if(!formData.firstName) {
            newErrors.firstName = 'First Name is required';
			isValid = false;
        }
        if(!formData.lastName) {
            newErrors.lastName = 'Last Name is required';
			isValid = false;
        }
        if(!formData.email) {
            newErrors.email = 'Email is required';
			isValid = false;
        }
        if(!formData.gender) {
            newErrors.gender = 'Gender is required';
			isValid = false;
        }
        if(!formData.address) {
            newErrors.address = 'Email is required';
			isValid = false;
        }
        if(!formData.country) {
            newErrors.country = 'Country is required';
			isValid = false;
        }
        if(!formData.state) {
            newErrors.state = 'State is required';
			isValid = false;
        }
        if(!formData.city) {
            newErrors.city = 'City is required';
			isValid = false;
        }
        if(!formData.pinCode) {
            newErrors.pinCode = 'Pin Code is required';
			isValid = false;
        }
        if(!formData.qualification) {
            newErrors.qualification = 'Qualification is required';
			isValid = false;
        }

        if(!formData.phoneNumber) {
            newErrors.phoneNumber = 'Phone number is required';
			isValid = false;
        }
		else if (!validatePhoneNumber(formData.phoneNumber)) {
			newErrors.phoneNumber = 'Invalid phone number';
			isValid = false;
		}

		if(!formData.parentNumber) {
            newErrors.parentNumber = 'Parent number is required';
			isValid = false;
        }
		else if (!validatePhoneNumber(formData.parentNumber)) {
			newErrors.parentNumber = 'Invalid parent number';
			isValid = false;
		}

        if(!formData.whatsappNumber) {
            newErrors.whatsappNumber = 'Whatsapp number is required';
			isValid = false;
        }
		else if (!validatePhoneNumber(formData.whatsappNumber)) {
			newErrors.whatsappNumber = 'Invalid whatsapp number';
			isValid = false;
		}
        
        if(!formData.studentSign) {
            newErrors.studentSign = 'Student Signature is required';
			isValid = false;
        }

        if(!formData.parentSign) {
            newErrors.parentSign = 'Parent Signature is required';
			isValid = false;
        }

        if(!formData.agreed) {
            newErrors.agreed = `Accept the Elance Learning's terms and conditions`;
			isValid = false;
        }
        
        if(formData.initialRegistration && !formData.registrationNumber) {
            newErrors.registrationNumber = 'Registration Number is required';
			isValid = false;
        }

		setErrors(newErrors);
		return isValid;
	};

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(steps === activeStep) {
            if (validateForm()) {
                let murl = `${config.apiUrl}/student/register`;

                const selectedCategory = {
                    _id: category?._id,
                    category: category?.category?._id,
                    emiScheme: category?.emiScheme,
                    papers: category?.papers,
                }
                
                try {
                    const newFormData = new FormData();
                    newFormData.append('firstName', formData.firstName);
                    newFormData.append('lastName', formData.lastName);
                    newFormData.append('phoneNumber', formData.phoneNumber);
                    newFormData.append('parentNumber', formData.parentNumber);
                    newFormData.append('whatsappNumber', formData.whatsappNumber);
                    newFormData.append('email', formData.email);
                    newFormData.append('gender', formData.gender);
                    newFormData.append('address', formData.address);
                    newFormData.append('country', formData.country);
                    newFormData.append('state', formData.state);
                    newFormData.append('city', formData.city);
                    newFormData.append('pinCode', formData.pinCode);
                    newFormData.append('initialRegistration', formData.initialRegistration);
                    newFormData.append('registrationNumber', formData.registrationNumber);
                    newFormData.append('qualification', formData.qualification);
                    newFormData.append('studentSign', dataURLToBlob(formData.studentSign), 'student-sign.png');
                    newFormData.append('parentSign', dataURLToBlob(formData.parentSign), 'parent-sign.png');
                    newFormData.append('admissionId', admission?._id);
                    newFormData.append('_id', admission?.student);
                    newFormData.append('categoryObj', JSON.stringify(selectedCategory));

                    const response = await axios.post(murl, newFormData, {
                    	headers: {
                    		'Content-Type': 'multipart/form-data',
                    	},
                    });
                    
                    alert('Your registration is complete. Our team will reach out to you soon!');
                    navigate('/student');
                    
                } catch (error) {
                    alert(error?.response?.data?.error);
                    console.error(error);
                }
            }
        }
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleNext = () => {
        if(activeStep === 0 && formData.initialRegistration && !formData.registrationNumber) {
            alert('Registration Number is required');
            return;
        }
        if(activeStep === 1 && category?.papers?.length !== category?.category?.paperLimit) {
            alert(`Select all ${category?.category?.paperLimit} papers from the list`);
            return
        }
        if(activeStep === 2) {
            if(!category.emiScheme) {
                alert(`Please select your payment scheme`);
                return
            }
            else if(category?.emiScheme?.thirdParty && !emiAcknowldgement) {
                alert(`Please accept the payment acknowledgment.`);
                return
            }
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    

    const dataURLToBlob = (dataURL) => {
        const [header, base64] = dataURL.split(',');
        const mime = header.match(/:(.*?);/)[1];
        const binary = atob(base64);
        const array = [];
        for (let i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], { type: mime });
    }

    const handleChange = (e) => {
		const { name, value } = e.target;

		if(name === 'firstName' || name === 'lastName'){
			if(value && !/^[A-Za-z ]+$/.test(value)){
				alert('Accept only letters and spaces!');
			}
			else{
				setFormData((prevData) => ({
					...prevData,
					[name]: value,
				}));
			}
		}
		else {
			setFormData((prevData) => ({
				...prevData,
				[name]: value,
			}));
		}

        setErrors((prevData) => ({
            ...prevData,
            [name]: '',
        }));
	}

    const handlePhoneChange = (value) => {
		setFormData((prevData) => ({
			...prevData,
			phoneNumber: value,
		}));

        setErrors((prevData) => ({
            ...prevData,
            phoneNumber: '',
        }));

		if(validatePhoneNumber(value)){
			handlePhoneDuplicateCheck('student', value);
		}
	}

    const handleWhatsappNumberChange = (value) => {
		setFormData((prevData) => ({
			...prevData,
			whatsappNumber: value,
		}));

        setErrors((prevData) => ({
            ...prevData,
            whatsappNumber: '',
        }));

		if(validatePhoneNumber(value)){
			handlePhoneDuplicateCheck('whatsapp', value);
		}
	}

	const handleParentPhoneChange = (value) => {
        console.log(formData.phoneNumber.replace(/\s/g, ''), value.replace(/\s/g, ''));
        
        if(formData.phoneNumber.replace(/\s/g, '') === value.replace(/\s/g, '')) {
            setErrors((prevData) => ({
                ...prevData,
                parentNumber: 'Phone Number and Parent Number must be different',
            }));
            setFormData((prevData) => ({
                ...prevData,
                parentNumber: '+91',
            }));
            alert('Phone Number and Parent Number must be different');
        }
        else {
            setFormData((prevData) => ({
                ...prevData,
                parentNumber: value,
            }));
            if(validatePhoneNumber(value)){
                handlePhoneDuplicateCheck('parent', value);
            }
            setErrors((prevData) => ({
                ...prevData,
                parentNumber: '',
            }));
        }
	}

    const handlePhoneDuplicateCheck = async (type, number) => {
		try {
		    let data = {};
            let phoneType = '';
            if(type === 'student'){
                phoneType = 'phoneNumber'
                data = {
                    phoneNumber: number,
                }
            }
            else if(type === 'parent'){
                phoneType = 'parentNumber'
                data = {
                    parentNumber: number,
                }
            }
            else {
                phoneType = 'whatsappNumber';
                data = {
                    whatsappNumber: number,
                }
            }
            
            const response = await axios.post(`${config.apiUrl}/student/checkStudentPhoneExist`, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if(response?.data?.status === 'error' && response?.data?.studentPhone === true){
                alert(response?.data?.message);
                setErrors((prevError) => ({
                    ...prevError,
                    [phoneType]: response?.data?.message
                }))
                setFormData({
                    ...formData,
                    phoneNumber: '+91',
                });
            }
            else if(response?.data?.status === 'error'){
                alert(response?.data?.message);
            }
		}
		catch(error) {
			console.error(error);
		}
	}

    const handleinitialRegistration = (e) => {
		setFormData((prevData) => ({
			...prevData,
			initialRegistration: e.target.checked,
		}));
	}

	const validatePhoneNumber = (phoneNumber) => {
		const regex = /^\+\d{1,3}\s\d{5}\s\d{5}$/;
		const regex2 = /^\+\d{1,3}\d{5}\d{5}$/;
		const regex3 = /^\+\d{1,3}\s\d{4}\s\d{4}$/;
		const regex4 = /^\+\d{1,3}\d{4}\d{4}$/;
		return regex.test(phoneNumber) || regex2.test(phoneNumber.replace(/\s/g, '')) || regex3.test(phoneNumber) || regex4.test(phoneNumber);
	}

    const allSubjectSelectedCheck = (papersList) => {
		const papers = papersList?.map(paper => paper._id);

		return papers?.length === category?.papers?.length;
    }

    const allSubjectSelectChange = (e, allPapers) => {
        const { checked } = e.target;
		
		const selectedPapers = allPapers;
		const papers = selectedPapers?.map(paper => paper._id);
		
		if(checked) {
            if(category?.category?.paperLimit < papers.length) {
                alert(`According to your scheme, you are allowed to choose only ${category?.category?.paperLimit} papers. Please select them individually.`);
            }
            else {
                setCategory((prevData) => ({
                    ...prevData,
                    papers: papers
                }));
            }
		}
		else {
			setCategory((prevData) => ({
                ...prevData,
                papers: []
            }));
		}
    }

    const selectedSubjectChange = (e, paperId) => {
        const { checked } = e.target;
        
		if(checked) {
            if(category?.category?.paperLimit < category?.papers?.length + 1) {
                alert(`According to your scheme, you are allowed to choose only ${category?.category?.paperLimit} papers.`);
            }
            else {
                setCategory((prevData) => ({
                    ...prevData,
                    papers: [...prevData.papers, paperId],
                }));
            }
		}
		else {
            setCategory((prevData) => ({
                ...prevData,
                papers: prevData?.papers?.filter(paper => paper !== paperId),
            }));
		}
    }

    const handlePaymentSchemeChange = (scheme) => {
        setCategory((prevData) => ({
            ...prevData,
            emiScheme: scheme?._id,
            selectedPaymentScheme:scheme,
        }));
    }

    const parentSignTrigger = () => {
        setSignatureModal(true);
        setSignIsParent(true);
    }

    const studentSignTrigger = () => {
        setSignatureModal(true);
        setSignIsStudent(true);
    }

    const trimmeCanvas = () => {
        const imageData = singatureRef.current.getTrimmedCanvas().toDataURL('image/png');
        if(signIsParent) {
            setFormData((prevData) => ({
                ...prevData,
                parentSign: imageData,
            }));
            setSignIsParent(false);
            setSignatureModal(false);
            setErrors((prevError) => ({
                ...prevError,
                parentSign: '',
            }));
        }

        if(signIsStudent) {
            setFormData((prevData) => ({
                ...prevData,
                studentSign: imageData,
            }));
            setSignIsStudent(false);
            setSignatureModal(false);
            setErrors((prevError) => ({
                ...prevError,
                studentSign: '',
            }));
        }
    }

    const emiTermsChange = (e) => {
        setEmiAcknowldgement(e.target.checked);
    }

    const PaymentSplit = () => {
        const paymentScheme = category.selectedPaymentScheme;
        const totalFee = category.totalFee;

        const saleDiscount  = category.discount?.reduce((sum, dis) => sum + parseFloat((dis.value === null || dis.value === '' || isNaN(dis.value)) ? 0 : dis.value), 0);
        const paymentDiscount = Boolean(paymentScheme?.discount) ? Math.round((totalFee - saleDiscount) * (paymentScheme?.discount / 100)) : 0;

        const totalDiscount = saleDiscount + paymentDiscount;
        const additionalCharge = Boolean(paymentScheme?.additionalCharge) ? Math.round((totalFee  - totalDiscount) * (paymentScheme?.additionalCharge / 100)) : 0;
        
        const totalPayableAmount = totalFee + additionalCharge - totalDiscount;

        const initialPayment = paymentScheme?.downPayment ? Math.round(totalPayableAmount * (paymentScheme?.downPayment / 100)) : 0;

        return <div style={{ border: '1px solid', padding: '10px', marginTop: '20px' }}>
            <Grid container spacing={2} alignItems="center">
                {additionalCharge ?
                    <Grid item xs={12}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={5}>Additional Charges: </Grid>
                            <Grid item xs={7}>{Math.round(additionalCharge)}</Grid>
                        </Grid>
                    </Grid>
                : ''}
                {paymentDiscount ?
                    <Grid item xs={12}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={5}>Payment Discount: </Grid>
                            <Grid item xs={7}>{Math.round(paymentDiscount)}</Grid>
                        </Grid>
                    </Grid>
                : ''}
                {Number(paymentScheme?.downPayment) === 100 ?
                    <Grid item xs={12}>
                        <div style={{ textAlign: 'justify', textJustify: 'inter-word'}}>
                            <strong>Note: </strong>The initial payment shall be due upon the starting date of the first allocated batch, with subsequent installments payable, if any, at monthly intervals thereafter <br /><br />
                        </div>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={5}>Net Course Fee Payable: </Grid>
                            <Grid item xs={7}>{Math.round(totalPayableAmount)}</Grid>
                        </Grid>
                    </Grid>
                : 
                    <>
                        <Grid item xs={12}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={5}>Net Course Fee Payable: </Grid>
                                <Grid item xs={7}>{Math.round(totalPayableAmount)}</Grid>
                            </Grid>
                        </Grid>
                        {paymentScheme?.month?.length ? 
                            <Grid item xs={12}>
                                <div style={{ textAlign: 'justify', textJustify: 'inter-word'}}>
                                    <strong>Note: </strong>The initial payment shall be due upon the starting date of the first allocated batch, with subsequent installments payable, if any, at monthly intervals thereafter <br /><br />
                                </div>
                                <Typography sx={{ fontWeight: 'bold' }}>Installments</Typography>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={5}>Initial Payment: </Grid>
                                    <Grid item xs={7}>{Math.round(initialPayment)}</Grid>
                                </Grid>
                            </Grid>
                        : ''}
                        {paymentScheme?.month?.map((month, index) => (
                            <Grid item xs={12}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={5}>Payment {index + 2}: </Grid>
                                    <Grid item xs={7}>{Math.round(totalPayableAmount * (month?.value / 100))}</Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </>
                }
            </Grid>
        </div>
    }

    const termsChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            agreed: e.target.checked,
        }));

        setErrors((prevError) => ({
            ...prevError,
            agreed: e.target.checked ? '' : `Accept the Elance Learning's terms and conditions parent sign`,
        }));
    }

    const totalDiscount = (category?.discount === null || category?.discount?.length === 0) ? 0 : category?.discount?.reduce((sum, dis) => sum + parseFloat((dis.value === '' || isNaN(dis.value)) ? 0 : dis.value), 0);

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container component="main" sx={{ marginTop: '20px', paddingBottom: '20px', height: '100vh', display:'flex', justifyContent:'center', alignItems:'center' }}>
                <CssBaseline />
                <Grid item xs={11} sm={6} md={4} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            borderRadius:8
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <AppRegistrationIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">Register</Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <Stepper activeStep={activeStep} alternativeLabel>
                                <Step>
                                    <StepLabel>Basic Details</StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>Course Details</StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>Payment Details</StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>Terms & Conditions</StepLabel>
                                </Step>
                            </Stepper>

                            {activeStep === 0 ? 
                                <>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="First Name"
                                                name="firstName"
                                                value={formData.firstName}
                                                onChange={handleChange}
                                                fullWidth
                                                required
                                                margin="normal"
                                                error={Boolean(errors.firstName)}
                                                helperText={errors.firstName}
                                                size="small"
                                                disabled={Boolean(studentExist)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Last Name"
                                                name="lastName"
                                                value={formData.lastName}
                                                onChange={handleChange}
                                                fullWidth
                                                required
                                                margin="normal"
                                                error={Boolean(errors.lastName)}
                                                helperText={errors.lastName}
                                                size="small"
                                                disabled={Boolean(studentExist)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <MuiTelInput
                                                label="Phone Number"
                                                name="phoneNumber"
                                                value={formData.phoneNumber}
                                                onChange={handlePhoneChange}
                                                fullWidth
                                                required
                                                error={Boolean(errors.phoneNumber)}
                                                helperText={errors.phoneNumber}
                                                margin="normal"
                                                size="small"
                                                disabled={Boolean(studentExist)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <MuiTelInput 
                                                label="Whatsapp Number"
                                                name="whatsappNumber"
                                                value={formData.whatsappNumber}
                                                onChange={handleWhatsappNumberChange}
                                                fullWidth
                                                required
                                                error={Boolean(errors.whatsappNumber)}
                                                helperText={errors.whatsappNumber}
                                                margin="normal"
                                                size="small"
                                                disabled={Boolean(studentExist)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <MuiTelInput 
                                                label="Parent Number"
                                                name="parentNumber"
                                                value={formData.parentNumber}
                                                onChange={handleParentPhoneChange}
                                                fullWidth
                                                required
                                                error={Boolean(errors.parentNumber)}
                                                helperText={errors.parentNumber}
                                                margin="normal"
                                                size="small"
                                                disabled={Boolean(studentExist)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                fullWidth
                                                required
                                                type="email"
                                                margin="normal"
                                                error={Boolean(errors.email)}
                                                helperText={errors.email}
                                                size="small"
                                                disabled={Boolean(studentExist)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <FormControl fullWidth style={{ marginTop: '20px'}} error={Boolean(errors.gender)} size="small">
                                                <InputLabel id="Gender">Gender</InputLabel>
                                                <Select
                                                    label="Gender"
                                                    name="gender"
                                                    value={formData.gender}
                                                    onChange={handleChange}
                                                    fullWidth
                                                    required
                                                    size="small"
                                                    disabled={Boolean(studentExist)}
                                                >
                                                    <MenuItem value="male">Male</MenuItem>
                                                    <MenuItem value="female">Female</MenuItem>
                                                    <MenuItem value="other">Other</MenuItem>
                                                </Select>
                                                {errors.gender ? <FormHelperText>{errors.gender}</FormHelperText> :''}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Qualification"
                                                name="qualification"
                                                value={formData.qualification}
                                                onChange={handleChange}
                                                fullWidth
                                                required
                                                margin="normal"
                                                error={Boolean(errors.qualification)}
                                                helperText={errors.qualification}
                                                size="small"
                                                disabled={Boolean(studentExist)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <TextField
                                        label="Address"
                                        name="address"
                                        value={formData.address}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        margin="normal"
                                        error={Boolean(errors.address)}
                                        helperText={errors.address}
                                        size="small"
                                        disabled={Boolean(studentExist)}
                                    />
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Country"
                                                name="country"
                                                value={formData.country}
                                                onChange={handleChange}
                                                fullWidth
                                                required
                                                margin="normal"
                                                error={Boolean(errors.country)}
                                                helperText={errors.country}
                                                size="small"
                                                disabled={Boolean(studentExist)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="State"
                                                name="state"
                                                value={formData.state}
                                                onChange={handleChange}
                                                fullWidth
                                                required
                                                margin="normal"
                                                error={Boolean(errors.state)}
                                                helperText={errors.state}
                                                size="small"
                                                disabled={Boolean(studentExist)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="City"
                                                name="city"
                                                value={formData.city}
                                                onChange={handleChange}
                                                fullWidth
                                                required
                                                margin="normal"
                                                error={Boolean(errors.city)}
                                                helperText={errors.city}
                                                size="small"
                                                disabled={Boolean(studentExist)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Pin code"
                                                name="pinCode"
                                                value={formData.pinCode}
                                                onChange={handleChange}
                                                fullWidth
                                                required
                                                margin="normal"
                                                error={Boolean(errors.pinCode)}
                                                helperText={errors.pinCode}
                                                size="small"
                                                disabled={Boolean(studentExist)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={6}>
                                            <FormGroup sx={{ width: 'fit-content'}}>
                                                <FormControlLabel control={<Checkbox checked={formData.initialRegistration} onChange={(e) => handleinitialRegistration(e)} />} label="Initial Registration Done" />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Registration Number"
                                                name="registrationNumber"
                                                value={formData.registrationNumber}
                                                onChange={handleChange}
                                                fullWidth
                                                type='text'
                                                error={Boolean(errors.registrationNumber)}
                                                helperText={errors.registrationNumber}
                                                margin="normal"
                                                size='small'
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            : activeStep === 1 ?
                                <>
                                    <Autocomplete
										id="Course-autocomplete"
										options={[admission?.course]}
										getOptionLabel={(option) => option?.name}
										value={admission?.course}
										renderInput={(params) => <TextField {...params} label={'Course *'} />}
										sx={{ marginTop: '15px' }}
										required
                                        disabled={true}
										size='small'
									/>
                                    <Autocomplete
										id="Category-autocomplete"
										options={[category.category]}
										getOptionLabel={(option) => option?.name}
										value={category.category}
										renderInput={(params) => <TextField {...params} label={'Category *'} />}
										sx={{ marginTop: '15px' }}
										required
                                        disabled={true}
										size='small'
									/>
                                    <FormControl fullWidth style={{ marginTop: '10px'}}>
										<TextField
											label="Status Type"
											select
											value={category?.statusType}
											fullWidth
											name="statusType"
											margin="normal"
											required
											size='small'
                                            disabled={true}
										>
											{[category?.statusType]?.map((type, statusTypeIndex) => (
												<MenuItem key={statusTypeIndex} value={type}>{type}</MenuItem>
											))}
										</TextField>
									</FormControl>
                                    <Typography sx={{ fontWeight: 'Bold', marginTop: '5px' }}>Select Required Papers</Typography>
                                    <TableContainer component={Paper} style={{ marginTop: '10px', marginBottom: '10px' }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        <FormControlLabel control={<Checkbox checked={allSubjectSelectedCheck(category?.category?.papers)} onChange={(e) => allSubjectSelectChange(e, category?.category?.papers)} />} />
                                                    </TableCell>
                                                    <TableCell>Name</TableCell>
                                                    {/* <TableCell>Action</TableCell> */}
                                                </TableRow>
                                            </TableHead> 
                                            <TableBody>
                                                {category?.category?.papers?.map((paper) => (
                                                    <TableRow key={paper._id}>
                                                        <TableCell>
                                                            <FormGroup sx={{ width: 'fit-content'}}>
                                                                <FormControlLabel control={<Checkbox checked={category?.papers?.includes(paper?._id)} onChange={(e) => selectedSubjectChange(e, paper?._id)} />} />
                                                            </FormGroup>
                                                        </TableCell>
                                                        <TableCell>{paper.name}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </>
                            : activeStep === 2 ?
                                <>
                                    <div style={{ textAlign: 'justify', textJustify: 'inter-word', paddingTop: '20px', paddingBottom: '20px'}}>
                                        <strong>Note: </strong> The initial payment shall be due upon the starting date of the first allocated batch, with subsequent installments payable at monthly intervals thereafter
                                    </div>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={5}>Admission Fee: </Grid>
                                        <Grid item xs={7}>{Math.round(category?.admissionFee)}</Grid>
                                    </Grid>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={5}>Course Fee: </Grid>
                                        <Grid item xs={7}>{Math.round(category?.totalFee)}</Grid>
                                    </Grid>
                                    {category?.discount?.map((row, index) => {
                                        if(row.discountType !== 'Instant Discount') {
                                            return (
                                            <Grid container spacing={2} alignItems="center" key={index}>
                                                <Grid item xs={5}>Sales Discount: </Grid>
                                                <Grid item xs={7}>{Math.round(row?.value)}</Grid>
                                            </Grid>);
                                        }
                                    })}
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={5}>Gross Course Fee Payable: </Grid>
                                        <Grid item xs={7}>{Math.round(category?.totalFee - totalDiscount)}</Grid>
                                    </Grid>
                                    <Typography sx={{ marginTop: '10px' }}>Choose Your Payment Scheme</Typography>
                                    <Autocomplete
										id="PaymentScheme-autocomplete"
										options={paymentScheme}
										getOptionLabel={(option) => option?.name}
										value={category?.selectedPaymentScheme}
										renderInput={(params) => <TextField {...params} label={'Payment Scheme *'} />}
										sx={{ marginTop: '15px' }}
										required
										size='small'
                                        onChange={(e, scheme) => handlePaymentSchemeChange(scheme)}
									/>
                                    {category?.selectedPaymentScheme?.thirdParty ? 
                                        <div style={{ textAlign: 'justify', textJustify: 'inter-word'}}>
                                            <Checkbox onChange={emiTermsChange} checked={emiAcknowldgement} /> 
                                            I acknowledge that Elance, or any authorized third party associated with Elance, may contact me to facilitate installment arrangements. I agree to promptly provide the necessary KYC documents to process the application. <br /><br />
                                            I understand that the approved EMI tenure will be shorter than the course duration. <br /><br />
                                            I accept that loan approval is not guaranteed and is subject to factors such as my CIBIL score, co-applicant status, and monthly income. In the event of rejection or partial approval, I agree to cooperate with Elance to finalize a revised payment schedule.
                                        </div>
                                    :
                                        <>
                                            <PaymentSplit />
                                        </>
                                    }
                                </>
                            :
                                <>
                                    <div>
                                        <Checkbox onChange={termsChange} checked={formData.agreed} /> I Agree to <a color="inherit" href="https://elancelearning.com/" target="_blank">{`Elance Learning's `}</a> <a href="https://elancelearning.com/student-policy/" target="_blank"> Terms and Conditions.</a>
                                        {errors.agreed ? <FormHelperText error={true}>{errors.agreed}</FormHelperText> : ''}
                                    </div>
                                    
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12} md={6}>
                                            {formData.parentSign && 
                                            <img
                                                src={formData.parentSign}
                                                alt="parent sign"
                                                loading="lazy"
                                                style={{ maxHeight: '100px'}}
                                            />}
                                            <Button
                                                type="button"
                                                fullWidth
                                                variant="outlined"
                                                sx={{ mt: 3, mb: 2 }}
                                                onClick={parentSignTrigger}
                                            >
                                                Parent Sign
                                            </Button>
                                            {errors.parentSign ? <FormHelperText error={true}>{errors.parentSign}</FormHelperText> :''}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {formData.studentSign && 
                                            <img
                                                src={formData.studentSign}
                                                alt="parent sign"
                                                loading="lazy"
                                                style={{ maxHeight: '100px' }}
                                            />}
                                            <Button
                                                type="button"
                                                fullWidth
                                                variant="outlined"
                                                sx={{ mt: 3, mb: 2 }}
                                                onClick={studentSignTrigger}
                                            >
                                                Student Sign
                                            </Button>
                                            {errors.studentSign ? <FormHelperText error={true}>{errors.studentSign}</FormHelperText> :''}
                                        </Grid>
                                    </Grid>
                                </>
                            }

                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Button
                                    type="button"
                                    color="inherit"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{ mr: 1 }}
                                >
                                    Back
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />
                                {steps === activeStep ?
                                    <Button type="submit" disabled={Boolean(!formData.agreed) || Boolean(!formData.parentSign) || Boolean(!formData.studentSign)} sx={{ mr: 1 }}>
                                        Submit
                                    </Button>
                                :
                                    <Button type="button" onClick={handleNext} sx={{ mr: 1 }}>
                                        Next
                                    </Button>
                                }
                            </Box>
                            <Copyright sx={{ mt: 5 }} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <Modal
                open={signatureModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{ border: '1px solid #000000', marginBottom: '20px' }}>
                        <SignatureCanvas ref={singatureRef} penColor='black' canvasProps={{width: 500, height: 200, className: 'sigCanvas'}} />
                    </div>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={4} md={3}>
                            <Button
                                type="button"
                                variant="contained"
                                onClick={() => {setSignatureModal(false); setSignIsParent(false); setSignIsStudent(false);}}
                            >
                                Close
                            </Button>
                        </Grid>
                        <Grid item xs={8} md={5}>
                            <Button
                                type="button"
                                variant="outlined"
                                onClick={() => {singatureRef.current.clear()}}
                                sx={{ marginRight: '10px'}}
                            >
                                Clear
                            </Button>
                            <Button
                                type="button"
                                variant="outlined"
                                onClick={() => trimmeCanvas()}
                            >
                                Save
                            </Button>
                        </Grid>
                        <Grid item xs={0} md={4}></Grid>
                    </Grid>
                </Box>
            </Modal>
        </ThemeProvider>
    );
}

export default RegistrationForm;