import React, { useEffect, useState } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Autocomplete, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import apiService from '../../../utlis/apiService';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';

export default function ActivityLogs() {
    const [data, setData] = useState([]);
    const [totalResults, setTotalResults] = useState(0);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 50,
        page: 0,
    });

    const pageOption = [10, 25, 50, 100];

    useEffect(() => {
        getScheduleData();
    },[paginationModel]);

    const getScheduleData = async() => {
        try {
            const logResponse = await apiService.get('/report/getActivityLogs', {
                params: {
                    page: paginationModel.page,
                    pageSize: paginationModel.pageSize,
                },
            });

            setData(logResponse?.data?.activityLogs);
            setTotalResults(logResponse?.data?.totalResults);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div style={{ height: '75vh', width: '100%' }}>
            <Typography variant="h4" gutterBottom>
                ACTIVITY LOGS
            </Typography>
            
            <DataGridPro
                getRowId={(row) => row._id}
                rows={data}
                disableDensitySelector
                columns={[
                    {   field: 'timestamp', 
                        headerName: 'Date', 
                        flex: 1,
                        valueFormatter: (params) => new Date(params.value).toLocaleDateString('en-GB', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })
                    },
                    { field: 'type', headerName: 'Section', flex: 1 },
                    { field: 'user', headerName: 'User Email', flex: 1, valueGetter: (params) => params?.row?.userId?.email },
                    { field: 'action', headerName: 'Action', flex: 1, valueGetter: (params) => params?.row?.details?.action },
                    { field: 'name', headerName: 'Section Title', flex: 1, valueGetter: (params) => params?.row?.details?.details?.name ? params?.row?.details?.details?.name : params?.row?.details?.details?.email },
                ]}
                pagination
                paginationModel={paginationModel}
                rowCount={totalResults}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                pageSizeOptions={pageOption} 
            />
        </div>
    );
}
