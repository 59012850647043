import { Box, Button, Container, Typography } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { Link } from "react-router-dom";

const styles = {
    error: {
        boxShadow: '0 15px 25px #00000019',
        padding: '45px',
        textAlign: 'center',
        margin: '40px auto',
        borderBottom: 'solid 4px #cc0000',
    },
    errorIcon: {
        fontSize: '55px',
        color: '#cc0000',
    },
    errorHeading: {
        marginBottom: '12px',
        marginTop: '10px',
    },
    errorSubHeading: {
        marginBottom: '0px',
        fontSize: {
            md: '18px',
            xs: '12px',
        },
        color: '#495057',
        fontWeight: 500,
    },
    backIcon: {
        fontSize: '30px',
        color: '#cc0000',
    },
}

const InternalPaymentError = () => {
    return (
        <Container maxWidth={'100%'} sx={{ paddingLeft: 0 }} style={{ padding: 0 }}>
            <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Box sx={{ alignSelf: 'center' }}>
                    <Box sx={styles.error}>
                        <CancelIcon sx={styles.errorIcon} />
                        <Typography variant="h4" sx={styles.errorHeading}> Your payment was pending </Typography>
                        <Typography sx={styles.errorSubHeading}> Your payment has not been confirmed by the merchant. <br /> Kindly reach out to support! </Typography> 
                        <Link to="/student"><Button type="button" color="error"><KeyboardReturnIcon sx={styles.backIcon} /> Go To Home</Button></Link> 
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}

export default InternalPaymentError;