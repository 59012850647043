import React, { useEffect, useState } from 'react';
import { Typography, Button, TextField, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DataGridPro } from '@mui/x-data-grid-pro';
import apiService from '../../../utlis/apiService';
import { useUser } from '../../../context/userContext';

const MyTable = () => {
    const { permissions } = useUser();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalResults, setTotalResults] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 50,
        page: 0,
    });

    const pageOption = [10, 25, 50, 100];
      

    useEffect(() => {
        getData();
    }, [searchTerm, paginationModel]);

    const getData = async () => {
        setLoading(true);
        try {
            const response = await apiService.get('/referrer/list', {
                params: {
                    searchTerm: searchTerm,
                    page: paginationModel.page,
                    pageSize: paginationModel.pageSize,
                },
            });

            setData(response?.data?.referrer);
            setTotalResults(response?.data?.totalResults);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const markAsPaid = async (row) => {
        const data = {
            referrerId: row.referrerId,
            _id: row._id,
        }

        try {
            const response = await apiService.post('/referrer/update', data);
            getData();
            alert(response?.data?.message);
        }
        catch (error) {
            console.error(error);
        }
    }

    return (
        <div>
            <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                <Typography variant="h4" gutterBottom>
                    Referrers
                </Typography>
            </div>
            <TextField
                label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                fullWidth
                margin="normal"
                style={{ marginBottom: 10 }}
                size='small'
            />
            {loading ? (
                <CircularProgress style={{ margin: '20px auto', display: 'block' }} />
            ) : (
                <div style={{ height: '70vh', width: '100%' }}>
                    <DataGridPro
                        rows={data}
                        getRowId={(row) => row?._id}
                        columns={[
                            { field: 'sno', headerName: 'S.No', width: 10, renderCell:(params) => params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) + 1 },
                            { field: 'name', headerName: 'Name', flex: 1 },
                            { field: 'referrerNo', headerName: 'Referrer Number', flex: 1 },
                            { field: 'studentName', headerName: 'Student Name', flex: 1 },
                            { field: 'studentId', headerName: 'Student Id', flex: 1 },
                            { field: 'category', headerName: 'Category', flex: 1 },
                            { field: 'status', headerName: 'Status', flex: 1 },
                            { field: 'ifsc', headerName: 'IFSC', flex: 1 },
                            { field: 'accountNo', headerName: 'A/C No', flex: 1 },
                            { field: 'amount', headerName: 'Amount', flex: 1 },
                            {
                                field: 'actions',
                                headerName: 'Action',
                                width: 150,
                                renderCell: (params) => (
                                    <>
                                        {permissions.includes('markReferrerAsPaid') && params?.row?.status !== 'Paid' && (
                                            <Button variant="outlined" onClick={() => markAsPaid(params?.row)}>Mark as Paid</Button>
                                        )}
                                    </>
                                ),
                            },
                        ]}
                        pagination
                        paginationModel={paginationModel}
                        rowCount={totalResults}
                        paginationMode="server"
                        onPaginationModelChange={setPaginationModel}
                        pageSizeOptions={pageOption}
                    />
                </div>
            )}
        </div>
    );
};

export default MyTable;
