import React, { useEffect, useState } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Autocomplete, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import apiService from '../../../utlis/apiService';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { useUser } from '../../../context/userContext';
import { useNavigate } from 'react-router-dom';

export default function ScheduleReport() {
    const { permissions } = useUser();
    const [data, setData] = useState([]);
    const [batches, setBatches] = useState([]);
    const [classes, setClasses] = useState([]);
    const [branches, setBranches] = useState([]);
    const [faculties, setFaculties] = useState([]);
    const [mentors, setMentors] = useState([]);
    const [coordinator, setCoordinator] = useState([]);
    const [totalResults, setTotalResults] = useState(0);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 50,
        page: 0,
    });
    const [filterData, setFilterData] = useState({
        dateFrom: null,
        dateTo: null,
        classRoom: '',
        batch: '',
        branch: '',
        faculty: '',
        mentor: '',
        coordinator: '',
        status: '',
    });
    const [loading, setLoading] = useState(false);

    const pageOption = [10, 25, 50, 100];

    const navigate = useNavigate();

    useEffect(() => {
        getScheduleData();
    },[filterData, paginationModel]);

    useEffect(() => {
        getData();
    },[]);

    const getScheduleData = async() => {
        setLoading(true);
        try {
            const scheduleResponse = await apiService.get('/report/schedule', {
                params: {
                    ...filterData,
                    page: paginationModel.page,
                    pageSize: paginationModel.pageSize,
                },
            });

            setData(scheduleResponse?.data?.schedule);
            setTotalResults(scheduleResponse?.data?.totalResults);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    const getData = async () => {
        try {
            const batcheResponse = await apiService.get('/batch/list');
            const classResponse = await apiService.get('/class/list');
            const branchResponse = await apiService.get('/branch/list');
            const mentorResponse = await apiService.get('/mentor/list');
            const facultyResponse = await apiService.get('/faculty/list');
            const coordinatorResponse = await apiService.get('/coordinator/list');

            setBatches(batcheResponse?.data?.batchs);
            setClasses(classResponse?.data?.classRoom);
            setBranches(branchResponse?.data?.branchs);
            setMentors(mentorResponse?.data?.mentor);
            setFaculties(facultyResponse?.data?.faculty);
            setCoordinator(coordinatorResponse?.data?.coordinator);
        } catch (error) {
            console.error(error);
        }
    };

    const handleChangeSubmit = (e, value, name) => {
        setFilterData((prevData) => ({
          ...prevData,
          [name]: value?._id,
        }));
    };

    const handleChange = (name, value) => {
        setFilterData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
    };

    const navigateToBatch = (params) => {
        if(permissions.includes('updateBatch') && params.field !== 'actions') {
            navigate(`/dashboard/batch/add?id=${params?.row?.batch?._id}`);
        }
    }

    const CustomeTime = (scheduleProp) => {
        const schedule = scheduleProp.value;

        const start = new Date(schedule?.start).toLocaleTimeString('en-GB', { hour12: true, hour: 'numeric', minute: 'numeric' });
        const end = new Date(schedule?.end).toLocaleTimeString('en-GB', { hour12: true, hour: 'numeric', minute: 'numeric' });

        return start + ' - ' + end;
    }

    return (
        <div style={{ height: '75vh', width: '100%' }}>
            <Typography variant="h4" gutterBottom>
                SCHEDULE REPORT
            </Typography>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={4}>
                    <InputLabel id="Date From">Date From</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
                        <FormControl className='gap-2 mb-8' fullWidth>
                            <MobileDatePicker
                                value={filterData.dateFrom}
                                onChange={(date) => handleChange('dateFrom', date)}
                                sx={{ marginTop: 1 }}
                                format="DD/MM/YYYY"
                                slotProps={{
                                    actionBar: {
                                      actions: ['clear', 'cancel', 'accept'],
                                    },
                                }}
                            />
                        </FormControl>
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={4}>
                    <InputLabel id="Date To">Date To</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
                        <FormControl className='gap-2 mb-8' fullWidth>
                            <MobileDatePicker
                                value={filterData.dateTo}
                                onChange={(date) => handleChange('dateTo', date)}
                                sx={{ marginTop: 1 }}
                                format="DD/MM/YYYY"
                                slotProps={{
                                    actionBar: {
                                      actions: ['clear', 'cancel', 'accept'],
                                    },
                                }}
                            />
                        </FormControl>
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-class"
                        name="classRoom"
                        options={classes}
                        style={{ marginTop: 30 }}
                        getOptionLabel={(option) => option.name}
                        value={classes.find((option) => option._id === filterData.classRoom) || null}
                        onChange={(e, value) => handleChangeSubmit(e, value, 'classRoom')}
                        renderInput={(params) => <TextField {...params} label="Class" />}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={4}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-branch"
                        name="branch"
                        options={branches}
                        style={{ marginTop: 15 }}
                        getOptionLabel={(option) => option.name}
                        value={branches.find((option) => option._id === filterData.branch) || null}
                        onChange={(e, value) => handleChangeSubmit(e, value, 'branch')}
                        renderInput={(params) => <TextField {...params} label="Branch" />}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-batch"
                        name="batch"
                        options={batches}
                        style={{ marginTop: 15 }}
                        getOptionLabel={(option) => option.name}
                        value={batches.find((option) => option._id === filterData.batch) || null}
                        onChange={(e, value) => handleChangeSubmit(e, value, 'batch')}
                        renderInput={(params) => <TextField {...params} label="Batch" />}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-mentor"
                        name="faculty"
                        options={faculties}
                        style={{ marginTop: 15 }}
                        getOptionLabel={(option) => option.name}
                        value={faculties.find((option) => option._id === filterData.faculty) || null}
                        onChange={(e, value) => handleChangeSubmit(e, value, 'faculty')}
                        renderInput={(params) => <TextField {...params} label="Faculty" />}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center" sx={{ marginBottom: 5}}>
                <Grid item xs={4}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-mentor"
                        name="mentor"
                        options={mentors}
                        style={{ marginTop: 15 }}
                        getOptionLabel={(option) => option.name}
                        value={mentors.find((option) => option._id === filterData.mentor) || null}
                        onChange={(e, value) => handleChangeSubmit(e, value, 'mentor')}
                        renderInput={(params) => <TextField {...params} label="Mentor" />}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-coordinator"
                        name="coordinator"
                        options={coordinator}
                        style={{ marginTop: 15 }}
                        getOptionLabel={(option) => option.name}
                        value={coordinator.find((option) => option._id === filterData.coordinator) || null}
                        onChange={(e, value) => handleChangeSubmit(e, value, 'coordinator')}
                        renderInput={(params) => <TextField {...params} label="Coordinator" />}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth sx={{ marginTop: '15px' }}>
                        <InputLabel id="Status">Status</InputLabel>
                        <Select
                            label="Status"
                            value={filterData.status}
                            onChange={(e) => handleChange('status', e.target.value)}
                            fullWidth
                            name="status"
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value="active">Active</MenuItem>
                            <MenuItem value="completed">Completed</MenuItem>
                            <MenuItem value="canceled">Canceled</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {loading ? (
                <div style={{ textAlign: 'center' }}>
                    <CircularProgress style={{ margin: 'auto' }} />
                </div>

            ) : (
                <DataGridPro
                    getRowId={(row) => row._id}
                    rows={data}
                    onCellClick={navigateToBatch}
                    disableDensitySelector
                    columns={[
                        {
                            field: 'date', 
                            headerName: 'Date', 
                            width: 150, 
                            valueFormatter: (params) => new Date(params.value).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' })
                        },
                        { field: 'class', headerName: 'Class', flex: 1, valueGetter: (params) => params?.row?.classRoom?.name },
                        { field: 'branch', headerName: 'Branch', flex: 1, valueGetter: (params) => params?.row?.batch?.branch?.name },
                        { field: 'batch', headerName: 'Batch', flex: 1, valueGetter: (params) => params?.row?.batch?.name },
                        { field: 'faculty', headerName: 'Faculty', flex: 1, valueGetter: (params) => params?.row?.batch?.faculty?.name },
                        { field: 'mentor', headerName: 'Mentor', flex: 1, valueGetter: (params) => params?.row?.batch?.mentor?.name },
                        { field: 'time', headerName: 'Time', flex: 1, renderCell: (params) => <CustomeTime value={params.row} /> },
                        { field: 'classBy', headerName: 'Class By', flex: 1 },
                        { field: 'status', headerName: 'Status', flex: 1 },
                        { field: 'remark', headerName: 'Remark', flex: 1 },
                        {
                            field: 'actions',
                            headerName: 'Action',
                            flex: 1,
                            renderCell: (params) => (
                                <>
                                    {permissions.includes('readSchedule') && (
                                        <Button variant="outlined" onClick={() => navigate(`/dashboard/schedule/view?id=${params.row._id}`)}>View Schedule</Button>
                                    )}
                                </>
                            ),
                        },
                    
                    ]}
                    pagination
                    paginationModel={paginationModel}
                    rowCount={totalResults}
                    paginationMode="server"
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={pageOption} 
                />
            )}
        </div>
    );
}
