import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import config from '../../../config';
import apiService from '../../../utlis/apiService';
import { ArrowBackIos as BackButton } from '@mui/icons-material';
import {
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,

} from '@mui/material';


function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CreateUserForm = () => {
  let query = useQuery();
  let id = query.get('id');
  const navigate = useNavigate();

  const [roles,setRoles]=useState([])


  const [formData, setFormData] = useState({
    name: '',
    email:'',
    role:'',
    password:''
  });


  useEffect(() => {
    if (id) {
      const fetchUserData = async () => {
        try {
          const response = await apiService.get(`/admin/get?id=${id}`);
          console.log(response.data);
          setFormData({
            name: response.data.name,
            email: response.data.email,
            role: response.data.role.name,
            // password: response.data.password
          });
        } catch (error) {
          console.error(error);
        }
      };
  
      fetchUserData();
    }
  }, [id]);
  
  useEffect(() => {
    getRoles();
  }, []);
  
  const getRoles = async () => {
    try {
      const response = await apiService.get(`/admin/roles`);
      // console.log(response?.data)
      setRoles(response?.data)
    } catch (error) {
      console.error(error);
    }
  };

  const [errors, setErrors] = useState({
    name: '',
    email: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const validateEmail = (email) => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      name: '',
      email: '',
    };

    if (!validateEmail(formData.email)) {
      newErrors.email = 'Invalid email';
      isValid = false;
    }

    if(!formData.name){
      newErrors.name = 'Name is required!';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(formData)
 
    if (validateForm()) {
      const apiUrl = id ? `${config.apiUrl}/admin/update?id=${id}` : `${config.apiUrl}/admin/createUser`;
      try {
        const response = await apiService.post(apiUrl, formData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        navigate(-1);
        console.log(JSON.stringify(response.data));
      } catch (error) {
        alert(error?.response?.data?.error);
        console.error(error);
      }
    }
  };



  return (
    <div>
      <h1 style={{ cursor: 'pointer' }} onClick={() => navigate(-1)}>
        <BackButton />
        {`Users`}
      </h1>
      <form onSubmit={handleSubmit}>
        <Typography variant="h4" gutterBottom>
          {id ? 'Edit' : 'Create'} User
        </Typography>

        <TextField
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          required
          error={Boolean(errors.name)}
          helperText={errors.name}
          margin="dense"
          size="small"
        />
  
        <TextField
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          fullWidth
          required
          error={Boolean(errors.email)}
          helperText={errors.email}
          margin="dense"
          size="small"
        />

        <TextField
          label="Password"
          name="password"
          type="password"
          required={!id} 
          value={formData.password}
          onChange={handleChange}
          fullWidth
          margin="dense"
          size="small"
        />
   
        <FormControl fullWidth size="small" sx={{ marginTop: '10px' }}>
          <InputLabel id="Role">Role</InputLabel>
          <Select
            label="Role"
            name="role"
            value={formData.role}
            onChange={handleChange}
            fullWidth
            margin="dense"
            size="small"
          >
          {roles.map(role => (
                <MenuItem value={role.name}>{role.name}</MenuItem>
          ))}
          </Select>
        </FormControl>


        <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
          {id ? 'Update User' : 'Create User'}
        </Button>
      </form>

    </div>
  );
};

export default CreateUserForm;
