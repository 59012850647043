import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, styled, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro'; // Import DataGridPro components
import apiService from '../../../utlis/apiService';
import { useUser } from '../../../context/userContext';

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  '& .student-theme--Drop': {
    color: '#ad343a',
    '&:hover': {
      color: '#c9121b',
    },
  },
}));

const MyTable = () => {
  const { permissions } = useUser();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalResults, setTotalResults] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 50,
    page: 0,
  });
  const pageOption = [10, 25, 50, 100];

  useEffect(() => {
    getData();
  }, [searchTerm, paginationModel]);

  let navigate = useNavigate();

  // const handlePageChange = (newPage) => {
  //   setPage(newPage);
  // };

  const getData = async () => {
    setLoading(true);
    try {
      const response = await apiService.get(`/student/list`, {
        params: {
          searchTerm: searchTerm,
          page: paginationModel.page,
          pageSize: paginationModel.pageSize,
        },
      });

      setData(response?.data?.students);
      setTotalResults(response?.data?.totalResults);

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteItem = (id) => {
    const shouldDelete = window.confirm('Are you sure you want to delete this item?');
    if (shouldDelete) {
      deleServer(id);
    }
  }

  const deleServer = async (mid) => {
    try {
      const response = await apiService.post(`/student/delete?id=${mid}`);
      getData();
    } catch (error) {
      console.error(error);
    }
  };

  function clearLocalStorageKeysWithSubstring(substring) {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.includes(substring)) {
        localStorage.removeItem(key);
      }
    }
  }

  const createPress = () => {
    clearLocalStorageKeysWithSubstring("FormData");
    navigate('/dashboard/students/add');
  }

  const navigateToEdit = (params) => {
    if(permissions.includes('updateStudent') && params.field !== 'actions') {
      navigate(`/dashboard/students/view?id=${params?.row?._id}`);
    }
  }

  return (
    <div>
      <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
        <Typography variant="h4" gutterBottom>STUDENTS</Typography>
        {permissions.includes('createStudent') && <Button variant="contained" color="primary" style={{ marginBottom: '16px' }} onClick={() => createPress()}>
          + Create
        </Button>}
      </div>
      <TextField
        label="Search"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        fullWidth
        margin="normal"
        style={{ marginBottom: 10 }}
        size='small'
      />
      {loading ? (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress style={{ margin: 'auto' }} />
        </div>

      ) : (
        <div style={{ height: '70vh', width: '100%' }}>
          <StyledDataGrid
            rows={data}
            getRowId={(row) => row._id}
            onCellClick={navigateToEdit}
            getRowClassName={(params) => `student-theme--${params.row.status}`}
            columns={[
              { field: 'studentId', headerName: 'ID', flex: 1 },
              { field: 'createdAt', headerName: 'Date', flex: 1, valueFormatter: (params) => new Date(params.value).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }) },
              { field: 'name', headerName: 'Name', flex: 1, valueGetter: (params) => params.row?.firstName + ' ' + params.row?.lastName  },
              { field: 'phoneNumber', headerName: 'Phone Number', flex: 1 },
              { field: 'parentNumber', headerName: 'Parent Phone', flex: 1 },
              { field: 'status', headerName: 'Status', flex: 1 },
              { field: 'gender', headerName: 'Gender', flex: 1 },
              {
                field: 'actions',
                headerName: 'Actions',
                width: 250,
                renderCell: (params) => (
                  <div>
                    {permissions.includes('readStudent') && (<Button variant="outlined" onClick={() => navigate(`/dashboard/students/view?id=${params.row._id}`)} >View</Button>)}
                    {permissions.includes('updateStudent') && (<Button variant="outlined" style={{ marginLeft: 5 }} onClick={() => navigate(`/dashboard/students/add?id=${params.row._id}`)} >Edit</Button>)}
                    {permissions.includes('deleteStudent') && (<Button variant="outlined" color="error" style={{ marginLeft: 5 }} onClick={() => deleteItem(params.row._id)}>Delete</Button>)}
                  </div>
                ),
              },
            ]}
            pagination
            paginationModel={paginationModel}
            rowCount={totalResults}
            paginationMode="server"
            components={{ Toolbar: GridToolbar }} // Add GridToolbar for filtering
            onPaginationModelChange={setPaginationModel}
            rowsPerPageOptions={pageOption} 
          />
        </div>
      )}

    </div>
  );
};

export default MyTable;
