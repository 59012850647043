import React, { useEffect, useState } from 'react';
import { Typography, Button, TextField, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro'; // Import GridToolbar
import apiService from '../../../utlis/apiService';
import { useUser } from '../../../context/userContext';

const MyTable = () => {
    const { permissions } = useUser();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalResults, setTotalResults] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 50,
        page: 0,
    });
    const pageOption = [10, 25, 50, 100];
    

    useEffect(() => {
        getData();
    }, [searchTerm, paginationModel]);

    const getData = async () => {
        setLoading(true);
        try {
            const response = await apiService.get('/class/list', {
                params: {
                    searchTerm: searchTerm,
                    page: paginationModel.page,
                    pageSize: paginationModel.pageSize,
                },
            });
            setData(response?.data?.classRoom);
            setTotalResults(response?.data?.totalResults);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const deleteItem = async (id) => {
        const shouldDelete = window.confirm('Are you sure you want to delete this item?');
        if (shouldDelete) {
            try {
                await apiService.post(`/class/delete?id=${id}`);
                getData();
            } catch (error) {
                console.error(error);
            }
        }
    };

    const createPress = () => {
        clearLocalStorageKeysWithSubstring('FormData');
        navigate('/dashboard/class/add');
    };

    const clearLocalStorageKeysWithSubstring = (substring) => {
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key.includes(substring)) {
                localStorage.removeItem(key);
            }
        }
    };

    return (
        <div>
            <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                <Typography variant="h4" gutterBottom>
                    CLASS ROOMS
                </Typography>
                {permissions.includes('createClassroom') && (
                    <Button variant="contained" color="primary" style={{ marginBottom: '16px' }} onClick={createPress}>
                        + Create
                    </Button>
                )}
            </div>
            <TextField
                label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                fullWidth
                margin="normal"
                style={{ marginBottom: 10 }}
                size="small"
            />
            {loading ? (
                <CircularProgress style={{ margin: '20px auto', display: 'block' }} />
            ) : (
                <div style={{ height: '70vh', width: '100%' }}>
                    <DataGridPro
                        rows={data}
                        getRowId={(row) => row._id}
                        columns={[
                            { field: 'name', headerName: 'Name', flex: 1 },
                            { field: 'branch', headerName: 'Branch', flex: 1, valueGetter: (params) => params.row?.branch?.name },
                            { field: 'location', headerName: 'Location', flex: 1 },
                            { field: 'floor', headerName: 'Floor', flex: 1 },
                            { field: 'capacity', headerName: 'Capacity', flex: 1 },
                            {
                                field: 'actions',
                                headerName: 'Action',
                                flex: 1,
                                renderCell: (params) => (
                                    <>
                                        {permissions.includes('updateClassroom') && (
                                            <Button variant="outlined" onClick={() => navigate(`/dashboard/class/add?id=${params.row._id}`)}>
                                                Edit
                                            </Button>
                                        )}
                                        {permissions.includes('deleteClassroom') && (
                                            <Button variant="outlined" color="error" style={{ marginLeft: 5 }} onClick={() => deleteItem(params.row._id)}>
                                                Delete
                                            </Button>
                                        )}
                                    </>
                                ),
                            },
                        ]}
                        pagination
                        paginationModel={paginationModel}
                        rowCount={totalResults}
                        paginationMode="server"
                        components={{ Toolbar: GridToolbar }} // Add GridToolbar for filtering
                        onPaginationModelChange={setPaginationModel}
                        rowsPerPageOptions={pageOption}
                    />
                </div>
            )}
        </div>
    );
};

export default MyTable;
