import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import HomePage from './pages/home';
import Login from './loginForm';

const ReferrerApp = () => {
  const rToken = localStorage.getItem('rtoken');

  return (
    <div>
      <Routes>
        <Route path="/" element={rToken ? (<Navigate replace to={'home'} />) : (<Login />)} />
        <Route path="/home" element={<HomePage />} />
      </Routes>
    </div>
  );
};

export default ReferrerApp;