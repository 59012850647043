import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button, CircularProgress, Grid, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import apiService from '../../../utlis/apiService';
import { useUser } from '../../../context/userContext';

const MyTable = () => {
    const { permissions, userId } = useUser();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); // State for loading
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalResults, setTotalResults] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        getData();
    }, [searchTerm, page, pageSize]);

    let navigate = useNavigate();

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const getData = async () => {
        try {
            setLoading(true); // Start loading
            const response = await apiService.get(`/admin/list`, {
                params: {
                    searchTerm: searchTerm
                }
            });
            console.log(response.data);
            setData(response?.data);
            // setTotalResults(response?.data?.totalResults);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false); // Stop loading, whether success or error
        }
    };

    const deleServer = async (mid) => {
        try {
            const response = await apiService.post(`/admin/delete?id=${mid}`);
            getData();
        } catch (error) {
            console.error(error);
        }
    };

    const deleteItem = (id) => {
        const shouldDelete = window.confirm('Are you sure you want to delete this item?');
        if (shouldDelete) {
            deleServer(id);
        }
    };

    function clearLocalStorageKeysWithSubstring(substring) {
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key.includes(substring)) {
                localStorage.removeItem(key);
            }
        }
    }

    const createPress = () => {
        clearLocalStorageKeysWithSubstring("FormData");
        navigate('/dashboard/users/add');
    };

    return (
        <div>
            <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                <Typography variant="h4" gutterBottom>USERS LIST</Typography>
                {permissions.includes('createUser') && (
                    <Button variant="contained" color="primary" style={{ marginBottom: '16px' }} onClick={createPress} size='small'>
                        + Create
                    </Button>
                )}
            </div>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                    <TextField
                        label="Name / Email"
                        margin="normal"
                        name="name"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        fullWidth
                        size="small"
                    />
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                {loading ? ( // Show loader if loading
                    <CircularProgress style={{ margin: '20px auto', display: 'block' }} />
                ) : (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="subtitle1" fontWeight="bold">Email</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle1" fontWeight="bold">Role</Typography>
                                </TableCell>
                                {permissions.some(permission => ['updateUser', 'deleteUser'].includes(permission)) && (
                                    <TableCell>
                                        <Typography variant="subtitle1" fontWeight="bold">ACTION</Typography>
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>{row.email}</TableCell>
                                    <TableCell>{row?.role?.name}</TableCell>
                                    {permissions.some(permission => ['updateUser', 'deleteUser'].includes(permission)) && (
                                        <TableCell>
                                            {(row?.role?.name !== 'superadmin' || userId !== row._id) && permissions.includes('updateUser') && (
                                                <Button variant="outlined" onClick={() => navigate(`/dashboard/users/add?id=${row._id}`)}>Edit</Button>
                                            )}
                                            {(row?.role?.name !== 'superadmin' || userId !== row._id) && permissions.includes('deleteUser') && (
                                                <Button variant="outlined" color="error" style={{ marginLeft: 5 }} onClick={() => deleteItem(row._id)}>Delete</Button>
                                            )}
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </TableContainer>
        </div>
    );
};

export default MyTable;
