import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate, useLocation } from 'react-router-dom';
import apiService from '../../../utlis/apiService';
import { ArrowBackIos as BackButton } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';

import { 
  FormControl,
  FormLabel,
  List,
  Card,
  CardContent,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
  InputLabel
} from '@mui/material';
import AdvancedFormat from 'dayjs/plugin/advancedFormat'; // Import the advancedFormat plugin
import 'dayjs/locale/en'; 

dayjs.extend(AdvancedFormat);

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ViewScheduleForm = (props) => {
  let query = useQuery();
  let id = query.get('id');
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    classRoom: '',
    batch: props?.batchId ?? '',
    date: null,
    start: null,
    end: null,
    topics:[],
    status: '',
    classBy: '',
    remark: '',
  });

  const [batches, setBatches] = useState([]);
  const [topics,setTopics] = useState([]);
  const [students, setStudents] = useState([]);

  const [selectedTopics,setSelectedTopics] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [classes,setClasses] = useState([]);
  const [subject, setSubject] = useState(props?.subjectId ?? '');

  const filteredStudents = students.filter((student) =>
    (student?.student?.firstName.toLowerCase().includes(searchTerm.toLowerCase()) || 
    student?.student?.lastName.toLowerCase().includes(searchTerm.toLowerCase()))
  );


  useEffect(() => {
    if (id) {
      const fetchScheduleData = async () => {
        try {
          const response = await apiService.get(`/schedule/get?id=${id}`);
  
          setFormData(response.data);
          setSelectedTopics(response?.data?.topics);
          setStudents(response?.data?.attendance?.students);
          setSubject(response?.data?.batch?.paper);
        } catch (error) {
          console.error(error);
        }
      };
  
      fetchScheduleData();
    }
  }, [id]);


  useEffect(() => {
    const storedFormData = JSON.parse(localStorage.getItem('paperFormData'));
    const storedTimestamp = localStorage.getItem('paperFormDataTimestamp');

    if (storedFormData && isRecent(storedTimestamp)) {
      setFormData(storedFormData);
    }
  }, []);

  const isRecent = (timestamp) => {
    const currentTimestamp = new Date().getTime();
    return currentTimestamp - timestamp < 50000;
  };

  
useEffect(() => {
  const fetchData = async () => {
    try {
      const batchesResponse = await apiService.get('/batch/list');
      const classResponse = await apiService.get('/class/list');

      const batchList = batchesResponse?.data?.batchs;
      const activeBatchList = batchList.filter(batch => batch);
      const classs = classResponse?.data?.classRoom;
      setBatches(activeBatchList);
      setClasses(classs);
    } catch (error) {
      console.error(error);
    }
  };

  fetchData();
}, []);

useEffect(() => {
  if(subject)
    getTopics();
}, [subject]);

const getTopics = async () => {
  const topicsResponse = await apiService.get(`/topic/listBySubject?subjectId=${subject}`);
  setTopics(topicsResponse?.data?.topics);
}


  return (
    <div>
      {props && props.from === 'batch' ?'':
      <h1 style={{ cursor: 'pointer' }} onClick={() => navigate(-1)}>
        <BackButton />
        {`Schedules`}
      </h1>}

      <form >
        <Typography variant="h4" gutterBottom>
          View Schedule
        </Typography>

        <Autocomplete
          disablePortal
          id="combo-box-batch"
          name="batch"
          options={batches}
          style={{ marginTop: 15 }}
          getOptionLabel={(option) => option.name}
          value={batches.find((option) => option._id === formData.batch) || null}
          disabled={true}
          renderInput={(params) => <TextField {...params} label="Batch" />}
        />
    
      <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
        <FormControl className='gap-2 mb-8' fullWidth style={{ marginTop: 10 }}>
          <MobileDatePicker
            label="Date"
            value={formData?.date ? dayjs(formData?.date) : null}
            disabled={true}
            sx={{ marginTop: 1 }}
            format="DD/MM/YYYY"
            slotProps={{
              textField: {
                size: 'small'
              }
            }}
          />
        </FormControl>
      </LocalizationProvider>
    
    <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
      <FormControl className='gap-2 mb-8' fullWidth style={{ marginTop: 10 }}>
        <MobileTimePicker
          label="Start Time"
          value={formData?.start ? dayjs(formData?.start) : null}
          disabled={true}
          ampm={true}
          sx={{ marginTop: 1 }}
          format='HH:mm'
          slotProps={{
            textField: {
              size: 'small'
            }
          }}
        />
      </FormControl>
    </LocalizationProvider>

    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormControl className='gap-2 mb-8' fullWidth style={{ marginTop: 10 }}>
        <MobileTimePicker
          label="End Time"
          value={formData?.end ? dayjs(formData?.end) : null}
          disabled={true}
          format='HH:mm'
          ampm={true}
          sx={{ marginTop: 1 }}
          slotProps={{
            textField: {
              size: 'small'
            }
          }}
        />
      </FormControl>
    </LocalizationProvider>

    <Autocomplete
      disablePortal
      id="combo-box-demo"
      name="classRoom"
      options={classes}
      style={{marginTop:15}}
      getOptionLabel={(option) => option.name}
      value={classes.find((option) => option._id === formData.classRoom) || null}
      disabled={true}
      renderInput={(params) => <TextField {...params} label="Add Class Room" />}
      size="small"
    />

      <FormControl fullWidth sx={{ marginTop: '15px' }} size="small">
        <InputLabel id="Select Status">Select Status</InputLabel>
        <Select
          size="small"
          label="Select Status"
          value={formData.status}
          disabled={true}
          fullWidth
          name="status"
        >
          <MenuItem value="active">Active</MenuItem>
          <MenuItem value="completed">Completed</MenuItem>
          <MenuItem value="canceled">Canceled</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ marginTop: '15px' }} size="small">
        <InputLabel id="Class Taken By">Class Taken By</InputLabel>
        <Select
          label="Class Taken By"
          value={formData.classBy}
          disabled={true}
          fullWidth
          name="classBy"
          size="small"
        >
          <MenuItem value="Faculty">Faculty</MenuItem>
          <MenuItem value="Mentor">Mentor</MenuItem>
        </Select>
      </FormControl>
        
        <Autocomplete
          multiple
          id="topic-autocomplete"
          options={topics}
          getOptionLabel={(option) => `Name : ${option.name} | Hours: ${option.hours} | Paper : ${option?.paper?.name} | Course : ${option?.paper?.course?.name}` }
          value={selectedTopics}
          disabled={true}
          renderInput={(params) => <TextField {...params} label={'Topics'} />}
          sx={{ marginTop: '15px' }}
          size="small"
        />

      <TextField
        label="Remark"
        name="remark"
        value={formData.remark}
        fullWidth
        margin="normal"
        disabled={true}
        size="small"
      />
    
        
          <div style={{marginTop:20}}>
            <Typography variant="h5" gutterBottom>
              Student List  | Total : {students?.length} | Absent : {students.filter(student => student.status === 'absent')?.length} | Present {students.filter(student => student.status === 'present')?.length}
            </Typography>
            <TextField
              label="Search"
              variant="outlined"
              fullWidth
              margin="normal"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              size="small"
            />
            <List style={{marginTop:10,marginBottom:20}}>
     
              {filteredStudents.map((student, index) => (
                <Card key={student?._id} sx={{ marginBottom: 2,padding:0,paddingBottom:0  }} >
                  <CardContent >
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography
                            sx={{
                              textDecoration: student.status == 'present'? 'none' : 'line-through',
                              color: student.status== 'present' ? 'inherit' : 'red',
                            }}
                          >
                            {`${student?.student?.firstName} ${student?.student?.lastName}`}
                          </Typography>
                        }
                      />
                      <ListItemSecondaryAction>
                        {student?.status == 'absent' && (
                          <TextField
                            label="Remarks"
                            style={{ padding: 0 }}
                            value={student?.remarks}
                            disabled={true}
                            size="small"
                          />
                        )}
                        <Checkbox
                          checked={student?.status == 'present'}
                          disabled={true}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  </CardContent>
                </Card>
              ))}
            </List>
          </div>
      
      </form>
    </div>
  );
};

export default ViewScheduleForm;
