import React, { useState,useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate,useLocation } from "react-router-dom";
import config from '../../../config'; 
import apiService from '../../../utlis/apiService';
import {
  ArrowBackIos as BackButton,
} from '@mui/icons-material';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CreateStudentForm = () => {
  let query = useQuery();
  let id = query.get("id")
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    hours:'',
    paper:'',
    course: '',
  });
  const [papers, setPapers] = useState([]);
  const [courses, setCourses] = useState([]);


  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getPapersByCourse(formData.course);
  },[formData.course]);

  const getPapersByCourse=async(cid)=>{
    try {
      const response = await apiService.get(`/paper/paperbycourse?id=${cid}`);
      console.log(response.data);
      setPapers(response.data)
    } catch (error) {
      console.error(error);
    }
  }

  const getData = async () => {
    try {
      const response = await apiService.get('/course/list', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      setCourses(response?.data?.course);
    } catch (error) {
      console.error(error);
    }
  };
  
  useEffect(() => {
    if (id) {
      const fetchStudentData = async () => {
        try {
          const response = await apiService.get(`/topic/get?id=${id}`);
          console.log(response.data);
          setFormData(response.data);
        } catch (error) {
          console.error(error);
        }
      };
  
      fetchStudentData();
    }
  }, [id]);

  const [errors, setErrors] = useState({
    name: '',
    hour:'',
    paper:'',
    course: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      name: '',
      paper:'',
      hours:'',
      course: ''
    };

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (validateForm()) {
      let murl = id ? `/topic/update?id=${id}` : `/topic/create`;
  
      try {
        const response = await apiService.post(murl, formData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
        navigate(-1);
        console.log(JSON.stringify(response.data));
      } catch (error) {
        alert(error?.response?.data?.error);
        console.error(error);
      }
    }
  };

  const handleChangePaper =(e,value)=>{
    setFormData((prevData) => ({
      ...prevData,
      ['paper']: value?._id,
    }));
  }

  const handleChangeCourse = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      course: value?._id,
    }));
  };

  return (
    <div>
      <h1 style={{ cursor:'pointer', width: 'fit-content' }} onClick={()=>navigate(-1)}>
      <BackButton />
        {`Topics`}
      </h1>
    <form onSubmit={handleSubmit}>
      <Typography variant="h4" gutterBottom>
       {id ? 'Edit' : 'Create'}  Topic
      </Typography>
      <TextField
        label="Name"
        name="name"
        value={formData.name}
        onChange={handleChange}
        fullWidth
        required
        margin="normal"
        size='small'
      />
      <TextField
        label="Hours"
        name="hours"
        value={formData.hours}
        onChange={handleChange}
        fullWidth
        required
        type='number'
        error={Boolean(errors.hours)}
        helperText={errors.hours}
        margin="normal"
        size='small'
      />

      <Autocomplete
        disablePortal
        id="course"
        name="course"
        options={courses}
        style={{marginTop:15}}
        getOptionLabel={(option) => option.name}
        value={courses.find((option) => option._id === formData.course) || null}
        onChange={(e, value) => handleChangeCourse(value)} 
        size='small'
        renderInput={(params) => <TextField {...params} label="Course" size='small' />}
      />

      <Autocomplete
        disablePortal
        id="combo-box-demo"
        name="paper"
        options={papers}
        style={{marginTop:15}}
        getOptionLabel={(option) => option.name}
        value={papers.find((option) => option._id === formData.paper) || null}
        onChange={(e, value) => handleChangePaper(e, value)} 
        size='small'
        renderInput={(params) => <TextField {...params} label="Subject" size='small' />}
      />

      <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }} size='small'>
       {id ? 'Update Topic' : 'Create Topic' }
      </Button>
 
    </form>
         
    </div>
  );
};

export default CreateStudentForm;
