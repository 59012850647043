import React, { useState,useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import apiService from '../../../utlis/apiService';
import {
  ArrowBackIos as BackButton,
} from '@mui/icons-material';
import { Grid } from '@mui/material';

const SettingsForm = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    _id: '',
    referralPayout: '',
    referralPayoutAfter: '',
    studentFacultyAttendance: '',
    studentMentorAttendance: '',
  });

  useEffect(() => {
    const fetchPaperData = async () => {
      try {
        const response = await apiService.get('/settings/get');
        setFormData(response?.data?.settings);
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchPaperData();
  }, []);


  const [errors, setErrors] = useState({
    referralPayout: '',
    referralPayoutAfter: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      referralPayout: '',
      referralPayoutAfter: '',
    };

    if (!formData.referralPayout) {
      newErrors.referralPayout = 'Invalid Referral Payout';
      isValid = false;
    }

    if (!formData.referralPayoutAfter) {
      newErrors.referralPayoutAfter = 'Invalid Referral Payout After';
      isValid = false;
    }

    if (!formData.studentFacultyAttendance) {
      newErrors.studentFacultyAttendance = 'Invalid Faculty Attendance Criteria';
      isValid = false;
    }

    if (!formData.studentMentorAttendance) {
      newErrors.studentMentorAttendance = 'Invalid Mentor Attendance Criteria';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (validateForm()) {
      let murl = `/settings/update`;
  
      try {
        const response = await apiService.post(murl, formData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
        console.log(JSON.stringify(response.data));
      } catch (error) {
        alert(error?.response?.data?.error);
        console.error(error);
      }
    }
  };

  return (
    <div>
      <h1 style={{ cursor:'pointer', width: 'fit-content' }} onClick={()=>navigate(-1)}>
      <BackButton />
        {`Subjects`}
      </h1>
    <form onSubmit={handleSubmit}>
      <Typography variant="h4" gutterBottom>Settings</Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6} sx={{ paddingTop: 0 }}>
          <TextField
            label="Referral Payout After %"
            name="referralPayout"
            value={formData.referralPayoutAfter}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
            size='small'
            error={Boolean(errors.referralPayoutAfter)}
            helperText={errors.referralPayoutAfter}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Referral Payout %"
            name="referralPayout"
            value={formData.referralPayout}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
            size='small'
            error={Boolean(errors.referralPayout)}
            helperText={errors.referralPayout}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6} sx={{ paddingTop: 0 }}>
          <TextField
            label="Faculty Attendance Criteria For Re-sit %"
            name="studentFacultyAttendance"
            value={formData.studentFacultyAttendance}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
            size='small'
            error={Boolean(errors.studentFacultyAttendance)}
            helperText={errors.studentFacultyAttendance}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Mentor Attendance Criteria For Re-sit %"
            name="studentMentorAttendance"
            value={formData.studentMentorAttendance}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
            size='small'
            error={Boolean(errors.studentMentorAttendance)}
            helperText={errors.studentMentorAttendance}
          />
        </Grid>
      </Grid>
      <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>Update</Button>
 
    </form>
         
    </div>
  );
};

export default SettingsForm;
