import { useState, useEffect, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate, useLocation } from 'react-router-dom';
import apiService from '../../../utlis/apiService';
import { ArrowBackIos as BackButton } from '@mui/icons-material';
import Students from './getStudents'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat'; // Import the advancedFormat plugin
import 'dayjs/locale/en';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ActivityLogs from './log'
import { useUser } from '../../../context/userContext';
import { generateYearRange, getSessions, getStatusType } from '../../../utlis/commonFunction';

dayjs.extend(AdvancedFormat);

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const CreatebatchForm = () => {
  let query = useQuery();
  let id = query.get("id")
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    nameTail: '',
    branch:'',
    course:'',
    mentor:'',
    faculty:'',
    coordinator: '',
    year:'',
    paper:'',
    limit: '',
    date: null,
    status: '',
    statusOptions: [],
    mode: '',
    session: '',
  });
  const [papers, setPapers] = useState([])
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState('');
  const [branchs, setBranchs] = useState([]);
  const [courses, setCourses] = useState([]);
  const [mentors, setMentors] = useState([]);
  const [faculties, setFaculties] = useState([]);
  const [coordinator, setCoordinator] = useState([]);
  const [statusType, setStatusType] = useState([]);
  const [errors, setErrors] = useState({
    name: '',
  });

  const years = generateYearRange();
  const sessions = getSessions();

  const nameExceptionSubjects = [
    'Business and Technology (BT)',
    'Management Accounting (MA)',
    'Financial Accounting (FA)',
    'Corporate and Business Law (LW)',
    'Financial Reporting (FR)',
    'Financial Management (FM)',
    'Taxation (TX)',
    'Performance Management (PM)'
  ]

  const { permissions } = useUser();
  
  const fetchbatchData = async () => {
    try {
      const response = await apiService.get(`/draft-batch/get`, {
        params: {
          id: id,
        },
      });
  
      setFormData({ ...response?.data, student: [], exam: []});
      setSelectedStudents(response?.data?.student || []);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchStatusTypeData = async () => {
		const statusTypeData = await getStatusType();
		setStatusType(statusTypeData);
	}
  
  useEffect(() => {
    if (id) {
      
      fetchbatchData();
    }
  }, [id]);

  useEffect(() => {
    getAllData();
    fetchStatusTypeData();
  }, []);

  useEffect(() => {
    const storedFormData = JSON.parse(localStorage.getItem('batchFormData'));
    const storedTimestamp = localStorage.getItem('batchFormDataTimestamp');

    if (storedFormData && isRecent(storedTimestamp)) {
      setFormData(storedFormData);
    }
  }, []);


  useEffect(()=>{
    if (formData.course){
      getPapersByCourse(formData.course)
    }
  },[formData.course]);

  const isRecent = (timestamp) => {
    const currentTimestamp = new Date().getTime();
    return currentTimestamp - timestamp < 50000; 
  };

  useEffect(() => {
    if(papers.length > 0) {
      const paperListItem = papers.filter(paper => paper._id === formData.paper);
      setSelectedSubject(paperListItem[0]?.name);
    }
  }, [papers]);

  const getAllData = async () => {
    try {
      const [branchResponse, courseResponse, mentorResponse, facultyResponse, coordinatorResponse] = await Promise.all([
        apiService.get('/branch/list'),
        apiService.get('/course/list'),
        apiService.get('/mentor/list'),
        apiService.get('/faculty/list'),
        apiService.get('/coordinator/list'),
      ]);
  
      const branch = branchResponse?.data?.branchs;
      const course = courseResponse?.data?.course;
      const mentor = mentorResponse?.data?.mentor;
      const faculty = facultyResponse?.data?.faculty;
      const coordinator = coordinatorResponse?.data?.coordinator;
  
      setBranchs(branch);
      setCourses(course);
      setMentors(mentor);
      setFaculties(faculty);
      setCoordinator(coordinator);
    } catch (error) {
      console.error('Error during requests:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateChange = (nName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [nName]: value,
    }));
  }

  const handleChangeSubmit = (e, value, name) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value?._id,
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if(selectedStudents.length > formData.limit){
      alert('The selected student exceeds the capacity limit.');
      isValid = false;
    }

    if(!formData.date) {
      alert('Schedule start after is required.');
      isValid = false;
    }

    if(formData.status === 'Open For Enrollment' && !formData.statusOptions?.length) {
      alert('Select atleast on option for status.');
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (validateForm()) {
      const apiUrl = id ? `/draft-batch/update?id=${id}` : '/draft-batch/create';
  
      try {
        const response = await apiService.post(apiUrl, formData);
        navigate(-1);
        console.log(JSON.stringify(response.data));
      } catch (error) {
        alert(error?.response?.data?.error);
        console.error(error);
      }
    }
  
  };

  const onChangeStudent = (newValue) => {
    if(newValue.length > formData.limit)
      alert('Maximum batch capacity reached!');
    else
      setSelectedStudents(newValue);
  }

  const handleChangeYear = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  const handleChangeList = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value?._id,
    }));

    if(name === 'paper') {
      setSelectedSubject(value?.name);
    }
  };

  const getPapersByCourse = async (cid) => {
    try {
      const response = await apiService.get(`/paper/paperbycourse?id=${cid}`);
      setPapers(response.data)
    } catch (error) {
      console.error(error);
    }
  }

  const handleStatusOptionChange = (e, type) => {
    if(e.target.checked) {
      if(type === 'All'){
        setFormData((prevData) => ({
          ...prevData,
          statusOptions: statusType,
        }));
      }
      else {
        setFormData((prevData) => ({
          ...prevData,
          statusOptions: [...prevData.statusOptions, type]
        }));
      }
    }
    else {
      if(type === 'All'){
        setFormData((prevData) => ({
          ...prevData,
          statusOptions: [],
        }));
      }
      else {
        setFormData((prevData) => ({
          ...prevData,
          statusOptions: prevData.statusOptions.filter(option => option !== type),
        }));
      }
    }
  }

  return (
    <div>
      <h1 style={{ cursor: 'pointer', width: 'fit-content' }} onClick={() => navigate(-1)}>
        <BackButton/>
        {`Draft Batches`}
      </h1>
      <form onSubmit={handleSubmit}>
        <Typography variant="h4" gutterBottom>
          {id ? 'Edit' : 'Create'} Draft Batch
        </Typography>
        {nameExceptionSubjects.includes(selectedSubject) && permissions.includes('updateDraftNameTail') ? 
          <TextField
            label="Name Tail"
            name="nameTail"
            value={formData.nameTail}
            onChange={handleChange}
            fullWidth
            sx={{marginTop:'40px'}}
            margin="normal"
            size="small"
          />  
          : ''
        }
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          name="year"
          required
          options={years}
          style={{ marginTop: 15 }}
          getOptionLabel={(option) => option}  
          value={formData.year || null}
          onChange={(e, value) => handleChangeYear('year', value)} 
          renderInput={(params) => <TextField {...params} label="Academic Year" />}
          size="small"
        />
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          name="session"
          required
          options={sessions}
          style={{ marginTop: 15 }}
          getOptionLabel={(option) => option}  
          value={formData.session || null}
          onChange={(e, value) => handleChangeYear('session', value)} 
          renderInput={(params) => <TextField {...params} label="Session" />}
          size="small"
        />
        
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          name="branch"
          options={branchs}
          style={{marginTop:15}}
          getOptionLabel={(option) => option.name}
          value={branchs.find((option) => option._id === formData.branch) || null}
          onChange={(e, value) => handleChangeList('branch', value)} 
          renderInput={(params) => <TextField {...params} label="Branch" />}
          size="small"
        />

        <Autocomplete
          disablePortal
          id="combo-box-demo"
          name="courses"
          options={courses}
          style={{marginTop:15}}
          getOptionLabel={(option) => option.name}
          value={courses.find((option) => option._id === formData.course) || null}
          onChange={(e, value) => handleChangeList('course', value)} 
          renderInput={(params) => <TextField {...params} label="Add Course" />}
          size="small"
        />

        {formData.course && (
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            name="paper"
            required
            options={papers}
            style={{ marginTop: 30 }}
            getOptionLabel={(option) => option.name} 
            value={papers.find((option) => option._id === formData.paper) || null}
            onChange={(e, value) => handleChangeList('paper',value)} 
            renderInput={(params) => <TextField {...params} label="Subject" />}
            size="small"
          />
        )}

        <TextField
          label="Capacity"
          name="limit"
          value={formData.limit}
          onChange={handleChange}
          fullWidth
          required
          sx={{marginTop:'20px'}}
          margin="normal"
          size="small"
        />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <FormControl className='gap-2 mb-8' fullWidth style={{ marginTop: "10px" }} size="small">
            <MobileDatePicker
              value={dayjs(formData.date)}
              onChange={(date) => handleDateChange('date', date)}
              sx={{ marginTop: 1 }}
              label="Schedule Start After"
              slotProps={{
                textField: {
                  size: 'small'
                }
              }}
            />
          </FormControl>
        </LocalizationProvider>

        <FormControl fullWidth sx={{ marginTop: '20px' }} size="small">
          <InputLabel id="Select Status">Select Status</InputLabel>
          <Select
            label="Select Status"
            value={formData.status}
            onChange={handleChange}
            fullWidth
            name="status"
            size="small"
          >
            <MenuItem value="Upcoming">Upcoming</MenuItem>
            <MenuItem value="Open For Enrollment">Open For Enrollment</MenuItem>
            <MenuItem value="Running">Running</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
          </Select>
        </FormControl>

        {formData.status === 'Open For Enrollment' ?
          <Grid container spacing={2} sx={{ marginTop: 1}}>
            <Grid item xs={2}>
              <FormControlLabel control={<Checkbox checked={formData?.statusOptions?.length === statusType?.length} onChange={(e) => handleStatusOptionChange(e, 'All')} />} label="All" />
            </Grid>
            {statusType?.map((type, index) => (
              <Grid item xs={2} key={index}>
                <FormControlLabel control={<Checkbox checked={formData.statusOptions.includes(type)} onChange={(e) => handleStatusOptionChange(e, type)} />} label={type} />
              </Grid>
            ))}
          </Grid>
        : ''}

        <FormControl fullWidth sx={{ marginTop: '20px' }} size="small">
          <InputLabel id="Select Mode">Select Mode</InputLabel>
          <Select
            label="Select Mode"
            value={formData.mode}
            onChange={handleChange}
            fullWidth
            name="mode"
            size="small"
          >
            <MenuItem value="Offline">Offline</MenuItem>
            <MenuItem value="Online">Online</MenuItem>
            <MenuItem value="Hybrid">Hybrid</MenuItem>
            <MenuItem value="Recordings">Recordings</MenuItem>
            <MenuItem value="Online Plus Recordings">Online Plus Recordings</MenuItem>
            <MenuItem value="Cohort">Cohort</MenuItem>
          </Select>
        </FormControl>
      
        <Autocomplete
          disablePortal
          id="combo-box-faculty"
          name="faculty"
          options={faculties}
          style={{ marginTop: '20px' }}
          getOptionLabel={(option) => option.name}
          value={faculties.find((option) => option._id === formData.faculty) || null}
          onChange={(e, value) => handleChangeSubmit(e, value,'faculty')}
          renderInput={(params) => <TextField {...params} label="Faculty" />}
          size="small"
        />

        <Autocomplete
          disablePortal
          id="combo-box-mentor"
          name="mentor"
          options={mentors}
          style={{ marginTop: '20px' }}
          getOptionLabel={(option) => option.name}
          value={mentors.find((option) => option._id === formData.mentor) || null}
          onChange={(e, value) => handleChangeSubmit(e, value,'mentor')}
          renderInput={(params) => <TextField {...params} label="Mentor" />}
          size="small"
        />

        <Autocomplete
          disablePortal
          id="combo-box-coordinator"
          name="coordinator"
          options={coordinator}
          style={{ marginTop: '20px' }}
          getOptionLabel={(option) => option.name}
          value={coordinator.find((option) => option._id === formData.coordinator) || null}
          onChange={(e, value) => handleChangeSubmit(e, value,'coordinator')}
          renderInput={(params) => <TextField {...params} label="Coordinator" />}
          size="small"
        />

        {(formData.paper && formData.mode && id) && <Students selectedStudents={selectedStudents} setSelectedStudents={onChangeStudent} paper={formData.paper} mode={formData.mode} batchId={id} /> }

        <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
          {id ? 'Update Draft Batch' : 'Create Draft Batch'}
        </Button>
      </form>

      {(id && permissions.includes('readBatchActivityLogs')) && 
        <div style={{ marginTop: '2rem' }}>
          <Accordion defaultExpanded ={!id}>
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="log-content"
              id="log-header"
            >
              <Typography>Activity Logs</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ActivityLogs id={id} />
            </AccordionDetails>
          </Accordion>
        </div>
      }

    </div>
  );
};

export default CreatebatchForm;
