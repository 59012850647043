import React, { useEffect, useState } from 'react';
import { Typography, Button, TextField, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro'; // Import DataGridPro components
import apiService from '../../../utlis/apiService';
import { useUser } from '../../../context/userContext';

const MyTable = () => {
    const { permissions } = useUser();
    const [data, setData] = useState([]);
    const [totalResults, setTotalResults] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 50,
        page: 0,
    });
    const pageOption = [10, 25, 50, 100];
    

    const navigate = useNavigate();

    useEffect(() => {
        getData();
    }, [searchTerm, paginationModel]);

    const getData = async () => {
        try {
            setLoading(true); // Start loading
            const response = await apiService.get('/branch/list', {
                params: { searchTerm, page: paginationModel.page, pageSize: paginationModel.pageSize },
            });
            setData(response?.data?.branchs);
            setTotalResults(response?.data?.totalResults);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false); // Stop loading, whether success or error
        }
    };

    const deleteItem = async (id) => {
        const shouldDelete = window.confirm('Are you sure you want to delete this item?');
        if (shouldDelete) {
            try {
                setLoading(true); // Start loading
                await apiService.post(`/branch/delete?id=${id}`);
                getData();
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false); // Stop loading, whether success or error
            }
        }
    };

    const clearLocalStorageKeysWithSubstring = (substring) => {
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key.includes(substring)) {
                localStorage.removeItem(key);
            }
        }
    };

    const createPress = () => {
        clearLocalStorageKeysWithSubstring('FormData');
        navigate('/dashboard/branch/add');
    };

    return (
        <div>
            <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                <Typography variant="h4" gutterBottom>BRANCHES</Typography>
                {permissions.includes('createBranch') && (
                    <Button variant="contained" color="primary" style={{ marginBottom: '16px' }} onClick={createPress}>
                        + Create
                    </Button>
                )}
            </div>
            <TextField
                label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                fullWidth
                margin="normal"
                style={{ marginBottom: 10 }}
            />
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 400 }}>
                    <CircularProgress />
                </div>
            ) : (
                <div style={{ height: '70vh', width: '100%' }}>
                    <DataGridPro
                        rows={data}
                        getRowId={(row) => row._id}
                        columns={[
                            { field: 'name', headerName: 'Name', width: 200 },
                            { field: 'location', headerName: 'Location', width: 200 },
                            // { field: 'status', headerName: 'Status', width: 150, valueGetter: (params) => (params.row.status ? 'Active' : 'Inactive') },
                            {
                                field: 'actions',
                                headerName: 'Action',
                                width: 150,
                                renderCell: (params) => (
                                    <>
                                        {permissions.includes('updateBranch') && (
                                            <Button variant="outlined" onClick={() => navigate(`/dashboard/branch/add?id=${params.row._id}`)}>Edit</Button>
                                        )}
                                        {permissions.includes('deleteBranch') && (
                                            <Button variant="outlined" color="error" style={{ marginLeft: 5 }} onClick={() => deleteItem(params.row._id)}>Delete</Button>
                                        )}
                                    </>
                                ),
                            },
                        ]}
                        pagination
                        paginationModel={paginationModel}
                        rowCount={totalResults}
                        paginationMode="server"
                        components={{ Toolbar: GridToolbar }}
                        onPaginationModelChange={setPaginationModel}
                        rowsPerPageOptions={pageOption}
                    />
                </div>
            )}
            
        </div>
    );
};

export default MyTable;
